import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Api from 'Services/paymentFileService';
import { DisplayByVendor } from 'Partials/DisplayByVendor';
import {
  Section,
  LinkList,
  FileGenericIcon,
  Button,
  CalendarIcon,
  User1Icon,
} from '@exxonmobil/react-unity';
import { setLoading } from 'Store/Reducers/uiSlice';
import Style from './style.module.css';
export const PaymentLogDetails = () => {
  const [data, setData] = useState();
  const [invoiceCount, setInvoiceCount] = useState();
  const refs = {};

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));
    if (params.get('id') != null) {
      Api.getPaymentFile(params.get('id')).then((response) => {
        if (response != null) {
          setData(response.data);
          invoiceTotal(response.data);
        }
        dispatch(setLoading(false));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ? currencyCode : 'USD',
    });
  };
  const downloadString = (text, fileType, fileName) => {
    var blob = new Blob([text], { type: fileType });

    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  };

  const goBack = () => window.history.back();

  const invoiceTotal = (data) => {
    const companies = data?.invoice;
    if (companies != null) {
      let total = companies.reduce((prev, curr) => {
        return (
          prev +
          curr.vendors.reduce((prev, curr) => {
            return prev + curr.invoices.length;
          }, 0)
        );
      }, 0);
      setInvoiceCount(total);
    }
  };

  const params = new URLSearchParams(window.location.search);
  return (
    <div className={Style.page}>
      <div className={Style.headerContainer}>
        <h1 className={Style.headerText}>Payment File</h1>
        <Button.Group className={Style.buttonBar}>
          <Button
            variant="primary"
            onClick={() => {
              downloadString(
                data?.fileContents,
                'text/plain',
                `${data?.paymentTemplate}-${new Date(data?.generatedDate)
                  .toLocaleDateString()
                  .split('/')
                  .join('-')}.txt`
              );
            }}>
            Download
          </Button>
          <Button
            onClick={() => {
              goBack();
            }}>
            Go Back
          </Button>
        </Button.Group>
        <hr className={Style.headerLine}></hr>
        <LinkList icons>
          <LinkList.Item className={Style.listItem} icon={<CalendarIcon />}>
            Generated Date:
            {(data?.generatedDate &&
              new Date(data?.generatedDate).toLocaleDateString()) ||
              '-'}
          </LinkList.Item>
          <LinkList.Item className={Style.listItem} icon={<FileGenericIcon />}>
            {' '}
            File Template: {data?.paymentTemplate}{' '}
          </LinkList.Item>
          <LinkList.Item className={Style.listItem} icon={<User1Icon />}>
            {' '}
            Processed By: {data?.generatedBy}{' '}
          </LinkList.Item>
        </LinkList>
        <div className={Style.amountBox}>
          {data?.totalAmounts.map((item) => (
            <p key={item.currencyCode}>
              Total Amount {item.currencyCode}{' '}
              {formatter('USD').format(item.totalAmount)}
            </p>
          ))}
        </div>
      </div>
      <Section
        className={Style.headerText}
        title={`File Contents - ${
          data?.fileContents.split('\n').length
        } Payment${data?.fileContents.split('\n').length === 1 ? '' : 's'}`}
        variant="expandable"
        underline="main">
        <pre className={Style.fileContainer}>{data?.fileContents}</pre>
      </Section>
      <Section
        className={Style.headerText}
        title={`Included Invoices - ${invoiceCount} Invoice${
          invoiceCount === 1 ? '' : 's'
        }`}
        variant="expandable"
        underline="main">
        {data?.invoice != null &&
          data?.invoice.map((inv, index) => (
            <DisplayByVendor
              company={inv}
              index={index}
              key={`accordion-${index}`}
              readOnly={true}
              refs= {refs}
            />
          ))}
      </Section>
    </div>
  );
};

export default PaymentLogDetails;
