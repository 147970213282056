import axios from 'axios';
import { authProvider } from './authProvider';

import ApiConfig from 'Config/ApiConfig';
import { history } from '../App';

const instance = axios.create(ApiConfig);

const redirectErrorHandling = (error) => {
  history.push({
    pathname: '/error-page',
    state: {
      status: error.response ? error.response.status : '',
      message: error.response ? error.response.statusText : '' + error,
    },
  });
};
// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Check and acquire a token before the request is sent\
    return new Promise((resolve, reject) => {
      authProvider.getIdToken().then((value) => {
        if (value.idToken) {
          config.headers.Authorization = `Bearer ${value.idToken.rawIdToken}`;
          resolve(config);
        } else {
          // Do something with error of acquiring the token
          reject(config);
        }
      });
    });
  },
  function (error) {
    // Do something with error of the request
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status < 500) return error.response;

    redirectErrorHandling(error);
    return Promise.reject(error);
  }
);
export default instance;
