import React, { useEffect } from 'react';
import { Container, XFilledIcon } from '@exxonmobil/react-unity';

import Style from './style.module.css';

const ErrorHandlingPage = (props) => {
  //const response = props.history.location.state
  const message = props.history.location.state.message;
  const status = props.history.location.state.status;

  useEffect(() => {}, []);

  return (
    <Container className={Style.container}>
      <div></div>
      <>
        <div className={Style.error}>
          <XFilledIcon className={Style.xfilled} />
        </div>
        <h2>Error: {status}</h2>
      </>
      <>
        <p>Message: {message} </p>
      </>
    </Container>
  );
};

export default ErrorHandlingPage;
