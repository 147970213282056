import { InvoiceStatusBadge } from 'Components/InvoiceStatusBadge';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  CaretDownIcon,
  CaretUpIcon,
  CirclePlusIcon,
  Modal,
  Pagination,
  PencilIcon,
  Searchbar,
  Select,
  Table,
  Tag,
  Toolbar,
  Tooltip,
  XFilledIcon,
} from '@exxonmobil/react-unity';
import { SelectGroup } from 'Components/SelectGroup';
import { DatePickerGroup } from 'Components/DatePickerGroup';
import { TextInputGroup } from 'Components/TextInputGroup';
import moment from 'moment';
import Styles from './style.module.css';

const ManageInvoicesTable = (props) => {
  const {
    filterType,
    filterTypes,
    filter,
    filterStart,
    filterEnd,
    selectedFilters,
    headers,
    page,
    pages,
    invoices,
    setActiveFilters,
    inputValidation,
    formatAmount,
    setFilter,
    setFilterStart,
    setFilterEnd,
    setFilterTypeValue,
    unsetFilter,
    formatter,
    numberSelected,
    changePage,
    selectAll,
    allSelected,
    selectInvoice,
    onSort,
    count,
    // clusterValue
  } = props;

  const [blockModal, setBlockModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [error, setError] = useState();
  const [paymentBlock, setPaymentBlock] = useState('');
  const { register, control, reset } = useForm();

  const massBlockEdit = () => {
    setConfirmationModal(false);
    setBlockModal(false);
    props.massBlockEdit(paymentBlock);
  };

  const validateDate = (date) => {
    return moment(date, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
  };

  const setInputType = () => {
    switch (filterType) {
      case 'paymentBlock':
      case 'cluster':
      case 'status':
      case 'system':
      case 'postingKey':
      case 'paymentMethod':
        return 'hidden';
      default:
        return filterType.includes('Range') ? 'hidden' : 'text';
    }
  };

  useEffect(() => {
    if (filterType.includes('Date')) {
      if (!validateDate(filterStart) && filterStart !== '') {
        setError({
          ...error,
          start:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
      } else {
        setError({ ...error, start: null });
      }
      if (!validateDate(filterEnd) && filterEnd !== '') {
        setError({
          ...error,
          end:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
      } else {
        setError({ ...error, end: null });
      }
    }
    // eslint-disable-next-line
  }, [filterType, filterStart, filterEnd]);

  const handleSearchClick = (e) => {
    if (filterType.includes('DateRange') || filterType === 'amountRange') {
      debugger;

      if (filterStart === '') {
        setError({ start: 'Required' });
        e.preventDefault();
      } else if (
        !moment(filterStart, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid()
      ) {
        setError({
          start:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
        e.preventDefault();
      } else if (
        !moment(filterEnd, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid()
      ) {
        setError({
          end:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
        e.preventDefault();
      } else if (
        moment(filterStart, ['MM/DD/YYYY', 'M/D/YYYY'], true) >
        moment(filterEnd, ['MM/DD/YYYY', 'M/D/YYYY'], true)
      ) {
        setError({ end: 'End value cannot be smaller than start value' });
        e.preventDefault();
      } else {
        setActiveFilters(e);
        setError();
        reset({
          startDate: '',
          endDate: '',
        });
      }
    } else {
      setActiveFilters(e);
      setError();
    }
  };

  return (
    <>
      <Table
        className={Styles.table}
        header={
          <Toolbar className={Styles.tableHeader}>
            <Toolbar.Item left="true">
              <Searchbar
                className={Styles.filter}
                onSubmit={(e) => handleSearchClick(e)}
                inputProps={{
                  maxLength: 100,
                  minLength: filterType === 'block' ? 1 : 3,
                  type: setInputType(),
                  className: `em-u-no-border ${
                    filterType.includes('Date') && Styles.date
                  }`,
                  onInput: (e) => inputValidation(e.target),
                  value: filterType.includes('amount')
                    ? formatAmount(filter, setFilter)
                    : filter,
                  valid: false,
                  onChange: (e) => setFilter(e.target.value),
                  placeholder: filterType.includes('amount')
                    ? '$0.00'
                    : 'Filter',
                }}
                filter={
                  <span style={{ display: 'inherit' }}>
                    <Select
                      className="em-u-margin-none"
                      onChange={(e) => {
                        setError();
                        setFilterTypeValue(e.target.value);
                      }}>
                      {Object.keys(filterTypes).map((item, index) => {
                        return (
                          <option value={item} key={`filter-${item}-${index}`}>
                            {filterTypes[item]}
                          </option>
                        );
                      })}
                    </Select>
                    {filterType === 'cluster' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={true}
                        labelStyle={{ marginBottom: '0px' }}
                        name="cluster"
                        control={control}
                        register={register}
                        options={props.clusters}
                        option="name"
                        isLoading={props.clustersLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType === 'system' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={true}
                        labelStyle={{ marginBottom: '0px' }}
                        name="system"
                        control={control}
                        register={register}
                        options={props.systems}
                        option="systemCode"
                        isLoading={props.systemsLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType.includes('DateRange') && (
                      <>
                        <div className={Styles.rangeFilter}>
                          <DatePickerGroup
                            id="startDate"
                            control={control}
                            register={register}
                            name="startDate"
                            onChange={(e) => setFilterStart(e.target.value)}
                            placeholder="Start Date"
                            noLabel
                            error={error?.start}
                          />
                          <DatePickerGroup
                            id="endDate"
                            control={control}
                            register={register}
                            name="endDate"
                            onChange={(e) => setFilterEnd(e.target.value)}
                            placeholder="End Date"
                            noLabel
                            error={error?.end}
                          />
                        </div>
                      </>
                    )}
                    {filterType === 'amountRange' && (
                      <div className={Styles.rangeFilter}>
                        <TextInputGroup
                          id="startValue"
                          control={control}
                          register={register}
                          name="startValue"
                          onChange={(e) =>
                            formatAmount(e.target.value, setFilterStart)
                          }
                          value={filterStart}
                          placeholder={'Start range: $0.00'}
                          noLabel
                          error={error?.start}
                        />
                        <TextInputGroup
                          id="endValue"
                          control={control}
                          register={register}
                          name="endValue"
                          onChange={(e) =>
                            formatAmount(e.target.value, setFilterEnd)
                          }
                          value={filterEnd}
                          placeholder={'End range: $0.00'}
                          noLabel
                          error={error?.end}
                        />
                      </div>
                    )}
                    {filterType === 'paymentBlock' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={false}
                        labelStyle={{ marginBottom: '0px' }}
                        name="block"
                        control={control}
                        register={register}
                        options={props.blocks}
                        option="name"
                        // isLoading={props.systemsLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType === 'status' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={true}
                        labelStyle={{ marginBottom: '0px' }}
                        name="status"
                        control={control}
                        register={register}
                        options={props.statuses}
                        option="description"
                        isLoading={props.statusLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType === 'postingKey' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={false}
                        labelStyle={{ marginBottom: '0px' }}
                        name="block"
                        control={control}
                        register={register}
                        options={props.postingKeys}
                        option="name"
                        // isLoading={props.systemsLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType === 'paymentMethod' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={false}
                        labelStyle={{ marginBottom: '0px' }}
                        name="block"
                        control={control}
                        register={register}
                        options={props.paymentMethods}
                        option="name"
                        // isLoading={props.systemsLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                  </span>
                }
                tags={
                  <Tag.Group style={{ maxWidth: '360px' }}>
                    {Object.keys(selectedFilters).map((item, index) => {
                      const filter = selectedFilters[item];
                      return Array.isArray(filter)
                        ? filter.map((tag, tagIndex) => {
                            return (
                              tag && (
                                <React.Fragment
                                  key={`active-filter-${item}-${tag}-${index}-${tagIndex}`}>
                                  <Tooltip color="light">
                                    <Tooltip.Passage>
                                      <Tag
                                        className={Styles.tags}
                                        onCloseAction={() =>
                                          unsetFilter(item, tag)
                                        }>
                                        {tag}
                                      </Tag>
                                      <Tooltip.Content>
                                        {filterTypes[item]}
                                      </Tooltip.Content>
                                    </Tooltip.Passage>
                                  </Tooltip>
                                </React.Fragment>
                              )
                            );
                          })
                        : filter && typeof filter !== 'object' && (
                            <React.Fragment
                              key={`active-filter-${item}-${index}`}>
                              <Tooltip color="light">
                                <Tooltip.Passage>
                                  <Tag
                                    className={Styles.tags}
                                    onCloseAction={() =>
                                      unsetFilter(item, filter)
                                    }>
                                    {item.includes('amount')
                                      ? formatter().format(filter)
                                      : item.includes('Date') &&
                                        new Date(filter).toLocaleDateString(
                                          'en-US',
                                          {
                                            timeZone: 'UTC',
                                          }
                                        )}
                                  </Tag>
                                  <Tooltip.Content>
                                    {item.includes('Start')
                                      ? filterTypes[
                                          item.replace('Start', 'Range')
                                        ]
                                      : item.includes('End')
                                      ? filterTypes[
                                          item.replace('End', 'Range')
                                        ]
                                      : filterTypes[item]}
                                  </Tooltip.Content>
                                </Tooltip.Passage>
                              </Tooltip>
                            </React.Fragment>
                          );
                    })}
                  </Tag.Group>
                }
              />
            </Toolbar.Item>
            <Toolbar.Item right>
              <span className={Styles.selected}>
                {numberSelected()
                  ? `${
                      numberSelected() === 1
                        ? `${numberSelected()} Invoice`
                        : `${numberSelected()} Invoices`
                    } Selected`
                  : ''}
              </span>
              <Button
                className={Styles.button}
                disabled={numberSelected() <= 0}
                onClick={() => setBlockModal(true)}>
                Mass Block Edit
              </Button>
              <Button
                className={Styles.button}
                style={{ top: '2px' }}
                variant="primary">
                <CirclePlusIcon size="small" />
                <span>New Invoice</span>
              </Button>
            </Toolbar.Item>
          </Toolbar>
        }
        footer={
          invoices.length ? (
            <Toolbar>
              <Toolbar.Item>
                {`Showing ${
                  invoices.length * (page + 1) - invoices.length + 1
                } - ${invoices.length * (page + 1)} of ${count} results`}
              </Toolbar.Item>
              <Toolbar.Item right>
                <Pagination
                  className={Styles.pagination}
                  pages={pages}
                  onChange={(page) => changePage(page)}
                />
              </Toolbar.Item>
            </Toolbar>
          ) : null
        }>
        {invoices.length ? (
          <>
            <Table.Head>
              <Table.Head.Row>
                <Table.Head.Cell>
                  <input
                    type="checkbox"
                    onChange={() => selectAll()}
                    checked={allSelected()}
                  />
                </Table.Head.Cell>
                {Object.keys(headers).map((item, index) => {
                  return (
                    <Table.Head.Cell
                      onClick={() => onSort(item)}
                      key={`column-${item}-${index}`}
                      style={{ userSelect: 'none' }}>
                      <div className={Styles.sort}>
                        {headers[item]}
                        <div style={{ height: '100%' }}>
                          {selectedFilters.sortOptions?.field === item &&
                          selectedFilters.sortOptions?.order === 'asc' ? (
                            <CaretUpIcon />
                          ) : selectedFilters.sortOptions?.field === item &&
                            selectedFilters.sortOptions?.order === 'desc' ? (
                            <CaretDownIcon />
                          ) : (
                            <>
                              <CaretUpIcon /> <CaretDownIcon />
                            </>
                          )}
                        </div>
                      </div>
                    </Table.Head.Cell>
                  );
                })}
                <Table.Head.Cell>Edit</Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              {invoices.map((invoice, index) => {
                return (
                  <Table.Body.Row
                    key={`invoice-${invoice.invoiceNumber}-${index}`}>
                    <Table.Body.Cell>
                      <input
                        type="checkbox"
                        onChange={() => selectInvoice(index)}
                        checked={invoice.selected}
                      />
                    </Table.Body.Cell>
                    <Table.Body.Cell>{invoice.system}</Table.Body.Cell>
                    <Table.Body.Cell>{invoice.company}</Table.Body.Cell>
                    <Table.Body.Cell style={{ maxWidth: '180px' }}>
                      {invoice.vendorNumber}
                      <div style={{ fontSize: '12px' }}>
                        {invoice.vendorName}
                      </div>
                    </Table.Body.Cell>
                    <Table.Body.Cell>{invoice.documentNumber}</Table.Body.Cell>
                    <Table.Body.Cell style={{ minWidth: '120px' }}>
                      {new Date(invoice.invoiceDate).toLocaleDateString()}
                    </Table.Body.Cell>
                    <Table.Body.Cell>{invoice.invoiceNumber}</Table.Body.Cell>
                    <Table.Body.Cell>{invoice.cluster}</Table.Body.Cell>
                    <Table.Body.Cell>
                      {invoice.postingKey || '-'}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      {invoice.paymentMethod || '-'}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      <InvoiceStatusBadge
                        status={invoice.status}
                        paymentBlock={invoice.paymentBlock}
                      />
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      {new Date(invoice.dueDate).toLocaleDateString()}
                    </Table.Body.Cell>
                    <Table.Body.Cell style={{ minWidth: '150px' }}>
                      {invoice.amount
                        ? `${invoice.currency || 'USD'} ${formatter(
                            invoice.currency
                          ).format(invoice.amount)}`
                        : `-`}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      <Button
                        onClick={() => props.editInvoice(invoice)}
                        variant="primary"
                        size="small"
                        style={{
                          height: '30px',
                        }}>
                        <PencilIcon size="small" />
                      </Button>
                    </Table.Body.Cell>
                  </Table.Body.Row>
                );
              })}
            </Table.Body>
          </>
        ) : (
          <tbody className={Styles.notFound}>
            <tr>
              <td>No results found</td>
            </tr>
          </tbody>
        )}
      </Table>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Modal.Window style={{ width: '450px' }}>
          <Modal.Window.Header>
            <Modal.Window.Header.Title>
              Mass Block Update
            </Modal.Window.Header.Title>
            <Modal.Window.Header.CloseButton
              onClick={() => setBlockModal(false)}>
              <XFilledIcon />
            </Modal.Window.Header.CloseButton>
          </Modal.Window.Header>
          <Modal.Window.Body>
            <Select
              label="Payment Block"
              onChange={(e) => setPaymentBlock(e.target.value)}>
              <option value="">Please Select</option>
              <option value="0">Open</option>
              <option value="A">A</option>
              <option value="Z">Z</option>
            </Select>
          </Modal.Window.Body>
          <Modal.Window.Footer>
            <Button
              disabled={!paymentBlock}
              className="small-margin"
              variant="primary"
              onClick={() => {
                setConfirmationModal(true);
                setBlockModal(false);
              }}>
              {`Update ${numberSelected()} Invoices`}
            </Button>
            <Button
              className="small-margin"
              onClick={() => setBlockModal(false)}>
              Cancel
            </Button>
          </Modal.Window.Footer>
        </Modal.Window>
      </Modal>

      <Modal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}>
        <Modal.Window style={{ width: '450px' }}>
          <Modal.Window.Header>
            <Modal.Window.Header.Title>
              Update confirmation
            </Modal.Window.Header.Title>
            <Modal.Window.Header.CloseButton
              onClick={() => setConfirmationModal(false)}>
              <XFilledIcon />
            </Modal.Window.Header.CloseButton>
          </Modal.Window.Header>
          <Modal.Window.Body>
            Are you sure you want to confirm the update?
          </Modal.Window.Body>
          <Modal.Window.Footer>
            <Button
              className="small-margin"
              variant="primary"
              onClick={() => massBlockEdit()}>
              Confirm
            </Button>
            <Button
              className="small-margin"
              onClick={() => {
                setConfirmationModal(false);
                setBlockModal(true);
              }}>
              Cancel
            </Button>
          </Modal.Window.Footer>
        </Modal.Window>
      </Modal>
    </>
  );
};

export default ManageInvoicesTable;
