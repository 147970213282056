import React, { useEffect, useState } from 'react';

import { InvoicesManagement } from 'Partials/InvoicesManagement';
import Api from 'Services/invoicesService';

const InvoicesList = () => {
  const [invoices, setInvoices] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadInvoices();
  }, []);

  const loadInvoices = async (top, skip, filter, myWork, sixMonthsHistory) => {
    setLoading(true);
    const query = filter && window.btoa(JSON.stringify(filter));
    const invoices = await Api.getInvoices(top, skip, query, myWork, sixMonthsHistory);
    setInvoices(invoices.data.results);
    setCount(invoices.data.count);
    setLoading(false);
  };

  const setPaymentBlock = async (invoices, block) => {
    setLoading(true);
    await Api.putInvoicesMassBlock(invoices, block);
    setLoading(false);
  };

  return (
    <InvoicesManagement
      invoices={invoices}
      count={count}
      loadInvoices={loadInvoices}
      massBlockEdit={setPaymentBlock}
      loading={loading}
    />
  );
};

export default InvoicesList;
