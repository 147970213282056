// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import * as MsalConfig from '../Config/MsalConfig';

export const authProvider = new MsalAuthProvider(
  MsalConfig.authConfig,
  MsalConfig.authParameters,
  {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html',
  }
);
