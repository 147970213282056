import { VendorDetailsPage } from 'Partials/VendorDetailsPage';
import Api from 'Services/vendorsService';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import VendorService from 'Services/vendorsService';

const VendorDetails = (props) => {
  const { systemCode, vendorNumber } = props.match.params;
  const [vendor, setVendor] = useState({});
  const history = useHistory();
  // const [timestamp, setTimestamp] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [vendorComments, setVendorComments] = useState([]);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    // setTimestamp(new Date());
    if (systemCode && vendorNumber) {
      getVendor(systemCode, vendorNumber);
    } else {
      setVendor({
        entryDate: new Date(),
        processor: auth.userName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (systemCode && vendorNumber) {
      getVendorComments(systemCode, vendorNumber);
      getAttachments(systemCode, vendorNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth = useSelector((state) => state.auth.accountInfo.account);
  const userRoles = useSelector(state => state.auth.accountInfo.account.idTokenClaims.roles)

  const getVendor = async (systemCode, vendorNumber) => {
    setLoading(true);
    const response = await Api.getVendor(systemCode, vendorNumber);
    setVendor(response.data);
    setLoading(false);
  };
  const postVendor = (vendor) => {
    setLoading(true);
    Api.postVendor(vendor)
      .then((response) => {
        vendor.vendorNumber = response.data.vendorNumber;
        history.push(
          `/vendors/${response.data.systemCode}/${response.data.vendorNumber}`
        );
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const putVendor = (vendor) => {
    setLoading(true);
    Api.putVendor(vendor)
      .then((response) => {
        vendor.vendorNumber = response.data.vendorNumber;
        history.push(
          `/vendors/${response.data.systemCode}/${response.data.vendorNumber}`
        );
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteVendor = (vendor) => {
    setLoading(true);
    Api.deleteVendor(vendor)
      .then((response) => {
        history.push('/vendors/manage/');
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getVendorComments = async (systemCode, vendorNumber) => {
    setLoading(true);
    const vendorComments = await VendorService.getVendorComments(
      systemCode,
      vendorNumber
    );
    setVendorComments(vendorComments.data);
    setLoading(false);
  };
  const addVendorComment = async (comment) => {
    setLoading(true);

    const commentLoad = {
      createdDate: new Date().toISOString(),
      createdBy: auth.name || auth.userName,
      commentText: comment,
    };

    const { systemCode } = props.match.params;
    const { vendorNumber } = props.match.params;

    return VendorService.postVendorComment(
      systemCode,
      vendorNumber,
      commentLoad
    ).finally(() => {
      setLoading(false);
    });
  };
  const deleteVendorComment = async (id) => {
    setLoading(true);
    await VendorService.deleteVendorComment(id);
    setLoading(false);
  };
  const getAttachments = async (systemCode, vendorNumber) => {
    setLoading(true);
    const response = await VendorService.getAttachments(
      systemCode,
      vendorNumber
    );
    if (response.status === 404){
      setAttachments([]);
      setLoading(false);
      return
    }
    setAttachments(response.data);
    setLoading(false);
  };
  const addAttachments = async (files) => {
    setLoading(true);
    const { systemCode } = props.match.params;
    const { vendorNumber } = props.match.params;
    const response = await VendorService.postAttachments(
      systemCode,
      vendorNumber,
      files
    );
    setLoading(false);
    return response;
  };
  const downloadAttachment = async (id, fileName) => {
    setLoading(true);
    const response = await VendorService.downloadAttachment(id);
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setLoading(false);
  };
  const deleteAttachment = async (id) => {
    setLoading(true);
    await VendorService.deleteAttachment(id);
    setLoading(false);
  };

  return (
    <VendorDetailsPage
      vendor={vendor}
      loading={loading}
      postVendor={postVendor}
      putVendor={putVendor}
      deleteVendor={deleteVendor}
      vendorComments={vendorComments}
      addVendorComment={addVendorComment}
      deleteVendorComment={deleteVendorComment}
      attachments={attachments}
      getAttachments={getAttachments}
      addAttachments={addAttachments}
      downloadAttachment={downloadAttachment}
      deleteAttachment={deleteAttachment}
      userRoles={userRoles}
    />
  );
};

export default VendorDetails;
