import React, { useEffect, useState } from 'react';
import { Loader, CheckIcon, Button } from '@exxonmobil/react-unity';
import { useHistory } from 'react-router-dom';
import { GenericModal } from 'Components/GenericModal';

import Api from 'Services/paymentFileService';

import Style from './style.module.css';

const ExtractPayment = (props) => {
  const {
    invoices,
    fileTemplateId,
    queryTimestamp,
    fileTemplateName,
  } = props.history.location.state;
  const [fileUrl, setFileUrl] = useState();
  const [success, setSuccess] = useState(false);
  const [successEmpty, setSuccessEmpty] = useState(false);
  const [concurrencyError, setConcurrencyError] = useState(
    props.concurrencyError
  );
  const history = useHistory();
  const now = new Date();
  const fileName =
    fileTemplateName.replace(/ /g, '_') +
    '_' +
    now.getFullYear() +
    '_' +
    ('0' + (now.getMonth() + 1)).slice(-2) +
    '_' +
    now.getDate() +
    '.txt';

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    const getFile = (template, invoices) => {
      const invoiceTimestamp = {
        invoicesVM: invoices,
        queryTimestamp: queryTimestamp,
      };
      Api.putExtractPaymentFile(template, invoiceTimestamp).then((response) => {
        if (response.status === 200) {
          setSuccess(true);

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/octet-stream' })
          );
          setFileUrl(url);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        } else if (response.status === 409) {
          setConcurrencyError(true);
        } else if (response.status === 204) {
          setSuccessEmpty(true);
          setSuccess(true);
        }
      });
    };

    getFile(fileTemplateId, invoices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectPaymentFilePage = () => {
    setConcurrencyError(false);
    history.push({
      pathname: '/generate-file',
      search: `?filter=${props.location.state.filter}`,
    });
  };

  return (
    <div className={Style.container}>
      {success ? (
        <>
          <div className={Style.success}>
            <CheckIcon className={Style.check} />
          </div>
          {successEmpty ? (
            <div>
              <h1>Invoices Processed Successfully</h1>
              <h3 style={{ textAlign: 'center' }}>
                No file was generated due to balance amount from selection is
                zero.
              </h3>
            </div>
          ) : (
            <>
              <h1>File Extracted Succesfully!</h1>
              <div className={Style.downloadButton}>
                <Button onClick={() => downloadFile()}>Download file</Button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <Loader className={Style.loading} />
          <h1>Extracting File...</h1>
        </>
      )}
      <GenericModal
        show={concurrencyError}
        title="Error generating report"
        content={
          <div>
            <p>Some invoices have already been selected by a different user.</p>
            <p>Please run the report again.</p>
          </div>
        }
        footer={true}
        primaryButton="Back to Filter Screen"
        primaryAction={() => redirectPaymentFilePage()}
      />
    </div>
  );
};

export default ExtractPayment;
