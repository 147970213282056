import React, { useState } from 'react';
import { Accordian, Grid, Tag, ChevronDownIcon } from '@exxonmobil/react-unity';
import { useSelector } from 'react-redux';

import { InvoicesTable } from 'Partials/InvoicesTable';

import Styles from './style.module.css';

export const DisplayByVendor = (props) => {
  const { className } = props;
  const [opened, setOpen] = useState(true);
  const company = props.company;
  const preDisbursement = useSelector((state) => state.preDisbursement);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const invoicesTotal = () => {
    // const invoices = preDisbursement.invoices;
    // return invoices[props.index]?.reduce((acc, item) => {
    //   return acc + (item.selected && parseFloat(item.documentAmount));
    // }, 0);
    if (
      preDisbursement.invoices[props.index] &&
      preDisbursement.invoices[props.index].length > 0
    ) {
      let uniqueCurrencies = preDisbursement.invoices[props.index]
        .filter((invoice) => invoice.selected)
        .map((invoice) => invoice.documentCurrency)
        .filter((value, index, self) => self.indexOf(value) === index);

      if (uniqueCurrencies.length > 0) {
        return uniqueCurrencies.map((item) => {
          return {
            currency: item,
            amount: preDisbursement.invoices[props.index].reduce(
              (acc, invoice) => {
                return (
                  acc +
                  (invoice.selected &&
                    invoice.documentCurrency === item &&
                    parseFloat(invoice.documentAmount))
                );
              },
              0
            ),
          };
        });
      }
    }
  };

  const href = null;
  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <Accordian className={Styles.accordian}>
          <li
            onClick={props.onClick}
            className={`em-c-accordion__item em-js-accordion-item ${
              !opened ? 'em-is-closed' : ''
            } ${className}`}>
            <div className="">
              <a
                href={href}
                onClick={() => setOpen(!opened)}
                className="em-c-accordion__title em-js-accordion-trigger">
                <div className={Styles.header}>
                  <ChevronDownIcon
                    className="em-c-accordion__icon"
                    size="small"
                  />
                  <span
                    style={{ width: '100%' }}
                    className="em-c-accordion__title-text">
                    <>
                      <div className={Styles.alignInline}>
                        <div className={Styles.title}>
                          <h3>{company.companyName && company.companyName}</h3>
                          <div>
                            {company.payingCompanyCode && (
                              <Tag>{company.payingCompanyCode}</Tag>
                            )}
                          </div>
                        </div>
                        {!props.readOnly && (
                          <div className={Styles.selectedInvoices}>
                            <div>
                              {preDisbursement.invoices[props.index]?.length}{' '}
                              Selected Invoices
                            </div>
                            <div className={Styles.invoiceSum}>
                              {invoicesTotal()?.map((item, index) => {
                                return (
                                  <div key={`currency-${index}`}>
                                    {item.currency}{' '}
                                    {formatter.format(item.amount)}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <Grid variant="2-up" className={Styles.gridText}>
                          <Grid.Item>
                            <div className={Styles.gridList}>
                              <div className={Styles.gridAlign}>
                                <div> House Bank: </div>
                                <div className={Styles.gridText}>
                                  {company.houseBank ? company.houseBank : '-'}
                                </div>
                              </div>
                            </div>
                          </Grid.Item>
                          <Grid.Item>
                            <div className={Styles.gridListLong}>
                              <div className={Styles.gridAlign}>
                                <div style={{ minWidth: '7.64em' }}>
                                  Payment Method:
                                </div>
                                <div className={Styles.gridText}>
                                  {company.pm ? company.pm : '-'}
                                </div>
                              </div>
                            </div>
                          </Grid.Item>
                          <Grid.Item>
                            <div className={Styles.gridList}>
                              <div className={Styles.gridAlign}>
                                <div>Bank Country:</div>
                                <div className={Styles.gridText}>
                                  {company.bankCountryKey
                                    ? company.bankCountryKey
                                    : '-'}
                                </div>
                              </div>
                            </div>
                          </Grid.Item>
                          <Grid.Item>
                            <div className={Styles.gridListLong}>
                              <div className={Styles.gridAlign}>
                                <div>Account ID:</div>
                                <div className={Styles.gridText}>
                                  {company.accountID ? company.accountID : '-'}
                                </div>
                              </div>
                            </div>
                          </Grid.Item>
                          <Grid.Item>
                            <div className={Styles.gridList}>
                              <div className={Styles.gridAlign}>
                                <div>Bank Key: </div>
                                <div className={Styles.gridText}>
                                  {company.bankKey ? company.bankKey : '-'}
                                </div>
                              </div>
                            </div>
                          </Grid.Item>
                          <Grid.Item>
                            <div className={Styles.gridListLong}>
                              <div className={Styles.gridAlign}>
                                <div> Account Number:</div>
                                <div className={Styles.gridText}>
                                  {company.bankAccountNumber
                                    ? company.bankAccountNumber
                                    : '-'}
                                </div>
                              </div>
                            </div>
                          </Grid.Item>
                        </Grid>
                      </div>
                    </>
                  </span>
                </div>
              </a>
            </div>
            <div className="em-c-accordion__body">
              <InvoicesTable
                vendors={company.vendors}
                index={props.index}
                extract={props.extract}
                readOnly={props.readOnly}
                onCheck={props.onCheck}
                refs={props.refs}
                companyCode={company.payingCompanyCode}
                onCreditMemoCheck={props.onCreditMemoCheck}
              />
            </div>
          </li>
        </Accordian>
      </div>
    </div>
  );
};

export default DisplayByVendor;
