import React, { useEffect, useState } from 'react';
import Api from 'Services/vendorsService';
import { GenericModal } from 'Components/GenericModal';
import { VendorCompanyEditRow } from 'Components/VendorCompanyEditRow';
import { Loading } from 'Components/Loading';

import {
  Button,
  Pagination,
  Table,
  Toolbar,
  PencilIcon,
  TrashcanEmptyIcon,
  UndoIcon,
} from '@exxonmobil/react-unity';
import Styles from './style.module.css';

const VendorCompanyTable = (props) => {
  const [vendorCompanies, setVendorCompanies] = useState([]);
  const [page, setPage] = useState(0);
  const [top] = useState(10);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState([]);
  const [newVendorCompany, setNewVendorCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [massEdit, setMassEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const { vendorNumber, systemCode, countryCode, hasVendorAdminRole, hasIVRole } = props;
  useEffect(() => {
    if (vendorNumber && systemCode) {
      loadVendorCompanies(top, 0, systemCode, vendorNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorNumber, systemCode, countryCode]);

  useEffect(() => {
    setPageArray(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const changePage = (nextPage) => {
    setPage(nextPage - 1);
    loadVendorCompanies(top, (nextPage - 1) * top, systemCode, vendorNumber);
  };
  const loadVendorCompanies = async (top, skip, systemCode, vendorNumber) => {
    setLoading(true);
    const vendorCompanies = await Api.getVendorCompanies(
      top,
      skip,
      systemCode,
      vendorNumber
    );
    setVendorCompanies(vendorCompanies.data.results);
    setCount(vendorCompanies.data.count);
    setLoading(false);
  };

  const setPageArray = (count) => {
    const total = Math.ceil(count / top);
    return setPages([...Array(total).keys()].toString().split(','));
  };

  const postVendorCompany = (stagingVC) => {
    let errors = validate(stagingVC);
    if (errors.length > 0) {
      props.showErrors(errors);
      return;
    }
    setLoading(true);
    Api.postVendorCompany(stagingVC)
      .then((response) => {
        changePage(page + 1);
      })
      .finally(() => {
        setAddMode(false);
      });
  };

  const putVendorCompany = (stagingVC) => {
    let errors = validate(stagingVC);
    if (errors.length > 0) {
      props.showErrors(errors);
      return;
    }
    setLoading(true);
    Api.putVendorCompany(stagingVC)
      .then((response) => {
        changePage(page + 1);
      })
      .finally(() => {
        setEditIndex(-1);
      });
  };
  const deleteVendorCompany = (stagingVC) => {
    setLoading(true);
    Api.deleteVendorCompany(stagingVC)
      .then((response) => {
        changePage(page + 1);
      })
      .finally(() => {
        setEditIndex(-1);
      });
  };

  const restoreVendorCompany = (stagingVC) => {
    setLoading(true);
    Api.restoreVendorCompany(stagingVC)
      .then((response) => {
        changePage(page + 1);
      })
      .finally(() => {
        setEditIndex(-1);
      });
  };

  const validate = (stagingVC) => {
    let errors = [];
    if (stagingVC.companyCode === '') {
      errors.push('Company Code is required!');
    }
    if (
      stagingVC.paymentMethodCodes === null ||
      stagingVC.paymentMethodCodes.length === 0
    ) {
      errors.push('At least one payment method is required!');
    }
    return errors;
  };
  const massEditVendorCompany = (stagingVC) => { };

  return (
    <>
      {!loading && (
        <Table
          className={Styles.table}
          footer={
            vendorCompanies?.length ? (
              <Toolbar>
                <Toolbar.Item>
                  {`Showing ${vendorCompanies.length * (page + 1) -
                    vendorCompanies.length +
                    1
                    } - ${vendorCompanies.length * (page + 1)
                    } of ${count} results`}
                </Toolbar.Item>
                <Toolbar.Item right>
                  <Pagination
                    className={Styles.pagination}
                    pages={pages}
                    onChange={(page) => changePage(page)}
                  />
                </Toolbar.Item>
              </Toolbar>
            ) : null
          }>
          <Table.Head>
            <Table.Head.Row>
              <Table.Head.Cell>Company Code</Table.Head.Cell>
              <Table.Head.Cell style={{ textAlign: 'center' }}>
                Indiv Pmt
              </Table.Head.Cell>
              <Table.Head.Cell style={{ textAlign: 'center' }}>
                PM
              </Table.Head.Cell>
              <Table.Head.Cell style={{ textAlign: 'center' }}>
                Pay Block
              </Table.Head.Cell>
              <Table.Head.Cell style={{ textAlign: 'center' }}>
                PSTNG Block
              </Table.Head.Cell>
              <Table.Head.Cell>Alt Payee</Table.Head.Cell>
              {
                (hasIVRole || hasVendorAdminRole) &&
                <Table.Head.Cell>Edit</Table.Head.Cell>
              }
            </Table.Head.Row>
          </Table.Head>
          {vendorCompanies?.length ? (
            <Table.Body>
              {vendorCompanies?.map((vc, index) => (
                <Table.Body.Row key={index}>
                  {editIndex === index ? (
                    <VendorCompanyEditRow
                      vc={vc}
                      key={index}
                      system={systemCode}
                      vendorNumber={vendorNumber}
                      getVendors={props.getVendors}
                      postVendorCompany={postVendorCompany}
                      putVendorCompany={putVendorCompany}
                      cancel={() => setEditIndex(-1)}
                      hasIVRole={hasIVRole}
                      hasVendorAdminRole={hasVendorAdminRole}
                    />
                  ) : (
                    <>
                      <Table.Body.Cell
                        className={vc.deletionFlag ? Styles.deleted : ''}>
                        {vc.companyCode} - {vc.companyName}
                      </Table.Body.Cell>
                      <Table.Body.Cell
                        className={vc.deletionFlag ? Styles.deleted : ''}
                        style={{ textAlign: 'center' }}>
                        {vc.individualPayment ? 'Yes' : 'No'}
                      </Table.Body.Cell>
                      <Table.Body.Cell
                        className={vc.deletionFlag ? Styles.deleted : ''}
                        style={{ textAlign: 'center' }}>
                        {vc.paymentMethodCodes.length > 0
                          ? vc.paymentMethodCodes.join(', ')
                          : '-'}
                      </Table.Body.Cell>
                      <Table.Body.Cell
                        className={vc.deletionFlag ? Styles.deleted : ''}
                        style={{ textAlign: 'center' }}>
                        {vc.paymentBlock ? vc.paymentBlock : '-'}
                      </Table.Body.Cell>
                      <Table.Body.Cell
                        className={vc.deletionFlag ? Styles.deleted : ''}
                        style={{ textAlign: 'center' }}>
                        {vc.postingBlock ? 'Yes' : 'No'}
                      </Table.Body.Cell>
                      <Table.Body.Cell
                        className={vc.deletionFlag ? Styles.deleted : ''}>
                        {' '}
                        {vc.alternativePayeeNumber
                          ? vc.alternativePayeeNumber
                          : '-'}
                      </Table.Body.Cell>
                      {
                        (hasIVRole || hasVendorAdminRole) &&
                        <Table.Body.Cell>
                          {!vc.deletionFlag ? (
                            <>
                              <Button
                                onClick={() => setEditIndex(index)}
                                variant="primary"
                                size="small"
                                style={{
                                  height: '30px',
                                  marginRight: '10px',
                                }}>
                                <PencilIcon size="small" />
                              </Button>
                              <Button
                                onClick={() => deleteVendorCompany(vc)}
                                variant="secondary"
                                size="small"
                                style={{
                                  height: '30px',
                                }}>
                                <TrashcanEmptyIcon size="small" />
                              </Button>
                            </>
                          ) : (
                            hasVendorAdminRole &&
                            <Button
                              onClick={() => restoreVendorCompany(vc)}
                              variant="secondary"
                              size="small"
                              style={{
                                height: '30px',
                              }}>
                              <UndoIcon size="small" />
                            </Button>
                          )}
                        </Table.Body.Cell>
                      }
                    </>
                  )}
                </Table.Body.Row>
              ))}
            </Table.Body>
          ) : (
            <>
              {!addMode && (
                <tbody className={Styles.notFound}>
                  <tr>
                    <td colSpan={7}>
                      <p
                        style={{
                          marginTop: '50px',
                        }}>
                        No results found
                      </p>
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          )}
          {addMode && (
            <Table.Body>
              <VendorCompanyEditRow
                vc={newVendorCompany}
                system={systemCode}
                vendorNumber={vendorNumber}
                getVendors={props.getVendors}
                postVendorCompany={postVendorCompany}
                putVendorCompany={putVendorCompany}
                cancel={() => setAddMode(false)}
                hasIVRole={hasIVRole}
                hasVendorAdminRole={hasVendorAdminRole}
              />
            </Table.Body>
          )}
        </Table>
      )}

      <Loading nobg isLoading={loading} />
      {
        hasVendorAdminRole &&
      <Button
      onClick={() => {
          setNewVendorCompany({});
          setAddMode(true);
        }}
        style={{
          marginRight: '10px',
        }}
        variant="primary">
        Add New
      </Button>
        }
      {/* {vendorCompanies?.length > 0 &&
        <Button
          onClick={() => {
            setNewVendorCompany({})
            setMassEdit(true)
          }}
          variant="secondary">Mass Edit
      </Button>
      } */}
      <GenericModal
        large
        show={massEdit}
        title="Mass Edition"
        content={
          <div>
            <Table>
              <Table.Head>
                <Table.Head.Row>
                  <Table.Head.Cell>Company Code</Table.Head.Cell>
                  <Table.Head.Cell style={{ textAlign: 'center' }}>
                    Indiv Pmt
                  </Table.Head.Cell>
                  <Table.Head.Cell style={{ textAlign: 'center' }}>
                    PM
                  </Table.Head.Cell>
                  <Table.Head.Cell style={{ textAlign: 'center' }}>
                    Pay Block
                  </Table.Head.Cell>
                  <Table.Head.Cell style={{ textAlign: 'center' }}>
                    PSTNG Block
                  </Table.Head.Cell>
                  <Table.Head.Cell>Alt Payee</Table.Head.Cell>
                </Table.Head.Row>
              </Table.Head>
              <Table.Body>
                <VendorCompanyEditRow
                  vc={newVendorCompany}
                  system={systemCode}
                  vendorNumber={vendorNumber}
                  getVendors={props.getVendors}
                  putVendorCompany={massEditVendorCompany}
                  cancel={() => setMassEdit(false)}
                  hideButtons
                  hasIVRole={hasIVRole}
                  hasVendorAdminRole={hasVendorAdminRole}
                />
              </Table.Body>
            </Table>
            {
              hasVendorAdminRole &&
              <>
                <Button
                  onClick={() => {
                    setNewVendorCompany({});
                    setMassEdit(false);
                  }}
                  variant="primary">
                  Save
            </Button>

                <Button
                  onClick={() => {
                    setNewVendorCompany({});
                    setMassEdit(false);
                  }}
                  variant="secondary">
                  Cancel
            </Button>
              </>
            }
          </div>
        }
        close={() => setMassEdit(false)}
      />
    </>
  );
};

export default VendorCompanyTable;
