import React, { useState, useEffect } from 'react';
import { GenericModal } from 'Components/GenericModal';
import { ManageVendorSearch } from 'Partials/ManageVendorSearch';
import { Button, SearchIcon } from '@exxonmobil/react-unity';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { Controller } from 'react-hook-form';

import Style from './style.module.css';

const AdvancedVendorSearch = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [vendor, setVendor] = useState({});

  let debounceTimer = null;
  const minLength = props.minLength ? props.minLength : 0;

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>{props.data.code}</components.MultiValue>
  );

  useEffect(() => {
    if (Object.keys(vendor).length !== 0) {
      //solution by https://spectrum.chat/react-hook-form/help/reset-only-one-field~3b9427eb-bfd2-4244-8c5e-00874085fd4c

      props.reset({ ...props.getValues(), [props.name]: vendor });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  const selectVendor = (vendorNumber, vendorName) => {
    setVendor({ vendorName: vendorName, vendorNumber: vendorNumber });
    setModalShow(false);
  };

  const closeModal = () => {
    props.reset({ ...props.getValues() });
    setModalShow(false);
  };

  return (
    <div className={props.className}>
      {props.label && (
        <div className={Style.label}>
          {props.label}
          <div className={Style.optional}>{props.optional && '(Optional)'}</div>
        </div>
      )}
      <div className={Style.align}>
        <Controller
          as={
            <AsyncSelect
              isDisabled={props.disabled}
              isMulti={props.isMulti}
              loadOptions={(value) => {
                if (debounceTimer) clearTimeout(debounceTimer);
                if (value.length >= minLength)
                  return new Promise((resolve, reject) => {
                    debounceTimer = setTimeout(
                      () => {
                        resolve(props.loadOptions(value));
                      },
                      props.loadWait ? props.loadWait : 0
                    );
                  });
              }}
              getOptionValue={(option) =>
                props.code ? option[props.code] : option.code
              }
              getOptionLabel={(option) =>
                props.code && props.desc
                  ? `${option[props.code]} - ${option[props.desc]}`
                  : `${option.code} - ${option.name}`
              }
              onInputChange={handleInputChange}
              placeholder={props.placeholder}
              isClearable={props.isClearable}
              backspaceRemoves={true}
              deleteRemoves={true}
              loadingMessage={(value) => {
                return value.inputValue.length >= minLength
                  ? 'Loading ...'
                  : `Enter at least ${minLength} characters`;
              }}
              noOptionsMessage={(value) => {
                return value.inputValue.length >= minLength
                  ? 'No options'
                  : 'Start typing to search';
              }}
              className="react-select-container"
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c69b0',
                },
              })}
              components={{ MultiValue }}
            />
          }
          name={props.name}
          register={props.register}
          control={props.control}
          onChange={([selected]) => {
            if (props.setSelected) props.setSelected(selected);
            return selected;
          }}
          defaultValue=""
          menuPortalTarget={document.body}
        />

        <Button
          className="em-u-margin-half"
          variant="primary"
          disabled={props.disabled}
          onClick={() => {
            setModalShow(true);
          }}>
          <SearchIcon size="small" />
        </Button>
      </div>
      <GenericModal
        show={modalShow}
        title="Search Alternative Payee"
        content={
          <div>
            <ManageVendorSearch
              defaultSystem={
                props.system ? props.system : props.getValues().system
              }
              defaultCompanyCode={props.getValues().company?.code}
              selectVendor={selectVendor}></ManageVendorSearch>
          </div>
        }
        close={() => closeModal()}
        huge
      />
    </div>
  );
};

export default AdvancedVendorSearch;
