import React from 'react';

import Style from './style.module.css';

const InvoiceStatusBadge = (props) => {
  const { status, paymentBlock } = props;

  return (
    <div
      className={`${Style.status} ${
        status === 'Open' && paymentBlock
          ? Style.Blocked
          : Style[status.replace(' ', '')]
      }`}>{`${
      status === 'Open' && paymentBlock
        ? `Blocked - ${paymentBlock}`
        : status === 'Paid'
        ? 'SAP Paid'
        : status
    }`}</div>
  );
};

export default InvoiceStatusBadge;
