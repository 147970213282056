import React from 'react';
import { Button, XFilledIcon } from '@exxonmobil/react-unity';
import Styles from './InvoiceMassUpload.module.css';

const ResultError = (props) => {
  return (
    <>
      <div className={`${Styles.checkcontainer} ${Styles.resulterr}`}>
        <XFilledIcon className={Styles.check} />
      </div>
      <div>
        <p className={Styles.checktitle}>Could not process the file.</p>
        <pre>{props.error}</pre>
        <div>
          <Button
            variant="default"
            size="small"
            style={{ marginRight: '20px' }}
            onClick={props.home}>
            Go to Home
          </Button>
          <Button variant="default" size="small" onClick={props.reset}>
            Try again
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResultError;
