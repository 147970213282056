import { combineReducers } from 'redux';

import authReducer from 'Store/Reducers/authenticationSlice';
import paymentFileReducer from 'Store/Reducers/paymentFileSlice';
import preDisbursementReducer from 'Store/Reducers/preDisbursementSlice';
import uiReducer from 'Store/Reducers/uiSlice';

export default combineReducers({
  auth: authReducer,
  paymentFile: paymentFileReducer,
  preDisbursement: preDisbursementReducer,
  ui: uiReducer,
});
