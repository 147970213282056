import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CalendarIcon,
  StickyNoteIcon,
  GlobeIcon,
  FactoryIcon,
  User1Icon,
  Grid,
  Alert,
  Loader,
} from '@exxonmobil/react-unity';
import { useHistory } from 'react-router-dom';

import { GenericModal } from 'Components/GenericModal';

import { DisplayByVendor } from 'Partials/DisplayByVendor';

import Styles from './style.module.css';

export const PaymentFilePage = (props) => {
  const userRoles = useSelector(
    (state) => state.auth.accountInfo.account.idTokenClaims.roles
  );
  const ref = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [extractPaymentFile, setExtractPaymentFile] = useState(false);
  const [creditMemoWarning, setCreditMemoWarning] = useState(false);
  const [preCreditMemoWarning, setPreCreditMemoWarning] = useState(false);
  const [extractErrors, setExtractErrors] = useState(false);

  const [totalSelected, setTotalSelected] = useState(null);
  const [totalSumValue, setTotalSumValue] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const invoices = props.invoices;
  const queryTimestamp = props.queryTimestamp;

  const preDisbursement = useSelector((state) => state.preDisbursement);
  const fileTemplateId = props.invoices?.filter.fileTemplateId;

  const refs = {};
  useEffect(() => {
    setInvoiceLoading(true);
    invoicesSelected();
    invoicesTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preDisbursement.invoices]);

  useEffect(() => {
    if (ref.current) {
      dispatch(props.setLoading(false));
    }
    if (ref.current && invoiceLoading) {
      setInvoiceLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSelected]);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  const scrollTo = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: refs[getFirstPositiveRef(preDisbursement.invoices)].offsetTop,
    });
  };

  const invoicesSelected = () => {
    const invoices = preDisbursement.invoices;
    const total = Object.keys(invoices).map((item, index) => {
      return invoices[index].reduce((acc, item) => {
        return item.selected ? ++acc : acc;
      }, 0);
    });
    setTotalSelected(total.reduce((acc, item) => acc + item, 0));
  };

  const validateSelection = () => {
    if (!checkCreditMemos(preDisbursement.invoices)) {
      setExtractPaymentFile(true);
      return;
    }
    setExtractPaymentFile(false);
    setExtractErrors(true);
  };

  const invoicesTotal = () => {
    const invoices = preDisbursement.invoices;
    let subtotals = getSubtotals(invoices);
    if (subtotals != null) {
      let currencies = subtotals
        .map((inv) => inv[0].currency)
        .filter((value, index, self) => self.indexOf(value) === index);

      let obj = currencies.map((item) => {
        return {
          currency: item,
          amount: subtotals.reduce((acc, subtotal) => {
            return (
              acc +
              (subtotal[0].currency === item && parseFloat(subtotal[0].amount))
            );
          }, 0),
        };
      });
      setTotalSumValue(obj);
      invoiceLoading && setInvoiceLoading(false);
    }
  };

  const getSubtotals = (invoices) => {
    return Object.keys(invoices).map((item, index) => {
      if (invoices[index].length > 0) {
        let uniqueCurrencies = invoices[index]
          .filter((inv) => inv.selected)
          .map((inv) => inv.documentCurrency)
          .filter((value, index, self) => self.indexOf(value) === index);

        if (uniqueCurrencies.length > 0) {
          return uniqueCurrencies.map((item) => {
            return {
              currency: item,
              amount: invoices[index].reduce((acc, invoice) => {
                return (
                  acc +
                  (invoice.selected &&
                    invoice.documentCurrency === item &&
                    parseFloat(invoice.documentAmount))
                );
              }, 0),
            };
          });
        }
      }
      return [
        {
          currency: 'USD',
          amount: 0,
        },
      ];
    });
  };

  const extractPayment = () => {
    history.push({
      pathname: '/extract-payment-file',
      state: {
        invoices: flattenInvoiceList(preDisbursement.invoices),
        fileTemplateName: props.fileTemplate,
        fileTemplateId,
        filter: props.query,
        queryTimestamp: queryTimestamp,
      },
    });
  };

  const flattenInvoiceList = (list) => {
    return Object.keys(list).reduce((acc, item) => {
      return [...acc, ...list[item]];
    }, []);
  };

  const removeDuplicates = (list) => {
    return list.filter(
      (invoice, index, self) =>
        index === self.findIndex((t) => t.id === invoice.id)
    );
  };
  const getFirstPositiveRef = (selectedList) => {
    let list = flattenInvoiceList(
      selectedList.concat(preDisbursement.invoices)
    );
    list = removeDuplicates(list);
    let positiveRef = null;
    list = list.filter((invoice) => invoice.selected);
    props.invoices.results.filter((company) => {
      if (company.vendors) {
        let vendors = company.vendors.filter((vendor) => {
          if (vendor.invoices.length > 0) {
            let invoices = vendor.invoices.filter((invoice) => {
              return list.reduce(
                (acc, curr) => acc || curr.id === invoice.id,
                false
              );
            });

            let totals = invoices.reduce((prev, curr) => {
              return (prev += curr.documentAmount);
            }, 0);
            if (totals > 0 && positiveRef == null) {
              positiveRef =
                company.payingCompanyCode + vendor.invoiceVendorCode;
            }
          }
          return invoices.length > 0;
        });
        return vendors.length > 0;
      } else {
        return true;
      }
    });
    return positiveRef;
  };

  const checkCreditMemos = (selectedList) => {
    let isPositive = false;
    let list = flattenInvoiceList(
      selectedList.concat(preDisbursement.invoices)
    );
    list = removeDuplicates(list);
    list = list.filter((invoice) => invoice.selected);
    props.invoices.results.filter((company) => {
      if (company.vendors) {
        let vendors = company.vendors.filter((vendor) => {
          if (vendor.invoices.length > 0) {
            let invoices = vendor.invoices.filter((invoice) => {
              return list.reduce(
                (acc, curr) => acc || curr.id === invoice.id,
                false
              );
            });

            let totals = invoices.reduce((prev, curr) => {
              return (prev += curr.documentAmount);
            }, 0);
            if (totals > 0) {
              isPositive = true;
            }
          }
          return invoices.length > 0;
        });
        return vendors.length > 0;
      } else {
        return true;
      }
    });
    isPositive ? setCreditMemoWarning(true) : setCreditMemoWarning(false);
    return isPositive;
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const redirectReport = (query) => {
    history.push({
      pathname: '/generate-file',
      search: `?filter=${query}`,
    });
  };

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.content}>
          {userRoles.includes('PaymentReleaser') && (
            <p className={Styles.title}>
              Review and select lines to include in the payment file
            </p>
          )}
          <div className={Styles.alignRight}>
            {invoiceLoading ? (
              <div className={Styles.loaderDiv}>
                {' '}
                <Loader className={Styles.loader} />{' '}
                <div> Loading Invoices </div>{' '}
              </div>
            ) : totalSelected ? (
              totalSelected +
              ` ${
                userRoles.includes('PaymentReleaser') ? 'Selected' : 'Total'
              } Invoices`
            ) : (
              'No invoices returned'
            )}
          </div>
          {preDisbursement.invoices &&
            !invoiceLoading &&
            totalSumValue?.map((item, index) => {
              return (
                <div key={index} className={Styles.alignRight}>
                  {item.currency + ' '}
                  {formatter.format(item.amount)}
                </div>
              );
            })}
          <Grid variant="2-up">
            <Grid.Item>
              <div className={Styles.leftGrid}>
                <Grid variant="2-up">
                  {props.fileTemplate && (
                    <Grid.Item>
                      <div className={Styles.alignInline}>
                        <StickyNoteIcon className={Styles.Icon} />
                        <div className={Styles.gridText}>
                          File Template:{props.fileTemplate}
                        </div>
                      </div>
                    </Grid.Item>
                  )}
                  {props.companies && (
                    <Grid.Item>
                      <div className={Styles.largerGridItem}>
                        <FactoryIcon className={Styles.Icon} />
                        <div className={Styles.gridText}>
                          Companies:
                          {props.companies ? props.companies.join(', ') : '-'}
                        </div>
                      </div>
                    </Grid.Item>
                  )}
                  {props.dueDate && (
                    <Grid.Item>
                      <div className={Styles.alignInline}>
                        <CalendarIcon className={Styles.Icon} />
                        <div className={Styles.gridText}>
                          Due Date: {props.dueDate}
                        </div>
                      </div>
                    </Grid.Item>
                  )}
                  {props.clusterTeam && (
                    <Grid.Item>
                      <div className={Styles.largerGridItem}>
                        <User1Icon className={Styles.Icon} />
                        <div className={Styles.gridText}>
                          Cluster/Team:
                          {props.clusterTeam ? props.clusterTeam : '-'}
                        </div>
                      </div>
                    </Grid.Item>
                  )}
                  {props.sapSystem && (
                    <Grid.Item>
                      <div className={Styles.alignInline}>
                        <GlobeIcon className={Styles.Icon} />
                        <div className={Styles.gridText}>
                          Sap System: {props.sapSystem ? props.sapSystem : '-'}
                        </div>
                      </div>
                    </Grid.Item>
                  )}
                </Grid>
              </div>
            </Grid.Item>
            <Grid.Item className={Styles.rightGrid}>
              <div className={Styles.alignRight}>
                <Button
                  className={Styles.button}
                  onClick={() => {
                    redirectReport(props.query);
                  }}>
                  Change Filters
                </Button>
                <Button
                  className={Styles.button}
                  onClick={() => validateSelection()}
                  variant="primary"
                  disabled={
                    !totalSelected || !userRoles.includes('PaymentReleaser')
                  }>
                  Extract Payment File
                </Button>
              </div>
            </Grid.Item>
          </Grid>
          {creditMemoWarning && (
            <Alert
              className={Styles.alert}
              onClick={() => scrollTo()}
              onClose={() => setCreditMemoWarning(false)}
              variant="warning">
              {' '}
              This report contains at least one vendor with a positive amount
              balance. Click here to view it.
            </Alert>
          )}
          {preCreditMemoWarning && !creditMemoWarning && (
            <Alert
              className={Styles.alert}
              onClose={() => setPreCreditMemoWarning(false)}
              variant="warning">
              {' '}
              Vendors with a positive amount balance have been unchecked.
            </Alert>
          )}
        </div>
      </div>
      {!(invoices == null) &&
        invoices.results.map((invoice, index) => (
          <DisplayByVendor
            company={invoice}
            index={index}
            readOnly={!userRoles.includes('PaymentReleaser')}
            key={`accordion-${index}`}
            onCheck={(list) => checkCreditMemos(list)}
            refs={refs}
            onCreditMemoCheck={(value) =>
              !value ? setPreCreditMemoWarning(!value) : null
            }
          />
        ))}
      {props.loading && (
        <div className={Styles.pageLoader}>
          <div className={Styles.loadSize}>
            <Loader />
          </div>
        </div>
      )}

      <GenericModal
        show={extractPaymentFile}
        title="Confirm extraction"
        content={
          <div>
            <p>
              Once the file is processed, all selected records will be marked as{' '}
              <b>processed</b>.
            </p>
            <p>Are you sure you want to proceed? </p>
          </div>
        }
        footer={true}
        primaryButton="Extract File"
        secondaryButton="Go back"
        primaryAction={() => extractPayment()}
        secondaryAction={() => setExtractPaymentFile(false)}
      />
      <GenericModal
        show={extractErrors}
        title="Error"
        content={
          <div>
            <p>
              The current selection contains vendors with positive amount
              balance.
            </p>
            <p>Please review the credit memo’s selection and try again.</p>
          </div>
        }
        footer={true}
        primaryButton="Go Back"
        primaryAction={() => setExtractErrors(false)}
      />
    </>
  );
};

export default PaymentFilePage;
