import React, { useEffect, useState } from 'react';
import { Button } from '@exxonmobil/react-unity';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';

import Style from './style.module.css';
import { DatePickerGroup } from 'Components/DatePickerGroup';
import { Loading } from 'Components/Loading';
import { PaymentLogsTable } from 'Partials/PaymentLogsTable';
import { SelectGroup } from 'Components/SelectGroup';
import moment from 'moment';

import Api from 'Services/paymentFileService';

export const PaymentLogsFilter = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  //const [fileTemplateValue, setFileTemplateValue] = useState();
  const fileTemplateValue = '';
  const [fileTemplates, setFileTemplates] = useState([]);
  const [fileTemplatesLoading, setFileTemplatesLoading] = useState(false);

  const { register, handleSubmit, getValues, control, errors } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const { dueDateStart, dueDateEnd, fileTemplateId } = data;
    const { getLogs } = props;
    const filters = {};

    filters.startDate = dueDateStart;
    filters.endDate = dueDateEnd;
    if (fileTemplateId !== 'Please select') {
      filters.paymentTemplateId = Number(fileTemplateId);
    }

    const results = await getLogs(window.btoa(JSON.stringify(filters)));
    setPaymentLogs(results);
    setSubmitted(true);
    setLoading(false);
  };

  const today = () => {
    const now = new Date();
    return new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
  };

  const dueDateErrorMessage = {
    // minDate: 'Due date should not be sooner than today',
    invalidDate:
      'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
    maxDate: 'Due date should not be later than 21 working days from now',
    laterThanStart: 'End Date should not be sooner than Start Date',
    required: 'Required',
  };

  const maxDateValidation = (value) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const workingDays = 31;
    return Math.round((new Date(value) - today()) / oneDay) <= workingDays;
  };

  useEffect(() => {
    let mounted = true;

    const getFileTemplates = () => {
      if (!fileTemplates.length) {
        setFileTemplatesLoading(true);
        Api.getFileTemplates().then((resp) => {
          if (mounted) {
            resp && setFileTemplates(resp.data);
            setFileTemplatesLoading(false);
          }
        });
      }
    };
    getFileTemplates();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    register(
      { name: 'dueDateStart' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          maxDate: (value) => {
            return maxDateValidation(value);
          },
        },
      }
    );

    register(
      { name: 'dueDateEnd' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          laterThanStart: (value) => {
            return new Date(value) >= new Date(getValues('dueDateStart'));
          },
          maxDate: (value) => {
            return maxDateValidation(value);
          },
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={Style.content}>
        <div className={Style.title}>Payment Logs</div>
        <div className={Style.filters}>
          <DatePickerGroup
            className={Style.item}
            id="due-date-start"
            control={control}
            register={register}
            name="dueDateStart"
            label="Generate Date Start"
            error={dueDateErrorMessage[errors.dueDateStart?.type]}
          />
          <DatePickerGroup
            className={Style.item}
            id="due-date-end"
            control={control}
            register={register}
            name="dueDateEnd"
            label="Generate Date End"
            error={dueDateErrorMessage[errors.dueDateEnd?.type]}
          />
          <div className={Style.item}>
            <SelectGroup
              name="fileTemplateId"
              optional={true}
              label="File Template"
              register={register}
              control={control}
              options={fileTemplates}
              option="description"
              value={fileTemplateValue}
              isLoading={fileTemplatesLoading}
            />
          </div>
          <Button
            className={Style.button}
            type="submit"
            onClick={() => onSubmit}
            variant="primary">
            Filter Invoices
          </Button>
        </div>
      </form>
      <Loading isLoading={loading} message={'Generating Report...'} />
      {paymentLogs.length && !loading ? (
        <PaymentLogsTable logs={paymentLogs} fileTemplates={fileTemplates} />
      ) : (
        submitted && <div className={Style.notFound}>No results found</div>
      )}
      <DevTool control={control} />
    </>
  );
};

export default PaymentLogsFilter;
