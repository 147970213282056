/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Feedback } from 'Components/Feedback';
import {
  ThemeProvider,
  unityTheme,
  Page,
  Footer,
  Container,
} from '@exxonmobil/react-unity';
import { authenticate } from 'Store/Reducers/authenticationSlice';

import { HomePage } from 'Containers/HomePage';
import { GenerateFile } from 'Containers/GenerateFile';
import { PaymentSummary } from 'Containers/PaymentSummary';
import { PaymentLogDetails } from 'Containers/PaymentLogDetails';
import { PaymentFileReview } from 'Containers/PaymentFileReview';
import { Header } from 'Partials/Header';
import { Loading } from 'Components/Loading';
import theme from './theme';
import { ExtractPayment } from 'Partials/ExtractPayment';
import { ErrorHandlingPage } from 'Partials/ErrorHandlingPage';
import { createBrowserHistory } from 'history';
import { PaymentLogList } from 'Containers/PaymentLogList';
import { InvoicesList } from 'Containers/InvoicesList';
import { ChangeLogDetails } from 'Containers/ChangeLogDetails';
import { ChangeLogList } from 'Containers/ChangeLogList';
import { InvoiceDetails } from 'Containers/InvoiceDetails';
import { InvoiceMassUpload } from 'Containers/InvoiceMassUpload';
import { VendorList } from 'Containers/VendorList';
import { VendorDetails } from 'Containers/VendorDetails';

const customTheme = {
  ...unityTheme,
  ...theme,
};

export const history = createBrowserHistory();

const App = (props) => {
  const userRoles = props.accountInfo.account.idTokenClaims.roles;
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  dispatch(
    authenticate({
      account: { ...props.accountInfo.account },
      jwtIdToken: props.accountInfo.jwtIdToken,
    })
  );

  return (
    <Router history={history}>
      <ThemeProvider theme={customTheme}>
        <Page>
          <Page.Header>
            <Header />
          </Page.Header>
          <Page.Body>
            <Container className="container">
              <Feedback application="PayBCP"></Feedback>
              <Loading isLoading={ui.isLoading} />
              <Switch>
                <Route exact path="/" component={HomePage}></Route>
                <Route path="/generate-file" component={GenerateFile}></Route>
                <Route
                  path="/forecast-payment-summary"
                  component={PaymentSummary}></Route>
                {userRoles.includes('Treasury') && (
                  <Route
                    path="/payment-log-list"
                    component={PaymentLogList}></Route>
                )}
                <Route
                  path="/payment-file-review"
                  component={PaymentFileReview}></Route>
                <Route
                  path="/extract-payment-file"
                  component={ExtractPayment}></Route>
                <Route
                  path="/payment-log-details"
                  component={PaymentLogDetails}></Route>
                <Route
                  exact
                  path="/invoices/manage"
                  component={InvoicesList}></Route>
                <Route path="/invoices/new" component={InvoiceDetails}></Route>
                <Route path="/invoices/:id" component={InvoiceDetails}></Route>
                <Route path="/invoices/six-month-history"></Route>
                <Route
                  exact
                  path="/vendors/manage"
                  component={VendorList}></Route>
                {userRoles.includes('VendorAdmin') || userRoles.includes('VendorIV') && (
                  <Route path="/vendors/new" component={VendorDetails}></Route>
                )}
                <Route
                  path="/vendors/:systemCode/:vendorNumber"
                  component={VendorDetails}></Route>
                <Route
                  exact
                  path="/changelog-list"
                  component={ChangeLogList}></Route>
                <Route
                  exact
                  path="/changelog-list/:entityGuid"
                  component={ChangeLogList}></Route>
                <Route
                  exact
                  path="/changelog/:id"
                  component={ChangeLogDetails}></Route>
                <Route path="/error-page" component={ErrorHandlingPage}></Route>
                <Route
                  path="/invoices-upload"
                  component={InvoiceMassUpload}></Route>
              </Switch>
            </Container>
          </Page.Body>
          <Page.Footer>
            <Footer style={{ marginTop: '0' }} />
          </Page.Footer>
        </Page>
      </ThemeProvider>
    </Router>
  );
};

export default App;
