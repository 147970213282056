import Api from 'Providers/apiProvider';

export default {
  getFileTemplates: async () => {
    try {
      const data = await Api.get('/Templates');
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getCompanies: async (query) => {
    try {
      const data = await Api.get('/Companies', { params: { filter: query } });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getCompaniesBySystem: async (systemCode, query) => {
    try {
      const data = await Api.get(`/Companies/${systemCode}`, { params: { filter: query } });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getClusters: async () => {
    try {
      const data = await Api.get('/Clusters');
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getCountries: async () => {
    try {
      const data = await Api.get('/Countries');
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getStatus: async () => {
    try {
      const data = await Api.get('/Status');
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getSapSystems: async () => {
    try {
      const data = await Api.get('/SapSystems');
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getInvoices: async (filter) => {
    try {
      const data = await Api.get('/Invoices', { params: { filter } });
      return data;
    } catch (error) {
      console.log('HTTP Error: ', error);
    }
  },
  putExtractPaymentFile: async (paymentTemplateId, invoices) => {
    try {
      const data = await Api.put(`/PaymentFiles`, invoices, {
        params: { paymentTemplateId },
      });
      return data;
    } catch (error) {
      console.log('HTTP Error: ', error);
    }
  },
  getTreasuryInvoices: async (filter) => {
    const { dueDateStart, dueDateEnd, sort, companyCode } = filter;
    try {
      const data = await Api.get('/Invoices/treasuryInvoices', {
        params: { dueDateStart, dueDateEnd, sort, companyCode },
      });
      return data;
    } catch (error) {
      console.log('HTTP Error: ', error);
    }
  },
  getPaymentFile: async (id) => {
    try {
      const data = await Api.get(`/PaymentFiles/${id}`);
      return data;
    } catch (error) {
      console.log('HTTP Error: ', error);
    }
  },
  getPaymentLogs: async (filter) => {
    try {
      const data = await Api.get('/PaymentFiles', {
        params: { filter },
      });
      return data;
    } catch (error) {
      console.log('HTTP Error: ', error);
    }
  },
};
