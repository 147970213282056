import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GenerateFileFilters } from 'Partials/GenerateFileFilters';
import Api from 'Services/paymentFileService';
import {
  setClusters,
  setSapSystems,
  setFileTemplates,
} from 'Store/Reducers/paymentFileSlice';

export const GenerateFile = () => {
  const paymentFile = useSelector((state) => state.paymentFile);
  const { fileTemplates, clusters, sapSystems } = paymentFile;
  const dispatch = useDispatch();

  const [clustersLoading, setClustersLoading] = useState(false);
  const [sapSystemsLoading, setSapSystemsLoading] = useState(false);
  const [fileTemplatesLoading, setFileTemplatesLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const getFileTemplates = () => {
      if (!fileTemplates.length) {
        setFileTemplatesLoading(true);
        Api.getFileTemplates().then((resp) => {
          if (mounted) {
            resp && dispatch(setFileTemplates(resp.data));
            setFileTemplatesLoading(false);
          }
        });
      }
    };
    getFileTemplates();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    const getClusters = () => {
      if (!clusters.length) {
        setClustersLoading(true);
        Api.getClusters().then((resp) => {
          if (mounted) {
            resp && dispatch(setClusters(resp.data));
            setClustersLoading(false);
          }
        });
      }
    };
    getClusters();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    const getSapSystems = () => {
      if (!sapSystems.length) {
        setSapSystemsLoading(true);
        Api.getSapSystems().then((resp) => {
          if (mounted) {
            resp && dispatch(setSapSystems(resp.data));
            setSapSystemsLoading(false);
          }
        });
      }
    };
    getSapSystems();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchCompanies = async (query) => {
    const list = await Api.getCompanies(query);
    return list.data;
  };

  const onFilter = async (filters) => {
    const report = await Api.getInvoices(filters);
    return report.data;
  };

  const params = new URLSearchParams(window.location.search);
  const filterApplied =
    params.get('filter') && JSON.parse(window.atob(params.get('filter')));

  return (
    <GenerateFileFilters
      fileTemplates={fileTemplates}
      fileTemplatesLoading={fileTemplatesLoading}
      sapSystems={sapSystems}
      sapSystemsLoading={sapSystemsLoading}
      clusters={clusters}
      clustersLoading={clustersLoading}
      companies={onSearchCompanies}
      filter={onFilter}
      filterApplied={filterApplied}
    />
  );
};

export default GenerateFile;
