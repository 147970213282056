import Api from 'Providers/apiProvider';

export default {
  getInvoices: async (
    top = 10,
    skip = 0,
    filter = '',
    myWork = false,
    sixMonths = false
  ) => {
    try {
      const data = await Api.get('/Invoices/list', {
        params: { top, skip, filter, myWork, sixMonths },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getInvoiceDetails: async (id) => {
    try {
      const data = await Api.get(`/Invoices/${id}`);

      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getInvoiceComments: async (id) => {
    try {
      const data = await Api.get(`/Invoices/${id}/comments`);
      if (data.status === 404) return { data: [] };
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  putInvoiceDetails: async (id, invoice, timestamp) => {
    try {
      const data = await Api.put(`/Invoices/${id}`, invoice, {
        params: { timestamp },
      });

      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  postInvoiceDetails: async (invoice) => {
    try {
      const data = await Api.post(`/Invoices/`, invoice);

      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getClusters: async (isNew = false) => {
    try {
      const data = await Api.get('/Clusters?isNew=' + isNew);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },

  getPaymentMethods: async (systemCode, countryCode) => {
    try {
      const data = await Api.get('/PaymentMethods', {
        params: { systemCode, countryCode },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getVendorPaymentMethods: async (systemCode, companyCode, vendorNumber) => {
    try {
      const data = await Api.get('/PaymentMethods', {
        params: { systemCode, companyCode, vendorNumber },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getAttachments: async (id) => {
    try {
      const data = await Api.get(`/invoices/${id}/attachments`);
      if (data.status === 404) return { data: [] };
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  downloadAttachment: async (id) => {
    try {
      const data = await Api.get(`/attachments/${id}`, {
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  deleteAttachment: async (id) => {
    try {
      const data = await Api.delete(`/InvoiceAttachments/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  deleteComment: async (id) => {
    try {
      const data = await Api.delete(`/InvoiceComments/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  putInvoicesMassBlock: async (invoices, block) => {
    try {
      const data = await Api.put('/Invoices/list', invoices, {
        params: { block },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  postInvoiceComment: async (id, comment) => {
    try {
      const data = await Api.post(`/invoices/${id}/comments`, comment);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  postInvoiceAttahcments: async (id, files) => {
    try {
      const data = await Api.post(`/invoices/${id}/attachments`, files, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  postInvoiceMassUpload: async (files) => {
    try {
      const data = await Api.post(`/MassInvoice`, files, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  getChangeSets: async (
    entity = 'invoice',
    top = 10,
    skip = 0,
    filter = ''
  ) => {
    try {
      const data = await Api.get('/changesets', {
        params: { entity, top, skip, filter },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },

  getInvoiceChangeSets: async (
    entityId,
    entity = 'invoice',
    top = 10,
    skip = 0,
    filter = ''
  ) => {
    try {
      const data = await Api.get('/changesets', {
        params: { entityId, entity, top, skip, filter },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },

  getChangeLogs: async (id) => {
    try {
      const data = await Api.get(`/changeSets/${id}/changeLogs`);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },

  getChangeSetById: async (id) => {
    try {
      const data = await Api.get(`/changeSets/${id}`);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getVendors: async (query, systemCode, companyCode) => {
    try {
      const data = await Api.get(
        `/systems/${systemCode}/companies/${companyCode}/vendors`,
        {
          params: {
            search: query,
          },
        }
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getCurrencies: async () => {
    try {
      const data = await Api.get('/Currencies');
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
};
