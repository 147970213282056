import React from 'react';
import Style from './style.module.css';

export const Feedback = (props) => {
  return (
    <a
      className={Style.feedback}
      href={'https://www.surveymonkey.com/r/7FWKNPK?app=' + props.application}
      target="_blank"
      rel="noopener noreferrer">
      Feedback
    </a>
  );
};

export default Feedback;
