import React, { useState, useEffect } from 'react';
import { Loader } from '@exxonmobil/react-unity';
import { useHistory } from 'react-router-dom';
import Api from 'Services/invoicesService';
import Result from './Result';
import Upload from './Upload';
import Styles from './InvoiceMassUpload.module.css';

const InvoiceMassUpload = () => {
  const allowedTypes = ['xls', 'xlsx'];
  const [fileError, setFileError] = useState('');
  const [filePosted, setFilePosted] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [results, setResults] = useState(null);
  let history = useHistory();

  useEffect(() => {
    document.body.className = loadingPage ? Styles.pageloading : '';
  }, [loadingPage]);

  const reset = () => {
    setFileError('');
    setFilePosted(null);
    setLoadingPage(false);
    setResults(null);
  };

  const goHome = () => {
    history.push('/');
  };

  const submitFile = async () => {
    if (filePosted == null) return;
    setLoadingPage(true);
    const data = new FormData();
    data.append('attachments', filePosted);
    await Api.postInvoiceMassUpload(data).then((result) => {
      setLoadingPage(false);
      setResults(result);
    });
  };

  const showFile = (event) => {
    if (event.length > 0) {
      let fileName = event[0].name.toLowerCase();
      let fileExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      setFileError(
        allowedTypes.includes(fileExt)
          ? ''
          : 'Invalid file type. Only allowed Excel files.'
      );

      setFilePosted(event[0]);
    }
  };

  return (
    <>
      {loadingPage && (
        <>
          <Loader className={Styles.loader} />
          <h1>Your file is being processed. Please wait ...</h1>
        </>
      )}
      {!loadingPage && results && (
        <Result results={results} reset={reset} home={goHome} />
      )}
      {!loadingPage && !results && (
        <Upload
          submitFile={submitFile}
          fileError={fileError}
          filePosted={filePosted}
          showFile={showFile}
        />
      )}
    </>
  );
};

export default InvoiceMassUpload;
