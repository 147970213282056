import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SelectGroup } from 'Components/SelectGroup';
import { useForm } from 'react-hook-form';
import { Switch } from '@exxonmobil/react-unity';
import { TextInputGroup } from 'Components/TextInputGroup';
import { VendorCompanyTable } from 'Partials/VendorCompanyTable';
import { BankInformationTable } from 'Partials/BankInformationTable';
import { useState } from 'react';
import PaymentFileService from 'Services/paymentFileService';
import VendorsService from 'Services/vendorsService';
import InvoicesService from 'Services/invoicesService';
import { Loading } from 'Components/Loading';
import { AdvancedVendorSearch } from 'Components/AdvancedVendorSearch';
import { GenericModal } from 'Components/GenericModal';
import {
  Button,
  CalendarIcon,
  Grid,
  GridItem,
  User1Icon,
  TextLink
} from '@exxonmobil/react-unity';

import Styles from './style.module.css';
import { CommentList } from 'Partials/CommentList';
import { AttachmentList } from 'Partials/AttachmentList';

const VendorDetailsPage = (props) => {
  const history = useHistory();
  const { register, control, errors, getValues, handleSubmit, reset } = useForm(
    {
      mode: 'onBlur',
    }
  );
  const { vendor, userRoles } = props;

  const [systems, setSystems] = useState([]);
  const [countries, setCountries] = useState([]);
  const [systemsLoading, setSystemsLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [system, setSystem] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [country, setCountry] = useState('');
  const [postingBlock, setPostingBlock] = useState(false);
  const [taxId, setTaxId] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    getSystems();
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    register({ name: 'systemCode' }, { required: 'Required' });
    register({ name: 'vendorName' }, { required: 'Required' });
    register({ name: 'countryCode' }, { required: 'Required' });
    register({ name: 'taxId' }, { required: false });
    register({ name: 'region' }, { required: false });
    register({ name: 'city' }, { required: 'Required' });
    register({ name: 'street' }, { required: false });
    register({ name: 'zipCode' }, { required: 'Required' });
    register({ name: 'altPayee' }, { required: false });
  }, [register]);

  useEffect(() => {
    if (vendor.vendorNumber) {
      setSystem(vendor.systemCode);
      setVendorName(vendor.vendorName);
      setTaxId(vendor.taxID);
      setCountry(vendor.countryCode);
      setRegion(vendor.region);
      setCity(vendor.city);
      setStreet(vendor.street);
      setZipCode(vendor.zipCode);
      setPostingBlock(vendor.postingBlock);
      reset({
        systemCode: vendor.systemCode,
        vendorName: vendor.vendorName,
        taxId: vendor.taxID,
        countryCode: vendor.countryCode,
        region: vendor.region,
        city: vendor.city,
        street: vendor.street,
        zipCode: vendor.zipCode,
        altPayee: vendor.altPayee
          ? { vendorNumber: vendor.altPayee, vendorName: vendor.altPayeeName }
          : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor, reset]);

  const getSystems = () => {
    setSystemsLoading(true);
    PaymentFileService.getSapSystems().then((resp) => {
      resp && setSystems(resp.data);
      setSystemsLoading(false);
    });
  };
  const getCountries = () => {
    setCountryLoading(true);
    PaymentFileService.getCountries().then((resp) => {
      resp && setCountries(resp.data);
      setCountryLoading(false);
    });
  };
  const hasIVRole = () => userRoles.indexOf("VendorIV") >= 0
  const hasVendorAdminRole = () => userRoles.indexOf("VendorAdmin") >= 0

  const getVendorsBySystem = async (query, system) => {
    const list = await VendorsService.getVendorsBySystem(query, system);
    return list.data;
  };

  const getVendors = async (query, system, company) => {
    const list = await InvoicesService.getVendors(query, system, company);
    return list.data;
  };

  const showErrors = (errors) => {
    setValidationErrors(errors);
  };

  const onSubmit = () => {
    let stagingVendor = {
      ...getValues(),
      postingBlock,
    };
    stagingVendor.altPayee = stagingVendor.altPayee
      ? stagingVendor.altPayee.vendorNumber
      : null;
    if (vendor.vendorNumber) {

      stagingVendor.changeReason = "Update"
      props.putVendor({ ...stagingVendor, vendorNumber: vendor.vendorNumber });
      return;
    }
    stagingVendor.changeReason = "Add"

    props.postVendor(stagingVendor);
  };

  return (
    <>
      {!props.loading && (
        <div>
          <div className={Styles.header}>
            <div className={Styles.document}>
              <div className={Styles.documentNumber}>
                {vendor?.vendorNumber
                  ? `Vendor #${vendor.vendorNumber} - ${vendor.vendorName}`
                  : `New Vendor`}
              </div>
            </div>

            <div className={Styles.info}>
              <div className={Styles.details}>
                <div>
                  <User1Icon size="small" className={Styles.icon} />
                  Created By: {vendor.processor || '-'}
                </div>
                <div>
                  <CalendarIcon size="small" className={Styles.icon} />
                  Created Date:{' '}
                  {vendor.entryDate !== null
                    ? new Date(vendor.entryDate).toLocaleDateString()
                    : '-'}
                </div>
              </div>
              <div className={Styles.actions}>
                {hasVendorAdminRole() &&
                  <>
                    <Button variant="primary" type="submit" form="vendor-form">
                      Save
                </Button>
                    {vendor.vendorNumber && (
                      <Button
                        variant="primary"
                        color="negative"
                        onClick={() => props.deleteVendor(vendor)}>
                        Delete
                      </Button>
                    )}
                  </>
                }
                <Button
                  variant="secondary"
                  onClick={() => history.push('/vendors/manage/')}>
                  Go back
                </Button>
                {vendor.entityGuid &&
                  <TextLink href={`/changelog-list/${vendor.entityGuid}?entity=vendor`}>
                    View Change Log
              </TextLink>
                }
              </div>
            </div>
          </div>
          <div className={Styles.content}>
            <div className={Styles.title}>Vendor Details</div>
            <form id="vendor-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid variant="3-up">
                <GridItem>
                  <SelectGroup
                    useDesc={true}
                    label={'SAP System'}
                    name="systemCode"
                    control={control}
                    register={register}
                    options={systems}
                    option="systemCode"
                    isLoading={systemsLoading}
                    onChange={(element) => setSystem(element.target.value)}
                    error={errors.system}
                    value={system}
                    disabled={!hasVendorAdminRole()}
                  />
                </GridItem>
                <GridItem>
                  <TextInputGroup
                    className={Styles.input}
                    name="vendorName"
                    register={register}
                    control={control}
                    label="Vendor Name"
                    maxLength="100"
                    error={errors.vendorName}
                    value={vendorName}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <TextInputGroup
                    className={Styles.input}
                    name="taxId"
                    register={register}
                    control={control}
                    label="Tax ID"
                    maxLength="100"
                    error={errors.taxId}
                    value={taxId}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <SelectGroup
                    useDesc={true}
                    label={'Country'}
                    name="countryCode"
                    control={control}
                    register={register}
                    options={countries}
                    option="countryCode"
                    isLoading={countryLoading}
                    onChange={(element) => setCountry(element.target.value)}
                    error={errors.country}
                    value={country}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <TextInputGroup
                    className={Styles.input}
                    name="region"
                    register={register}
                    control={control}
                    label="Region"
                    maxLength="100"
                    error={errors.region}
                    value={region}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <TextInputGroup
                    className={Styles.input}
                    name="city"
                    register={register}
                    control={control}
                    label="City"
                    maxLength="100"
                    error={errors.city}
                    value={city}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <TextInputGroup
                    className={Styles.input}
                    name="street"
                    register={register}
                    control={control}
                    label="Street Address"
                    maxLength="100"
                    error={errors.streetAddress}
                    value={street}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <TextInputGroup
                    className={Styles.input}
                    name="zipCode"
                    register={register}
                    control={control}
                    label="ZIP Code"
                    maxLength="100"
                    error={errors.zipCode}
                    value={zipCode}
                    disabled={!hasVendorAdminRole()}

                  />
                </GridItem>
                <GridItem>
                  <div>
                    <AdvancedVendorSearch
                      placeholder="Type to search"
                      name="altPayee"
                      label="Alt. Payee"
                      register={register}
                      control={control}
                      loadOptions={(query) => getVendorsBySystem(query, system)}
                      minLength="3"
                      loadWait="500"
                      error={errors.altPayee}
                      code="vendorNumber"
                      desc="vendorName"
                      reset={reset}
                      getValues={getValues}
                      disabled={!hasVendorAdminRole()}

                    />
                  </div>
                </GridItem>
                <GridItem>
                  {
                    hasVendorAdminRole() ?

                      <Switch
                        label="Central Posting Block"
                        active={postingBlock}
                        onClick={() => setPostingBlock(!postingBlock)} />
                      :
                      `Central Posting Block: ${postingBlock ? "Yes" : "No"}`
                  }
                </GridItem>
              </Grid>
            </form>
          </div>
          {vendor.vendorNumber && (
            <>
              <div className={Styles.content}>
                <div className={Styles.title}>Company Information</div>
                <VendorCompanyTable
                  systemCode={system}
                  vendorNumber={vendor.vendorNumber}
                  countryCode={country}
                  getVendors={getVendors}
                  showErrors={showErrors}
                  hasIVRole={hasIVRole()}
                  hasVendorAdminRole={hasVendorAdminRole()}
                />
              </div>
              <div className={Styles.content}>
                <div className={Styles.title}>Banking Information</div>
                <BankInformationTable
                  systemCode={system}
                  countries={countries}
                  vendorNumber={vendor.vendorNumber}
                  showErrors={showErrors}
                  hasVendorAdminRole={hasVendorAdminRole()}

                />
              </div>
            </>
          )}
        </div>
      )}
      {vendor.systemCode != null && vendor.vendorNumber != null && (
        <div className={Styles.content}>
          <div className={Styles.list}>
            <div className={Styles.title}>Attachments</div>
            <AttachmentList
              attachments={props.attachments}
              getAttachments={props.getAttachments}
              addAttachments={props.addAttachments}
              downloadAttachment={props.downloadAttachment}
              deleteAttachment={props.deleteAttachment}
              systemCode={vendor.systemCode}
              vendorNumber={vendor.vendorNumber}
            />
          </div>
          <div className={Styles.list}>
            <div className={Styles.title}>Comments</div>
            <CommentList
              deleteComments={props.deleteVendorComment}
              comments={props.vendorComments}
              maxLength={5000}
              onSubmit={props.addVendorComment}
            />
          </div>
        </div>
      )}
      <Loading isLoading={props.loading} />
      <GenericModal
        show={validationErrors.length > 0}
        title="Validation Errors"
        content={
          <div>
            {validationErrors.map((item) => (
              <p>{item}</p>
            ))}
          </div>
        }
        close={() => setValidationErrors([])}
      />
    </>
  );
};

export default VendorDetailsPage;
