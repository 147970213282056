import React, { useEffect, useState } from 'react';

import { ChangeLogDetailsPage } from 'Partials/ChangeLogDetailsPage';

import Api from 'Services/invoicesService';

const ChangeLogDetails = (props) => {
  const [changeLogs, setChangeLogs] = useState([]);
  const [entityObject, setEntityObject] = useState([]);
  const [changeSet, setChangeSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = props.match.params;

  useEffect(() => {
    getChanges(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChangeLogs = async (id) => {
    const changeLogs = await Api.getChangeLogs(id);
    setChangeLogs(changeLogs.data);
  };

  const getChangeSetById = async (id) => {
    const result = await Api.getChangeSetById(id);
    setEntityObject(result.data.entityObject);
    setChangeSet(result.data.changeSet);
  };

  const getChanges = async (id) => {
    setLoading(true);
    await Promise.all([getChangeLogs(id), getChangeSetById(id)]);
    setLoading(false);
  };

  return (
    <ChangeLogDetailsPage
      entityObject={entityObject}
      changeSet={changeSet}
      changeLogs={changeLogs}
      loading={loading}></ChangeLogDetailsPage>
  );
};

export default ChangeLogDetails;
