import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  CalendarIcon,
  FactoryIcon,
  GlobeIcon,
  Grid,
  NewspaperIcon,
  User1Icon,
  SuitcaseIcon,
} from '@exxonmobil/react-unity';

import Styles from './style.module.css';

const InvoiceDetailsReadOnly = (props) => {
  const { invoice } = props;
  const history = useHistory();
  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ? currencyCode : 'USD',
    });
  };

  return (
    <div>
      <div className={Styles.header}>
        <div className={Styles.document}>
          <div
            className={
              Styles.documentNumber
            }>{`Document #${invoice.documentNumber}`}</div>
        </div>

        <div className={Styles.info}>
          <div className={Styles.details}>
            <div>
              <div>
                <GlobeIcon size="small" className={Styles.icon} />
                SAP System: {invoice.system || '-'}
              </div>
              <div>
                <FactoryIcon size="small" className={Styles.icon} />
                Company: {invoice.company || '-'}
              </div>
            </div>
            <div>
              <div>
                <CalendarIcon size="small" className={Styles.icon} />
                Fiscal Year: {invoice.fiscalYear || '-'}
              </div>
              <div>
                <SuitcaseIcon size="small" className={Styles.icon} />
                Vendor: {invoice.vendorNumber + '-' + invoice.vendorName || '-'}
              </div>
            </div>
            <div>
              <div>
                <CalendarIcon size="small" className={Styles.icon} />
                Entry Date:{' '}
                {invoice.entryDate !== null
                  ? new Date(invoice.entryDate).toLocaleDateString()
                  : '-'}
              </div>
              <div>
                <User1Icon size="small" className={Styles.icon} />
                Processed By: {invoice.processor || '-'}
              </div>
            </div>
            <div>
              <NewspaperIcon size="small" className={Styles.icon} />
              Status:
              {invoice.status === 'Open' && invoice.paymentBlock ? (
                <div className={`${Styles.status} ${Styles.blocked}`}>
                  {`Blocked - ${invoice.paymentBlock}`}
                </div>
              ) : invoice.status === 'Processed' && !invoice.paymentBlock ? (
                <div className={`${Styles.status} ${Styles.Processed}`}>
                  {invoice.status}
                </div>
              ) : invoice.status === 'Draft' && !invoice.paymentBlock ? (
                <div className={`${Styles.status} ${Styles.Draft}`}>
                  {invoice.status}
                </div>
              ) : invoice.status === 'Cancelled' && !invoice.paymentBlock ? (
                <div className={`${Styles.status} ${Styles.Cancelled}`}>
                  {invoice.status}
                </div>
              ) : invoice.status === 'Paid' && !invoice.paymentBlock ? (
                <div className={`${Styles.status} ${Styles.Paid}`}>
                  {invoice.status}
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className={Styles.actions}>
            <Button
              variant="secondary"
              onClick={() => history.push('/invoices/manage/')}>
              Go Back
            </Button>
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.title}>Invoice Details</div>
        <Grid variant="3-up">
          <Grid.Item>
            <div className={Styles.label}>Posting Key</div>
            <div>{invoice.pk || '-'}</div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Document Date</div>
            <div>
              {invoice.invoiceDate !== null
                ? new Date(invoice.invoiceDate).toLocaleDateString()
                : '-'}
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Posting Date</div>
            <div>
              {invoice.postingDate !== null
                ? new Date(invoice.postingDate).toLocaleDateString()
                : '-'}
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Invoice Number</div>
            <div>{invoice.invoiceNumber || '-'}</div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Payment Amount</div>
            <div>{invoice.amount < 0 ? formatter(invoice.currencyCode).format(invoice.amount*-1) : formatter(invoice.currencyCode).format(invoice.amount)}</div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Due Date</div>
            <div>
              {invoice.dueDate !== null
                ? new Date(invoice.dueDate).toLocaleDateString()
                : '-'}
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Cluster/ Team</div>
            <div>{invoice.cluster || '-'}</div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Payment Method</div>
            <div>{invoice.paymentMethod || '-'}</div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Payment Block</div>
            <div>{invoice.paymentBlock || '-'}</div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Clearing/ Payment Date</div>
            {invoice.postingDate !== null
              ? new Date(invoice.postingDate).toLocaleDateString()
              : '-'}
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>Clearing Document/ Payment Field</div>
            <div>
              {invoice.paymentFileId || invoice.clearingDocument || '-'}
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className={Styles.label}>PO Number</div>
            <div>{invoice.poNumber || '-'}</div>
          </Grid.Item>
        </Grid>
      </div>
    </div>
  );
};

export default InvoiceDetailsReadOnly;
