import React from 'react';
import ResultOk from './ResultOk';
import ResultWarning from './ResultWarning';
import ResultError from './ResultError';
import Styles from './InvoiceMassUpload.module.css';

function Result(props) {
  return (
    <div className={Styles.resultcontainer}>
      {props.results.status !== 202 ? (
        <ResultError
          error={props.results.data}
          reset={props.reset}
          home={props.home}
        />
      ) : props.results.data.failedRows.length > 0 ? (
        <ResultWarning
          total={props.results.data.addedRows}
          errorList={props.results.data.failedRows}
          reset={props.reset}
          home={props.home}
        />
      ) : (
        <ResultOk
          total={props.results.data.addedRows}
          reset={props.reset}
          home={props.home}
        />
      )}
    </div>
  );
}

export default Result;
