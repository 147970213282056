import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { DatePickerGroup } from 'Components/DatePickerGroup';
import { Loading } from 'Components/Loading';
import { SelectGroup } from 'Components/SelectGroup';
import { TextInputGroup } from 'Components/TextInputGroup';
import {
  Alert,
  Button,
  CalendarIcon,
  FactoryIcon,
  GlobeIcon,
  Grid,
  TextArea,
  NewspaperIcon,
  TextLink,
  User1Icon,
} from '@exxonmobil/react-unity';

import Styles from './style.module.css';
import { GenericModal } from 'Components/GenericModal';
import { CommentList } from 'Partials/CommentList';
import { AttachmentList } from 'Partials/AttachmentList';
import { InvoiceDetailsReadOnly } from 'Partials/InvoiceDetailsReadOnly';
import { NewInvoicePage } from 'Partials/NewInvoicePage';
import moment from 'moment';

const InvoiceDetailsPage = (props) => {
  const { invoice } = props;
  // const timestamp = new Date();
  const history = useHistory();
  const {
    register,
    control,
    errors,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ mode: 'onChange' });

  const [paymentBlock, setPaymentBlock] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cluster, setCluster] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [savePopup, setSavePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [changeReason, setChangeReason] = useState('');
  const [length, setLength] = useState(5000);

  const handleChange = (event) => {
    if (event.target.value.length > 5000) {
      return;
    }

    setLength(5000 - event.target.value.length);
    setChangeReason(event.target.value);
  };

  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ? currencyCode : 'USD',
    });
  };
  const [paymentBlocks] = useState([
    { id: 'A', name: 'A' },
    { id: 'Z', name: 'Z' },
    { id: '0', name: 'Open' },
  ]);
  const onSubmit = (data) => {
    setSavePopup(true);
  };

  const onDelete = () => {
    setDeletePopup(true);
  };

  const updateInvoice = () => {
    const form = { ...getValues() };
    // form.documentDate = new Date(form.documentDate).toISOString();
    form.dueDate = new Date(form.dueDate + 'Z').toISOString();
    props.updateInvoice({
      ...invoice,
      ...form,
      cluster: props.clusters[form.cluster - 1]?.name,
      clusterId: form.cluster,
      changeReason: changeReason,
    });
    setSavePopup(false);
    setChangeReason('');
  };

  const deleteInvoice = () => {
    invoice.Status = 'Cancelled';
    props.deleteInvoice(invoice);
    setDeletePopup(false);
  };

  const dueDateErrorMessage = {
    invalidDate:
      'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
    laterThanStart: 'Due Date should not be sooner than Document Date',
    required: 'Required',
  };

  useEffect(() => {
    register(
      { name: 'documentDate' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
        },
      }
    );
    register(
      { name: 'dueDate' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          laterThanStart: (value) => {
            return new Date(value) >= new Date(getValues('documentDate'));
          },
        },
      }
    );
    register({ name: 'paymentBlock' });
    register({ name: 'paymentMethod' });
    register({ name: 'cluster' });
    register(
      { name: 'poNumber' },
      {
        pattern: {
          value: /(null)|(^[0-9]+$)/,
          message: 'Only numbers are accepted',
        },
      }
    );
  }, [getValues, register]);

  useEffect(() => {
    setPaymentBlock(invoice.paymentBlock);
    setPaymentMethod(invoice.paymentMethod);
    setCluster(invoice.clusterId);
    setPoNumber(invoice.poNumber);
    // Set Default Form Values
    // https://github.com/react-hook-form/react-hook-form/issues/2492
    reset({
      documentDate: new Date(invoice.invoiceDate).toLocaleDateString(),
      dueDate: new Date(invoice.dueDate).toLocaleDateString(),
      paymentBlock: invoice.paymentBlock,
      paymentMethod: invoice.paymentMethod,
      cluster: invoice.clusterId,
      poNumber: invoice.poNumber,
    });
  }, [invoice, reset]);

  return (
    <div className={Styles.container}>
      {props.edited && (
        <Alert variant="success" onClose={() => props.setEdited(false)}>
          Invoice Edited
        </Alert>
      )}
      {invoice.status !== 'Open' && invoice.status !== 'Draft' && (
        <InvoiceDetailsReadOnly invoice={invoice}></InvoiceDetailsReadOnly>
      )}
      {invoice.status === 'Draft' && (
        <NewInvoicePage
          invoice={invoice}
          systems={props.systems}
          clusters={props.clusters}
          paymentMethods={props.paymentMethods}
          currencies={props.currencies}
          getPaymentMethods={props.getPaymentMethods}
          getCompanies={props.getCompanies}
          getVendors={props.getVendors}
          systemsLoading={props.systemsLoading}
          currenciesLoading={props.currenciesLoading}
          companiesLoading={props.companiesLoading}
          saveInvoice={props.saveInvoice}
          updateInvoice={props.updateInvoice}></NewInvoicePage>
      )}
      {invoice.status === 'Open' && (
        <div className={Styles.header}>
          <div className={Styles.document}>
            <div
              className={
                Styles.documentNumber
              }>{`Document #${invoice.documentNumber}`}</div>
            <span className={Styles.vendor}>
              {`Vendor ${invoice.vendorNumber} - ${invoice.vendorName}`}
            </span>
          </div>

          <div className={Styles.info}>
            <div className={Styles.details}>
              <div>
                <GlobeIcon size="small" className={Styles.icon} />
                SAP System: {invoice.system || '-'}
              </div>
              <div>
                <FactoryIcon size="small" className={Styles.icon} />
                Company: {invoice.company || '-'}
              </div>
              <div>
                <CalendarIcon size="small" className={Styles.icon} />
                Entry Date:{' '}
                {new Date(invoice.entryDate).toLocaleDateString() || '-'}
              </div>
              <div>
                <User1Icon size="small" className={Styles.icon} />
                Processed By: {invoice.processor || '-'}
              </div>
              <div>
                <NewspaperIcon size="small" className={Styles.icon} />
                Status:
                {invoice.status === 'Open' && invoice.paymentBlock ? (
                  <div className={`${Styles.status} ${Styles.blocked}`}>
                    {`Blocked - ${invoice.paymentBlock}`}
                  </div>
                ) : invoice.status === 'Open' && !invoice.paymentBlock ? (
                  <div className={`${Styles.status} ${Styles.open}`}>
                    {invoice.status}
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className={Styles.actions}>
              <Button variant="primary" type="submit" form="invoice-form">
                Save Changes
              </Button>
              {invoice.id && (
                <Button
                  variant="primary"
                  color="negative"
                  onClick={() => onDelete()}>
                  Cancel Invoice
                </Button>
              )}

              <Button
                variant="secondary"
                onClick={() => history.push('/invoices/manage/')}>
                Go Back
              </Button>
                {invoice.entityGuid && 
              <TextLink href={`/changelog-list/${invoice.entityGuid}?entity=invoice`}>
                View Change Log
              </TextLink>
              }
            </div>
          </div>
        </div>
      )}

      <div className={Styles.content}>
        {invoice.status === 'Open' && (
          <form id="invoice-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className={Styles.title}>Invoice Details</div>
            <Grid variant="3-up">
              <Grid.Item>
                <DatePickerGroup
                  className={Styles.date}
                  id="due-date"
                  control={control}
                  register={register}
                  name="dueDate"
                  label="Due Date"
                  error={dueDateErrorMessage[errors.dueDate?.type]}
                />
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.select}>
                  <SelectGroup
                    name="paymentBlock"
                    optional
                    label="Payment Block"
                    register={register}
                    control={control}
                    options={paymentBlocks}
                    option="name"
                    value={paymentBlock}
                  />
                </div>
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.select}>
                  <SelectGroup
                    name="paymentMethod"
                    optional={true}
                    label="Payment Method"
                    register={register}
                    control={control}
                    options={props.paymentMethods}
                    option="paymentMethodCode"
                    isLoading={props.paymentMethodsLoading}
                    value={paymentMethod}
                  />
                </div>
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.select}>
                  <SelectGroup
                    name="cluster"
                    register={register}
                    control={control}
                    label="Cluster/Team"
                    optional
                    options={props.clusters}
                    option="name"
                    isLoading={props.clustersLoading}
                    value={cluster}
                  />
                </div>
              </Grid.Item>
              <Grid.Item>
                <TextInputGroup
                  className={Styles.input}
                  name="poNumber"
                  register={register}
                  control={control}
                  label="PO Number"
                  optional
                  maxLength="10"
                  value={poNumber}
                  error={errors.poNumber}
                />
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.label}>Document Date</div>
                <div>
                  {new Date(invoice.invoiceDate).toLocaleDateString() || '-'}
                </div>
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.label}>Invoice Number</div>
                <div>{invoice.invoiceNumber}</div>
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.label}>Payment Amount</div>
                <div>
                  {invoice.amount > 0
                    ? `${formatter(invoice.currencyCode).format(
                        invoice.amount
                      )}`
                    : `${formatter(invoice.currencyCode).format(
                        invoice.amount * -1
                      )}`}
                </div>
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.label}>Posting Key</div>
                <div>{invoice.postingKey || '-'}</div>
              </Grid.Item>
              <Grid.Item>
                <div className={Styles.label}>Posting Date</div>
                <div>
                  {new Date(invoice.postingDate).toLocaleDateString() || '-'}
                </div>
              </Grid.Item>
            </Grid>
          </form>
        )}
        {invoice.id != null && (
          <div>
            <div className={Styles.list}>
              <div className={Styles.title}>Attachments</div>
              <AttachmentList
                attachments={props.attachments}
                addAttachments={props.addAttachments}
                deleteAttachment={props.deleteAttachment}
                getAttachments={props.getAttachments}
                downloadAttachment={props.downloadAttachment}
                invoiceId={invoice.id}
              />
            </div>
            <div className={Styles.list}>
              <div className={Styles.title}>Comments</div>
              <CommentList
                deleteComments={props.deleteInvoiceComments}
                comments={props.invoiceComments}
                maxLength={5000}
                onSubmit={props.addComment}
              />
            </div>
          </div>
        )}
      </div>
      <GenericModal
        show={props.concurrencyError}
        title="Error Editing Invoice"
        content={
          <div>
            <p>This invoice has already been updated.</p>
            <p>Please refresh the page and try again.</p>
          </div>
        }
        footer={true}
        primaryButton="Refresh Page"
        primaryAction={() => {
          props.getInvoice(invoice.id);
        }}
      />
      <GenericModal
        show={savePopup}
        title="Confirm update?"
        content={
          <div>
            <TextArea
              label="Change Reason"
              placeholder="Type a comment..."
              id="add-change-reason-invoice"
              className={Styles.textArea}
              value={changeReason}
              onChange={(e) => handleChange(e)}
            />
            <div className={Styles.footer}>
              <div>{length} characters left</div>
            </div>
          </div>
        }
        footer={true}
        primaryButton="Save Changes"
        primaryAction={() => updateInvoice()}
        secondaryButton="Cancel"
        secondaryAction={() => setSavePopup(false)}
      />

      <GenericModal
        show={deletePopup}
        title="Confirm Invoice Cancellation?"
        content={<div></div>}
        footer={true}
        primaryButton="Cancel Invoice"
        primaryAction={() => deleteInvoice()}
        secondaryButton="Cancel"
        secondaryAction={() => setDeletePopup(false)}
      />
      <Loading isLoading={props.loading} />
    </div>
  );
};
export default InvoiceDetailsPage;
