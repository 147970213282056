import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  TextLink,
  CalendarIcon,
  FactoryIcon,
  GlobeIcon,
  Table,
  User1Icon,
  Button,
  CreditCardIcon,
} from '@exxonmobil/react-unity';

import { CommentList } from 'Partials/CommentList';
import { Loading } from 'Components/Loading';

import Styles from './style.module.css';

const ChangeLogDetailsPage = (props) => {
  const { changeLogs, entityObject, changeSet } = props;
  const id = entityObject.id;
  const history = useHistory();

  const comment = [
    {
      createdBy: changeSet.changedBy || 'No person assigned',
      createdDate: changeSet.changedDate || '',
      commentText: changeSet.changeReason || 'No change reason provided',
    },
  ];

  return (
    <div className={Styles.container}>
      {changeSet.entity === 'INVOICE' && (
        <div>
          <div className={Styles.header}>
            <div className={Styles.document}>
              <div className={Styles.documentNumber}>{`Document ${
                entityObject.documentNumber
                  ? '#' + entityObject.documentNumber
                  : 'Empty'
              }`}</div>
            </div>

            <div className={Styles.info}>
              <div className={Styles.details}>
                <div>
                  <div>
                    <GlobeIcon size="small" className={Styles.icon} />
                    SAP System: {entityObject.systemCode || '-'}
                  </div>
                  <div>
                    <FactoryIcon size="small" className={Styles.icon} />
                    Company: {entityObject.companyCode || '-'}
                  </div>
                </div>
                <div>
                  <div>
                    <CalendarIcon size="small" className={Styles.icon} />
                    Fiscal Year: {entityObject.fiscalYear || '-'}
                  </div>
                  <div>
                    <User1Icon size="small" className={Styles.icon} />
                    Processed By: {entityObject.processor || '-'}
                  </div>
                </div>
              </div>
              <div className={Styles.actions}>
                <TextLink href={`/invoices/${id}`}>
                  View Invoice Details
                </TextLink>
                <Button
                  onClick={() => {
                    history.push(`/changelog-list?entity=invoice`);
                  }}>
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {['VENDOR', 'VENDORCOMPANY', 'BANKDETAILS'].indexOf(changeSet.entity) >
        -1 && (
        <div>
          <div className={Styles.header}>
            <div className={Styles.document}>
              <div className={Styles.documentNumber}>{`Vendor ${
                entityObject.vendorNumber
                  ? `# ${entityObject.vendorNumber}`
                  : 'Empty'
              }`}</div>
            </div>

            <div className={Styles.info}>
              {changeSet.entity === 'VENDOR' && (
                <div className={Styles.details}>
                  <div>
                    <div>
                      <CalendarIcon size="small" className={Styles.icon} />
                      Creation Date:{' '}
                      {entityObject.entryDate !== null
                        ? new Date(entityObject.entryDate).toLocaleDateString()
                        : '-'}
                    </div>
                    <div>
                      <User1Icon size="small" className={Styles.icon} />
                      Created By: {entityObject.processor || '-'}
                    </div>
                  </div>
                </div>
              )}
              {changeSet.entity === 'VENDORCOMPANY' && (
                <div className={Styles.details}>
                  <div>
                    <div>
                      <CalendarIcon size="small" className={Styles.icon} />
                      Creation Date:{' '}
                      {entityObject.vendor?.entryDate !== null
                        ? new Date(entityObject.entryDate).toLocaleDateString()
                        : '-'}
                    </div>
                    <div>
                      <User1Icon size="small" className={Styles.icon} />
                      Created By: {entityObject.vendor?.processor || '-'}
                    </div>
                  </div>
                  <div>
                    <div>
                      <FactoryIcon size="small" className={Styles.icon} />
                      Company: {entityObject.companyCode || '-'}
                    </div>
                  </div>
                </div>
              )}
              {changeSet.entity === 'BANKDETAILS' && (
                <div className={Styles.details}>
                  <div>
                    <div>
                      <CalendarIcon size="small" className={Styles.icon} />
                      Creation Date:{' '}
                      {entityObject.vendor?.entryDate !== null
                        ? new Date(entityObject.entryDate).toLocaleDateString()
                        : '-'}
                    </div>
                    <div>
                      <User1Icon size="small" className={Styles.icon} />
                      Created By: {entityObject.vendor?.processor || '-'}
                    </div>
                  </div>
                  <div>
                    <div>
                      <CreditCardIcon size="small" className={Styles.icon} />
                      Bank account number:{' '}
                      {entityObject.bankAccountNumber || '-'}
                    </div>
                    <div>
                      <CreditCardIcon size="small" className={Styles.icon} />
                      Bank key: {entityObject.bankKeys || '-'}
                    </div>
                  </div>
                  <div>
                    <div>
                      <GlobeIcon size="small" className={Styles.icon} />
                      Country code: {entityObject.countryCode || '-'}
                    </div>
                  </div>
                </div>
              )}

              <div className={Styles.actions}>
                <TextLink
                  href={`/vendors/${entityObject.systemCode}/${entityObject.vendorNumber}`}>
                  View Vendor Details
                </TextLink>
                <Button
                  onClick={() => {
                    history.push(`/changelog-list?entity=vendor`);
                  }}>
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={Styles.content}>
        <div className={Styles.title}>{`Changeset ${
          changeLogs.length > 0 ? '#' + changeLogs[0].changeSetId : 'Empty'
        }`}</div>
        <div className={Styles.table}>
          {changeLogs.length > 0 && (
            <Table>
              <Table.Head>
                <Table.Head.Row>
                  <Table.Head.Cell> Change ID </Table.Head.Cell>
                  <Table.Head.Cell> Changed Field </Table.Head.Cell>
                  <Table.Head.Cell> Description </Table.Head.Cell>
                  <Table.Head.Cell> From </Table.Head.Cell>
                  <Table.Head.Cell> To </Table.Head.Cell>
                </Table.Head.Row>
              </Table.Head>
              <Table.Body>
                {changeLogs.map((item, index) => {
                  return (
                    <Table.Body.Row key={`index-${index}`}>
                      <Table.Body.Cell> {item.id || '-'} </Table.Body.Cell>
                      <Table.Body.Cell>
                        {' '}
                        {item.trackEntity.columnName || '-'}{' '}
                      </Table.Body.Cell>
                      <Table.Body.Cell>
                        {' '}
                        {item.trackEntity.fieldDescription || '-'}{' '}
                      </Table.Body.Cell>
                      <Table.Body.Cell>
                        {' '}
                        {item.oldValue || '-'}{' '}
                      </Table.Body.Cell>
                      <Table.Body.Cell>
                        {' '}
                        {item.newValue || '-'}{' '}
                      </Table.Body.Cell>
                    </Table.Body.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </div>
        <div className={Styles.title}>Change Reason</div>
        <CommentList comments={comment} readOnly></CommentList>
      </div>
      <Loading isLoading={props.loading} />
    </div>
  );
};

export default ChangeLogDetailsPage;
