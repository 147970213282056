import React from 'react';
import { Button, CheckFilledIcon } from '@exxonmobil/react-unity';
import Styles from './InvoiceMassUpload.module.css';

const ResultOk = (props) => {
  return (
    <>
      <div className={`${Styles.checkcontainer} ${Styles.resultok}`}>
        <CheckFilledIcon className={Styles.check} />
      </div>
      <div>
        <p className={Styles.checktitle}>
          The file has been successfully processed.
        </p>
        <p className={Styles.checktext}>
          {props.total} invoices have been imported.
        </p>
        <div>
          <Button
            variant="default"
            size="small"
            style={{ marginRight: '20px' }}
            onClick={props.home}>
            Go to Home
          </Button>
          <Button variant="default" size="small" onClick={props.reset}>
            Upload another file
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResultOk;
