import React from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { HorizontalHeader } from '@exxonmobil/react-unity';
import Style from './style.module.css';

export default function Header() {
  const userRoles = useSelector(
    (state) => state.auth.accountInfo.account.idTokenClaims.roles
  );
  return (
    <div>
      <HorizontalHeader
        variant="large"
        className={Style.header}
        title={
          <Link to="/">
            <div className={Style.title}>Global Payables Cyber BCP Tool</div>
          </Link>
        }>
        <HorizontalHeader.Nav>
          <Link to="/">
            <HorizontalHeader.Nav.Item>Home</HorizontalHeader.Nav.Item>
          </Link>
          <HorizontalHeader.Nav.Dropdown text="Payments">
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/generate-file">Generate File</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
            {userRoles.includes('Treasury') && (
              <HorizontalHeader.Nav.Dropdown.Item>
                <Link to="/forecast-payment-summary">
                  Forecast Payment Summary
                </Link>
              </HorizontalHeader.Nav.Dropdown.Item>
            )}
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/payment-log-list">Payment Logs</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
          </HorizontalHeader.Nav.Dropdown>
          <HorizontalHeader.Nav.Dropdown text="Invoices">
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/invoices/manage">Manage Invoices</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/invoices-upload">Invoice Mass Upload</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/invoices/new">New Invoice</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/changelog-list?entity=invoice">Changelog</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
          </HorizontalHeader.Nav.Dropdown>
          <HorizontalHeader.Nav.Dropdown text="Vendors">
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/vendors/manage">Manage Vendors</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
            {(userRoles.includes('VendorAdmin') || userRoles.includes('VendorIV')) && (
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/vendors/new">New Vendor</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
            )}
            <HorizontalHeader.Nav.Dropdown.Item>
              <Link to="/changelog-list?entity=vendor">Changelog</Link>
            </HorizontalHeader.Nav.Dropdown.Item>
          </HorizontalHeader.Nav.Dropdown>
        </HorizontalHeader.Nav>
      </HorizontalHeader>
    </div>
  );
}
