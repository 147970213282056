import { Loading } from 'Components/Loading';
import { ManageInvoicesTable } from 'Partials/ManageInvoicesTable';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from 'Services/paymentFileService';
import { Toggle } from '@exxonmobil/react-unity';
import Styles from './style.module.css';

const InvoicesManagement = (props) => {
  const filterTypes = {
    system: 'System',
    vendor: 'Vendor',
    invoiceNumber: 'Invoice Number',
    amountRange: 'Amount',
    invoiceDateRange: 'Invoice Date',
    dueDateRange: 'Due Date',
    company: 'Company',
    paymentBlock: 'Block',
    cluster: 'Cluster',
    status: 'Status',
    documentNumber: 'Document Number',
    postingKey: 'Posting Key',
    paymentMethod: 'Payment Method',
  };
  const headers = {
    system: 'System',
    company: 'Company',
    vendor: 'Vendor',
    docNo: 'Doc #',
    invoiceDate: 'Invoice Date',
    invoiceNumber: 'Invoice Number',
    cluster: 'Cluster',
    pk: 'PK',
    pm: 'PM',
    status: 'Status',
    dueDate: 'Due Date',
    amount: 'Amount',
  };
  const top = 10;
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterStart, setFilterStart] = useState('');
  const [filterEnd, setFilterEnd] = useState('');
  const [filterType, setFilterType] = useState('system');
  const [selectedFilters, setSelectedFilters] = useState({});

  const [clustersLoading, setClustersLoading] = useState(false);
  const [clusters, setClusters] = useState([]);

  const [systemsLoading, setSystemsLoading] = useState(false);
  const [systems, setSystems] = useState([]);
  const [status, setStatus] = useState([]);
  const [statusLoading, setStatusLoading] = useState([]);
  const [blocks] = useState([
    { id: 'A', name: 'Blocked - A' },
    { id: 'Z', name: 'Blocked - Z' },
  ]);
  const [postingKeys] = useState([
    { id: 31, name: 'Invoice - 31' },
    { id: 21, name: 'Credit Memo - 21' },
  ]);
  const [paymentMethods] = useState([
    { id: 'A', name: 'A' },
    { id: 'M', name: 'M' },
    { id: 'U', name: 'U' },
    { id: 'V', name: 'V' },
    { id: 'W', name: 'W' },
    { id: 'X', name: 'X' },
  ]);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [myWork, setMyWork] = useState('false');
  const [sixMonthsHistory, setSixMonthsHistory] = useState('false');
  const filtersMount = useRef(true);
  const myWorkMount = useRef(true);

  const history = useHistory();

  useEffect(() => {
    setInvoices(defineInvoices(props.invoices));
    getClusters();
    getSystems();
    getStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.invoices]);

  useEffect(() => {
    setPageArray(props.count);
  }, [props.count]);

  useLayoutEffect(() => {
    if (filtersMount.current) {
      filtersMount.current = false;
      return;
    }
    props.loadInvoices(top, page, selectedFilters, myWork, sixMonthsHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useLayoutEffect(() => {
    if (myWorkMount.current) {
      myWorkMount.current = false;
      return;
    }
    props.loadInvoices(top, page, selectedFilters, myWork, sixMonthsHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWork, sixMonthsHistory]);

  const getClusters = () => {
    if (!clusters.length) {
      setClustersLoading(true);
      Api.getClusters().then((resp) => {
        resp && setClusters(resp.data);
        setClustersLoading(false);
      });
    }
  };

  const getSystems = () => {
    if (!clusters.length) {
      setSystemsLoading(true);
      Api.getSapSystems().then((resp) => {
        resp && setSystems(resp.data);
        setSystemsLoading(false);
      });
    }
  };

  const getStatus = () => {
    if (!clusters.length) {
      setStatusLoading(true);
      Api.getStatus().then((resp) => {
        resp && setStatus(resp.data);
        setStatusLoading(false);
      });
    }
  };

  const defineInvoices = (invoices) => {
    return invoices.map((item) => {
      return (
        selectedInvoices.find(
          (selectedInvoice) => selectedInvoice.id === item.id
        ) || { ...item, selected: !!item.selected }
      );
    });
  };

  const checkSelected = (invoices) => {
    const list = [...invoices, ...selectedInvoices];

    const filteredInvoices = list.reduce((acc, current) => {
      const repeated = acc.find((item) => current.id === item.id);

      if (!repeated) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    setSelectedInvoices(filteredInvoices.filter((invoice) => invoice.selected));
  };

  const setActiveFilters = (e) => {
    let filters = {};
    if (filterType.includes('Range')) {
      const filterName = filterType.split('Range')[0];
      filters = {
        ...selectedFilters,
        [`${filterName}Start`]: filterType.includes('Date')
          ? new Date(filterStart).toISOString()
          : filterType.includes('amount')
          ? Number(filterStart.replace(/[^0-9.-]+/g, ''))
          : [
              ...(selectedFilters[filterType]
                ? selectedFilters[filterType]
                : []),
              filterStart,
            ],
        [`${filterName}End`]: filterType.includes('Date')
          ? new Date(filterEnd).toISOString()
          : filterType.includes('amount')
          ? Number(filterEnd.replace(/[^0-9.-]+/g, ''))
          : [
              ...(selectedFilters[filterType]
                ? selectedFilters[filterType]
                : []),
              filterEnd,
            ],
      };
    } else {
      filters = {
        ...selectedFilters,
        [filterType]: filterType.includes('Date')
          ? new Date(filter).toISOString()
          : filterType.includes('amount')
          ? Number(filter.replace(/[^0-9.-]+/g, ''))
          : [
              ...(selectedFilters[filterType]
                ? selectedFilters[filterType]
                : []),
              filter,
            ],
      };
    }

    setSelectedFilters(filters);
    setFilter('');
    setFilterStart('');
    setFilterEnd('');
    e.preventDefault();
  };

  const setFilterTypeValue = (value) => {
    setFilter('');
    setFilterStart('');
    setFilterEnd('');
    setFilterType(value);
  };

  const unsetFilter = (type, filter) => {
    const value = selectedFilters[type];

    if (type.includes('Start') || type.includes('End')) {
      let baseType = '';

      if (type.includes('Start')) {
        baseType = type.replace('Start', '');
      }
      if (type.includes('End')) {
        baseType = type.replace('End', '');
      }

      setSelectedFilters({
        ...selectedFilters,
        [`${baseType}Start`]: Array.isArray(value)
          ? value.filter((item) => item !== filter)
          : '',
        [`${baseType}End`]: Array.isArray(value)
          ? value.filter((item) => item !== filter)
          : '',
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [type]: Array.isArray(value)
          ? value.filter((item) => item !== filter)
          : '',
      });
    }
  };

  const onSort = (column) => {
    const sort = selectedFilters.sortOptions;
    const sorting = {
      ...selectedFilters,
      sortOptions: {
        field: column,
        order: !sort?.order
          ? 'asc'
          : sort?.order === 'asc' && sort.field === column
          ? 'desc'
          : null,
      },
    };

    setSelectedFilters(sorting);
  };

  const allSelected = () => {
    return invoices.every((item) => {
      return item.selected === true;
    });
  };

  const selectAll = () => {
    const list = invoices.reduce((acc, item) => {
      return [...acc, { ...item, selected: !allSelected() }];
    }, []);
    setInvoices(list);
    checkSelected(list);
  };

  const selectInvoice = (invoiceIndex) => {
    const list = invoices.reduce((acc, item, index) => {
      return [
        ...acc,
        {
          ...item,
          selected: invoiceIndex === index ? !item.selected : item.selected,
        },
      ];
    }, []);
    setInvoices(list);
    checkSelected(list);
  };

  const numberSelected = () => {
    return selectedInvoices.reduce((acc, item) => {
      return (acc += item.selected);
    }, 0);
  };

  const setPageArray = (count) => {
    const total = Math.ceil(count / top);
    return setPages([...Array(total).keys()].toString().split(','));
  };

  const changePage = (nextPage) => {
    setPage(nextPage - 1);
    props.loadInvoices(
      top,
      (nextPage - 1) * top,
      selectedFilters,
      myWork,
      sixMonthsHistory
    );
    checkSelected(invoices, selectedInvoices);
  };

  const inputValidation = (input) => {
    if (input.value.length > input.maxLength)
      input.value = input.value.slice(0, input.maxLength);
  };

  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,

      currency: currencyCode ? currencyCode : 'USD',
    });
  };

  const formatAmount = (value, setFunction) => {
    if (value) {
      const valueNumber = Number(value.replace(/[^0-9-]+/g, '') / 100);
      const valueFormatted = formatter().format(valueNumber);
      // .replace(/\D00(?=\D*$)/, '');
      if (valueFormatted !== filter) {
        setFunction(valueFormatted);
      }
    }
    return filter;
  };

  const massBlockEdit = async (block) => {
    setSelectedInvoices([]);
    await props.massBlockEdit(selectedInvoices, block);
    await props.loadInvoices(
      top,
      page,
      selectedFilters,
      myWork,
      sixMonthsHistory
    );
  };

  const editInvoice = (invoice) => {
    const { id } = invoice;
    history.push({
      pathname: `/invoices/${id}`,
      state: { invoice },
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h1 className={Styles.title}>Invoice List</h1>
        <div>
          <div className={Styles.myWork}>
            <span>6 Month History</span>
            <div>
              <Toggle valid className={Styles.toggle}>
                <Toggle.Item
                  id="toggle-on-six-month"
                  key="toggle-on-six-month"
                  name="six-month"
                  value="true"
                  checked={sixMonthsHistory === 'true'}
                  onChange={(e) => setSixMonthsHistory(e.target.value)}>
                  On
                </Toggle.Item>
                <Toggle.Item
                  id="toggle-off-six-month"
                  key="toggle-off-six-month"
                  name="six-month"
                  value="false"
                  checked={sixMonthsHistory === 'false'}
                  onChange={(e) => setSixMonthsHistory(e.target.value)}>
                  Off
                </Toggle.Item>
              </Toggle>
            </div>
          </div>
          <div className={Styles.myWork}>
            <span className={Styles.tagLabel}>My Work</span>
            <div>
              <Toggle valid className={Styles.toggle}>
                <Toggle.Item
                  id="toggle-on"
                  key="toggle-on"
                  name="my-work"
                  value="true"
                  checked={myWork === 'true'}
                  onChange={(e) => setMyWork(e.target.value)}>
                  On
                </Toggle.Item>
                <Toggle.Item
                  id="toggle-off"
                  key="toggle-off"
                  name="my-work"
                  value="false"
                  checked={myWork === 'false'}
                  onChange={(e) => setMyWork(e.target.value)}>
                  Off
                </Toggle.Item>
              </Toggle>
            </div>
          </div>
        </div>
      </div>
      <ManageInvoicesTable
        filterType={filterType}
        filterTypes={filterTypes}
        filter={filter}
        filterStart={filterStart}
        filterEnd={filterEnd}
        selectedFilters={selectedFilters}
        headers={headers}
        page={page}
        pages={pages}
        invoices={invoices}
        count={props.count}
        setActiveFilters={setActiveFilters}
        inputValidation={inputValidation}
        formatAmount={formatAmount}
        setFilter={setFilter}
        setFilterStart={setFilterStart}
        setFilterEnd={setFilterEnd}
        setFilterTypeValue={setFilterTypeValue}
        unsetFilter={unsetFilter}
        formatter={formatter}
        numberSelected={numberSelected}
        changePage={changePage}
        selectAll={selectAll}
        allSelected={allSelected}
        selectInvoice={selectInvoice}
        onSort={onSort}
        selectedInvoices={selectedInvoices}
        massBlockEdit={massBlockEdit}
        editInvoice={editInvoice}
        clusters={clusters}
        clustersLoading={clustersLoading}
        systems={systems}
        systemsLoading={systemsLoading}
        blocks={blocks}
        statuses={status}
        statusLoading={statusLoading}
        postingKeys={postingKeys}
        paymentMethods={paymentMethods}
      />
      <Loading isLoading={props.loading} />
    </div>
  );
};

export default InvoicesManagement;
