/* eslint-disable */
import { Loading } from 'Components/Loading';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  CaretDownIcon,
  CaretUpIcon,
  Pagination,
  Searchbar,
  Select,
  Table,
  Tag,
  Toolbar,
  Tooltip,
} from '@exxonmobil/react-unity';
import Api from 'Services/vendorsService';
import Styles from './style.module.css';

const ManageVendorSearch = (props) => {
  const [vendors, setVendors] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const filterTypes = {
    vendorName: 'Vendor Name',
    vendorNumber: 'Vendor Number',
    country: 'Country',
    region: 'Region',
    city: 'City',
    street: 'Street Address',
    zipCode: 'ZIP Code',
    taxID: 'Tax ID',
    bankAccount: 'Bank Account',
    iban: 'IBAN',
  };
  const headers = {
    vendorName: 'Vendor',
    taxId: 'Tax ID',
    country: 'Country',
    region: 'Region',
    city: 'City',
    street: 'Street Address',
    zip: 'ZIP Code',
  };
  const top = 5;
  const [filter, setFilter] = useState('');
  const [filterType, setFilterType] = useState('system');
  const [selectedFilters, setSelectedFilters] = useState({});
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const filtersMount = useRef(true);

  useEffect(() => {
    loadVendors(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultSystem, props.defaultCompanyCode]);

  const loadVendors = async (top, skip, filter) => {
    setLoading(true);
    let defaultFilter = {
      system: [props.defaultSystem],
      companyCode: props.defaultCompanyCode,
    };
    const query = window.btoa(JSON.stringify({ ...defaultFilter, ...filter }));
    const vendors = await Api.getVendors(top, skip, query);
    setVendors(vendors.data.results);
    setCount(vendors.data.count);
    setLoading(false);
  };

  useEffect(() => {
    setPageArray(count);
  }, [count]);

  useLayoutEffect(() => {
    if (filtersMount.current) {
      filtersMount.current = false;
      return;
    }
    loadVendors(top, page, selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const setActiveFilters = (e) => {
    let filters = {};
    filters = {
      ...selectedFilters,
      [filterType]: [
        ...(selectedFilters[filterType] ? selectedFilters[filterType] : []),
        filter,
      ],
    };
    setSelectedFilters(filters);
    setFilter('');
    e.preventDefault();
  };

  const setFilterTypeValue = (value) => {
    setFilter('');
    setFilterType(value);
  };

  const unsetFilter = (type, filter) => {
    const value = selectedFilters[type];

    setSelectedFilters({
      ...selectedFilters,
      [type]: Array.isArray(value)
        ? value.filter((item) => item !== filter)
        : '',
    });
  };

  const onSort = (column) => {
    const sort = selectedFilters.sortOptions;
    const sorting = {
      ...selectedFilters,
      sortOptions: {
        field: column,
        order: !sort?.order
          ? 'asc'
          : sort?.order === 'asc' && sort.field === column
          ? 'desc'
          : null,
      },
    };

    setSelectedFilters(sorting);
  };

  const setPageArray = (count) => {
    const total = Math.ceil(count / top);
    return setPages([...Array(total).keys()].toString().split(','));
  };

  const changePage = (nextPage) => {
    setPage(nextPage - 1);
    loadVendors(top, (nextPage - 1) * top, selectedFilters);
  };

  const inputValidation = (input) => {
    if (input.value.length > input.maxLength)
      input.value = input.value.slice(0, input.maxLength);
  };

  const handleSearchClick = (e) => {
    setActiveFilters(e);
  };

  return (
    <div className={Styles.container}>
      <>
        <Table
          className={Styles.table}
          header={
            <Toolbar className={Styles.tableHeader}>
              <Toolbar.Item left="true">
                <Searchbar
                  className={Styles.filter}
                  onSubmit={(e) => handleSearchClick(e)}
                  inputProps={{
                    maxLength: 100,
                    minLength: filterType === 'block' ? 1 : 2,
                    type: 'text',
                    className: `em-u-no-border`,
                    onInput: (e) => inputValidation(e.target),
                    value: filter,
                    valid: false,
                    onChange: (e) => setFilter(e.target.value),
                    placeholder: 'Filter',
                  }}
                  filter={
                    <span style={{ display: 'inherit' }}>
                      <Select
                        className="em-u-margin-none"
                        onChange={(e) => {
                          setFilterTypeValue(e.target.value);
                        }}>
                        {Object.keys(filterTypes).map((item, index) => {
                          return (
                            <option
                              value={item}
                              key={`filter-${item}-${index}`}>
                              {filterTypes[item]}
                            </option>
                          );
                        })}
                      </Select>
                    </span>
                  }
                  tags={
                    <Tag.Group style={{ maxWidth: '360px' }}>
                      {Object.keys(selectedFilters).map((item, index) => {
                        const filter = selectedFilters[item];
                        return Array.isArray(filter)
                          ? filter.map((tag, tagIndex) => {
                              return (
                                tag && (
                                  <React.Fragment
                                    key={`active-filter-${item}-${tag}-${index}-${tagIndex}`}>
                                    <Tooltip color="light">
                                      <Tooltip.Passage>
                                        <Tag
                                          className={Styles.tags}
                                          onCloseAction={() =>
                                            unsetFilter(item, tag)
                                          }>
                                          {tag}
                                        </Tag>
                                        <Tooltip.Content>
                                          {filterTypes[item]}
                                        </Tooltip.Content>
                                      </Tooltip.Passage>
                                    </Tooltip>
                                  </React.Fragment>
                                )
                              );
                            })
                          : filter && typeof filter !== 'object' && (
                              <React.Fragment
                                key={`active-filter-${item}-${index}`}>
                                <Tooltip color="light">
                                  <Tooltip.Passage>
                                    <Tag
                                      className={Styles.tags}
                                      onCloseAction={() =>
                                        unsetFilter(item, filter)
                                      }>
                                      {filterTypes[item] === 'Payment Block'
                                        ? filter === 'true'
                                          ? 'Block Exist'
                                          : 'None'
                                        : filter === 'true'
                                        ? 'yes'
                                        : 'no'}
                                    </Tag>
                                  </Tooltip.Passage>
                                  <Tooltip.Content>
                                    {filterTypes[item]}
                                  </Tooltip.Content>
                                </Tooltip>
                              </React.Fragment>
                            );
                      })}
                    </Tag.Group>
                  }
                />
              </Toolbar.Item>
              <Toolbar.Item right></Toolbar.Item>
            </Toolbar>
          }
          footer={
            vendors.length ? (
              <Toolbar>
                <Toolbar.Item>
                  {`Showing ${
                    vendors.length * (page + 1) - vendors.length + 1
                  } - ${vendors.length * (page + 1)} of ${count} results`}
                </Toolbar.Item>
                <Toolbar.Item right>
                  <Pagination
                    className={Styles.pagination}
                    pages={pages}
                    onChange={(page) => changePage(page)}
                  />
                </Toolbar.Item>
              </Toolbar>
            ) : null
          }>
          {vendors.length ? (
            <>
              <Table.Head>
                <Table.Head.Row>
                  {Object.keys(headers).map((item, index) => {
                    return (
                      <Table.Head.Cell
                        onClick={() => onSort(item)}
                        key={`column-${item}-${index}`}
                        style={{ userSelect: 'none' }}>
                        <div className={Styles.sort}>
                          {headers[item]}
                          <div style={{ height: '100%' }}>
                            {selectedFilters.sortOptions?.field === item &&
                            selectedFilters.sortOptions?.order === 'asc' ? (
                              <CaretUpIcon />
                            ) : selectedFilters.sortOptions?.field === item &&
                              selectedFilters.sortOptions?.order === 'desc' ? (
                              <CaretDownIcon />
                            ) : (
                              <>
                                <CaretUpIcon /> <CaretDownIcon />
                              </>
                            )}
                          </div>
                        </div>
                      </Table.Head.Cell>
                    );
                  })}
                  <Table.Head.Cell>Payment Block</Table.Head.Cell>
                </Table.Head.Row>
              </Table.Head>
              <Table.Body>
                {vendors.map((vendor, index) => {
                  return (
                    <Table.Body.Row
                      className={Styles.rowPointer}
                      key={`vendor-${vendor.vendorNumber}-${index}`}
                      onClick={() =>
                        props.selectVendor(
                          vendor.vendorNumber,
                          vendor.vendorName
                        )
                      }>
                      <Table.Body.Cell style={{ maxWidth: '180px' }}>
                        {vendor.vendorNumber}
                        <div style={{ fontSize: '12px' }}>
                          {vendor.vendorName}
                        </div>
                      </Table.Body.Cell>
                      <Table.Body.Cell>{vendor.taxID}</Table.Body.Cell>
                      <Table.Body.Cell className={Styles.centerData}>
                        {vendor.countryCode}
                      </Table.Body.Cell>
                      <Table.Body.Cell className={Styles.centerData}>
                        {vendor.region}
                      </Table.Body.Cell>
                      <Table.Body.Cell>{vendor.city}</Table.Body.Cell>
                      <Table.Body.Cell>{vendor.street}</Table.Body.Cell>
                      <Table.Body.Cell>{vendor.zipCode}</Table.Body.Cell>
                      <Table.Body.Cell>
                        {vendor.paymentBlock ? (
                          <div
                            className={`${Styles.status} ${Styles.withBlock}`}>
                            {' '}
                            Blocks Exist
                          </div>
                        ) : (
                          <div className={`${Styles.status} ${Styles.noBlock}`}>
                            {' '}
                            None
                          </div>
                        )}
                      </Table.Body.Cell>
                    </Table.Body.Row>
                  );
                })}
              </Table.Body>
            </>
          ) : (
            <tbody className={Styles.notFound}>
              <tr>
                <td>No results found</td>
              </tr>
            </tbody>
          )}
        </Table>
      </>
      {loading && (
        <div className={Styles.loaderDiv}>
          <Loading isLoading={loading} />
        </div>
      )}
    </div>
  );
};

export default ManageVendorSearch;
