import React, { useState, useEffect } from 'react';
import { Table, Button, SearchIcon } from '@exxonmobil/react-unity';

import Style from './style.module.css';
import { useHistory } from 'react-router-dom';

const PaymentSummaryTable = (props) => {
  const [logs, setLogs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLogs(initialLogs(props.logs));
  }, [props.logs]);

  const initialLogs = (logs) => {
    return logs.reduce((acc, item) => {
      return [...acc, { ...item, visible: false }];
    }, []);
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return (
      date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    );
  };

  const redirectDetails = (id) => {
    history.push({
      pathname: '/payment-log-details',
      search: `?id=${id}`,
    });
  };

  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ? currencyCode : 'USD',
    });
  };

  return (
    <Table className={Style.table}>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Cell>File Template</Table.Head.Cell>
          <Table.Head.Cell>File ID</Table.Head.Cell>
          <Table.Head.Cell>Generation Date</Table.Head.Cell>
          <Table.Head.Cell>Generated By</Table.Head.Cell>
          <Table.Head.Cell>Invoice Count</Table.Head.Cell>
          <Table.Head.Cell>PMT count</Table.Head.Cell>
          <Table.Head.Cell>Total Amount ($)</Table.Head.Cell>
          <Table.Head.Cell>View Details</Table.Head.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body>
        {logs.map((log, index) => {
          return (
            <React.Fragment key={`log-${log.id}-${index}`}>
              <Table.Body.GroupedRow
                className={Style.log}
                cells={
                  <>
                    <Table.Body.Cell>{log.paymentTemplate}</Table.Body.Cell>
                    <Table.Body.Cell>{log.id}</Table.Body.Cell>
                    <Table.Body.Cell>
                      {dateFormat(log.generatedDate)}
                    </Table.Body.Cell>
                    <Table.Body.Cell>{log.generatedBy}</Table.Body.Cell>
                    <Table.Body.Cell>{log.invoiceCount}</Table.Body.Cell>
                    <Table.Body.Cell>{log.paymentCount}</Table.Body.Cell>
                    <Table.Body.Cell>
                      {log.totalAmounts.map((item) => (
                        <p key={item.currencyCode}>
                          {item.currencyCode}
                          {formatter('USD').format(item.totalAmount)}
                        </p>
                      ))}
                    </Table.Body.Cell>
                    <Table.Body.Cell style={{ padding: '1.2rem' }}>
                      <Button
                        className="small-margin"
                        variant="primary"
                        onClick={() => redirectDetails(log.id)}
                        style={{ width: '10px', height: '10px' }}>
                        <SearchIcon size="small" style={{ width: '10px' }} />
                      </Button>
                    </Table.Body.Cell>
                  </>
                }
              />
            </React.Fragment>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default PaymentSummaryTable;
