import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { Controller } from 'react-hook-form';

import Style from './style.module.css';

const Autocomplete = (props) => {
  let debounceTimer = null;
  const minLength = props.minLength ? props.minLength : 0;

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>{props.data.code}</components.MultiValue>
  );

  return (
    <div className={props.className}>
      {props.label &&
      <div className={Style.label}>
        {props.label}
        <div className={Style.optional}>{props.optional && '(Optional)'}</div>
      </div>
      }
      <Controller
        as={
          <AsyncSelect
            isMulti={props.isMulti}
            loadOptions={(value) => {
              if (debounceTimer) clearTimeout(debounceTimer);
              if (value.length >= minLength)
                return new Promise((resolve, reject) => {
                  debounceTimer = setTimeout(
                    () => {
                      resolve(props.loadOptions(value));
                    },
                    props.loadWait ? props.loadWait : 0
                  );
                });
            }}
            getOptionValue={(option) =>
              props.code ? option[props.code] : option.code
            }
            getOptionLabel={(option) =>
              props.code && props.desc
                ? `${option[props.code]} - ${option[props.desc]}`
                : `${option.code} - ${option.name}`
            }
            onInputChange={handleInputChange}
            placeholder={props.placeholder}
            isClearable={props.isClearable}
            backspaceRemoves={true}
            deleteRemoves={true}
            loadingMessage={(value) => {
              return value.inputValue.length >= minLength
                ? 'Loading ...'
                : `Enter at least ${minLength} characters`;
            }}
            noOptionsMessage={(value) => {
              return value.inputValue.length >= minLength
                ? 'No options'
                : 'Start typing to search';
            }}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c69b0',
              },
            })}
            components={{ MultiValue }}
          />
        }
        name={props.name}
        register={props.register}
        control={props.control}
        onChange={([selected]) => {
          if (props.setSelected) props.setSelected(selected);
          return selected;
        }}
        defaultValue=""
        menuPortalTarget={document.body}
      />
      {props.error && (
        <div className={Style.errorText}>{props.error.message}</div>
      )}
    </div>
  );
};

export default Autocomplete;
