export const authConfig = {
  auth: {
    authority: window['runConfig'].aadAuthority,
    clientId: window['runConfig'].aadClientId,
    redirectUri: window['runConfig'].appURL, // Commented out to force users using Azure URL be redirect to DNS. window.location.protocol + '//' + window.location.host,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const authParameters = {
  scopes: ['openid', 'email', 'profile'],
};
