import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  TextArea,
  User1Icon,
  TrashcanEmptyIcon,
  ExclamationPointIcon,
} from '@exxonmobil/react-unity';

import Styles from './style.module.css';

const CommentList = (props) => {
  const user = useSelector((state) => state.auth);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState('');
  const [length, setLength] = useState(props.maxLength || null);

  useEffect(() => {
    setCommentList(props.comments);
  }, [props.comments]);

  const handleChange = (event) => {
    if (event.target.value.length > props.maxLength) {
      return;
    }

    setLength(props.maxLength - event.target.value.length);
    setComment(event.target.value);
  };

  const onSubmit = async () => {
    props.onSubmit(comment).then((response) => {
      setComment('');
      setCommentList([...commentList, response.data]);
      setLength(props.maxLength);
    });
  };

  const removeComment = (comment) => {
    props.deleteComments(comment.id).then((response) => {
      const updatedComments = commentList.filter(
        (item) => comment.id !== item.id
      );
      setCommentList(updatedComments);
    });
  };

  return (
    <div>
      {commentList &&
        commentList.map((comment, index) => {
          return (
            <div className={Styles.list} key={`comment-${index}`}>
              <div className={Styles.container}>
                <div className={Styles.alignInLine}>
                  <div className={Styles.icon}>
                    {comment.createdBy !== 'System' ? (
                      <User1Icon size="large" />
                    ) : (
                      <ExclamationPointIcon size="large" />
                    )}
                  </div>
                  <div className={Styles.description}>
                    <div className={Styles.personName}>
                      {' '}
                      {comment.createdBy}
                    </div>
                    <div>
                      {' '}
                      {new Date(comment.createdDate+"Z").toLocaleDateString(
                        'en-US',
                        {
                          // timeZone: 'UTC',
                          hour: '2-digit',
                          minute: '2-digit',
                        }
                      )}
                    </div>
                    <div className={Styles.comment}> {comment.commentText}</div>
                  </div>
                  {(comment.createdBy === user.accountInfo.account.userName ||
                    comment.createdBy === user.accountInfo.account.name) && (
                    <div className={Styles.deleteButton}>
                      {!props.readOnly &&                        
                        <Button onClick={() => removeComment(comment)}>
                          <TrashcanEmptyIcon />
                        </Button>
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      {props.readOnly || (
        <div>
          <TextArea
            label="Comment"
            placeholder="Type a comment..."
            id="add-comment-invoice"
            className={Styles.textArea}
            value={comment}
            onChange={(e) => handleChange(e)}
          />
          <div className={Styles.footer}>
            <div>{length} characters left</div>
            <Button variant="primary" onClick={() => onSubmit()}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentList;
