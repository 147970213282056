import React, { useEffect, useState } from 'react';
import { Select, Loader } from '@exxonmobil/react-unity';
import { Controller } from 'react-hook-form';

import Style from './style.module.css';

const SelectGroup = (props) => {
  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);
  const [value, setValue] = useState();
  return (
    <Controller
      as={
        <div style={props.divStyle}>
          {props.label && (
            <div className={Style.label} style={props.labelStyle}>
              {props.label}
              <div className={Style.optional}>
                {props.optional && ' (Optional)'}
              </div>
            </div>
          )}
          <Select
            className={`${Style.container} ${
              props.isLoading ? Style.loading : ''
            }`}
            disabled={props.disabled || props.isLoading}
            error={!!props.error}
            value={value}
            id={`select-${props.label}`}
            note={props.error && props.error.message}>
            {!props.noDefault && (
              <option value="">
                {props.isLoading
                  ? 'Loading...'
                  : !props.disabled
                  ? 'Please select'
                  : props.disabledPlaceholder
                  ? props.disabledPlaceholder
                  : ''}
              </option>
            )}
            {props.options &&
              props.options.map((item, index) => {
                return (
                  <option
                    key={`option-${item.id}-${index}`}
                    value={
                      !props.useDesc ? item.id : item[props.option] || item
                    }>
                    {item[props.option] || item}
                  </option>
                );
              })}
          </Select>
          <div
            style={{
              width: '0px',
              height: '0px',
              float: 'right',
              position: 'relative',
              top: '-40px',
              right: '30px',
            }}>
            <div
              style={{
                width: '20px',
                position: 'absolute',
              }}>
              {props.isLoading && <Loader />}
            </div>
          </div>
        </div>
      }
      name={props.name}
      control={props.control}
      register={{ ...props.register }}
      placeholder={props.placeholder ? props.placeholder : 'Please select'}
      onChange={([element]) => {
        if (props.onChange) {
          props.onChange(element);
        }
        setValue(element.target.value);
        return element.target.value;
      }}
    />
  );
};

export default SelectGroup;
