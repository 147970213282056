import { Loading } from 'Components/Loading';
import { ManageVendorsTable } from 'Partials/ManageVendorsTable';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from 'Services/paymentFileService';
import Styles from './style.module.css';

const VendorsManagement = (props) => {
  const filterTypes = {
    system: 'SAP System',
    vendorName: 'Vendor Name',
    vendorNumber: 'Vendor Number',
    country: 'Country',
    region: 'Region',
    city: 'City',
    street: 'Street Address',
    zipCode: 'ZIP Code',
    taxID: 'Tax ID',
    bankAccount: 'Bank Account',
    iban: 'IBAN',
    critical: 'Critical',
    postingBlock: 'Posting Block',
    hasPaymentBlocks: 'Payment Block',
    needsIV: 'IV',
  };
  const headers = {
    system: 'SAP',
    vendorName: 'Vendor',
    taxId: 'Tax ID',
    country: 'Country',
    region: 'Region',
    city: 'City',
    street: 'Street Address',
    zip: 'ZIP Code',
    postingBlock: 'Post Block',
  };
  const top = 10;
  const [vendors, setVendors] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterType, setFilterType] = useState('system');
  const [selectedFilters, setSelectedFilters] = useState({});

  const [paymentBlocks] = useState([
    { id: false, name: 'None' },
    { id: true, name: 'Blocks exist' },
  ]);

  const [yesOrNo] = useState([
    { id: false, name: 'No' },
    { id: true, name: 'Yes' },
  ]);

  const [systemsLoading, setSystemsLoading] = useState(false);
  const [systems, setSystems] = useState([]);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const filtersMount = useRef(true);

  const history = useHistory();

  useEffect(() => {
    setVendors(props.vendors);
    getSystems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vendors]);

  useEffect(() => {
    setPageArray(props.count);
  }, [props.count]);

  useLayoutEffect(() => {
    if (filtersMount.current) {
      filtersMount.current = false;
      return;
    }
    props.loadVendors(top, page, selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const getSystems = () => {
    setSystemsLoading(true);
    Api.getSapSystems().then((resp) => {
      resp && setSystems(resp.data);
      setSystemsLoading(false);
    });
  };

  const setActiveFilters = (e) => {
    let filters = {};
    if (
      filterType.includes('critical') ||
      filterType.includes('postingBlock') ||
      filterType.includes('needsIV') ||
      filterType.includes('hasPaymentBlocks')
    ) {
      filters = {
        ...selectedFilters,
        [filterType]: filter,
      };
    } else {
      filters = {
        ...selectedFilters,
        [filterType]: [
          ...(selectedFilters[filterType] ? selectedFilters[filterType] : []),
          filter,
        ],
      };
    }
    setSelectedFilters(filters);
    setFilter('');
    e.preventDefault();
  };

  const setFilterTypeValue = (value) => {
    setFilter('');
    setFilterType(value);
  };

  const unsetFilter = (type, filter) => {
    const value = selectedFilters[type];

    setSelectedFilters({
      ...selectedFilters,
      [type]: Array.isArray(value)
        ? value.filter((item) => item !== filter)
        : '',
    });
  };

  const onSort = (column) => {
    const sort = selectedFilters.sortOptions;
    const sorting = {
      ...selectedFilters,
      sortOptions: {
        field: column,
        order: !sort?.order
          ? 'asc'
          : sort?.order === 'asc' && sort.field === column
          ? 'desc'
          : null,
      },
    };

    setSelectedFilters(sorting);
  };

  const setPageArray = (count) => {
    const total = Math.ceil(count / top);
    return setPages([...Array(total).keys()].toString().split(','));
  };

  const changePage = (nextPage) => {
    setPage(nextPage - 1);
    props.loadVendors(top, (nextPage - 1) * top, selectedFilters);
  };

  const inputValidation = (input) => {
    if (input.value.length > input.maxLength)
      input.value = input.value.slice(0, input.maxLength);
  };

  const editVendor = (vendor) => {
    const { vendorNumber, systemCode } = vendor;
    history.push({
      pathname: `/vendors/${systemCode}/${vendorNumber}`,
      state: { vendor },
    });
  };

  const newVendor = () => {
    history.push({
      pathname: `/vendors/new`,
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h1 className={Styles.title}>Vendor List</h1>
      </div>
      <ManageVendorsTable
        filterType={filterType}
        filterTypes={filterTypes}
        filter={filter}
        selectedFilters={selectedFilters}
        headers={headers}
        page={page}
        pages={pages}
        vendors={vendors}
        count={props.count}
        setActiveFilters={setActiveFilters}
        inputValidation={inputValidation}
        setFilter={setFilter}
        setFilterTypeValue={setFilterTypeValue}
        unsetFilter={unsetFilter}
        changePage={changePage}
        onSort={onSort}
        systems={systems}
        systemsLoading={systemsLoading}
        paymentBlocks={paymentBlocks}
        yesOrNo={yesOrNo}
        editVendor={editVendor}
        newVendor={newVendor}
      />
      <Loading isLoading={props.loading} />
    </div>
  );
};

export default VendorsManagement;
