import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  CaretDownIcon,
  CaretUpIcon,
  CirclePlusIcon,
  WarningIcon,
  VisibilityOnIcon,
  Pagination,
  PencilIcon,
  Searchbar,
  Select,
  Table,
  Tag,
  Toolbar,
  Tooltip,
} from '@exxonmobil/react-unity';
import { SelectGroup } from 'Components/SelectGroup';
import Styles from './style.module.css';

const ManageVendorsTable = (props) => {
  const {
    filterType,
    filterTypes,
    filter,
    selectedFilters,
    headers,
    page,
    pages,
    vendors,
    setActiveFilters,
    inputValidation,
    setFilter,
    setFilterTypeValue,
    unsetFilter,
    changePage,
    onSort,
    count,
  } = props;

  const { register, control } = useForm();

  const setInputType = () => {
    switch (filterType) {
      case 'hasPaymentBlocks':
      case 'critical':
      case 'postingBlock':
      case 'needsIV':
      case 'system':
        return 'hidden';
      default:
        return 'text';
    }
  };

  const handleSearchClick = (e) => {
    setActiveFilters(e);
  };

  return (
    <>
      <Table
        className={Styles.table}
        header={
          <Toolbar className={Styles.tableHeader}>
            <Toolbar.Item left="true">
              <Searchbar
                className={Styles.filter}
                onSubmit={(e) => handleSearchClick(e)}
                inputProps={{
                  maxLength: 100,
                  minLength: filterType === 'block' ? 1 : 2,
                  type: setInputType(),
                  className: `em-u-no-border`,
                  onInput: (e) => inputValidation(e.target),
                  value: filter,
                  valid: false,
                  onChange: (e) => setFilter(e.target.value),
                  placeholder: 'Filter',
                }}
                filter={
                  <span style={{ display: 'inherit' }}>
                    <Select
                      className="em-u-margin-none"
                      onChange={(e) => {
                        setFilterTypeValue(e.target.value);
                      }}>
                      {Object.keys(filterTypes).map((item, index) => {
                        return (
                          <option value={item} key={`filter-${item}-${index}`}>
                            {filterTypes[item]}
                          </option>
                        );
                      })}
                    </Select>
                    {filterType === 'system' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={true}
                        labelStyle={{ marginBottom: '0px' }}
                        name="system"
                        control={control}
                        register={register}
                        options={props.systems}
                        option="systemCode"
                        isLoading={props.systemsLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType === 'hasPaymentBlocks' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={false}
                        labelStyle={{ marginBottom: '0px' }}
                        name="hasPaymentBlocks"
                        control={control}
                        register={register}
                        options={props.paymentBlocks}
                        option="name"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {(filterType === 'critical' ||
                      filterType === 'needsIV' ||
                      filterType === 'postingBlock') && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={false}
                        labelStyle={{ marginBottom: '0px' }}
                        name={filterType}
                        control={control}
                        register={register}
                        options={props.yesOrNo}
                        option="name"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                  </span>
                }
                tags={
                  <Tag.Group style={{ maxWidth: '360px' }}>
                    {Object.keys(selectedFilters).map((item, index) => {
                      const filter = selectedFilters[item];
                      return Array.isArray(filter)
                        ? filter.map((tag, tagIndex) => {
                            return (
                              tag && (
                                <React.Fragment
                                  key={`active-filter-${item}-${tag}-${index}-${tagIndex}`}>
                                  <Tooltip color="light">
                                    <Tooltip.Passage>
                                      <Tag
                                        className={Styles.tags}
                                        onCloseAction={() =>
                                          unsetFilter(item, tag)
                                        }>
                                        {tag}
                                      </Tag>
                                      <Tooltip.Content>
                                        {filterTypes[item]}
                                      </Tooltip.Content>
                                    </Tooltip.Passage>
                                  </Tooltip>
                                </React.Fragment>
                              )
                            );
                          })
                        : filter && typeof filter !== 'object' && (
                            <React.Fragment
                              key={`active-filter-${item}-${index}`}>
                              <Tooltip color="light">
                                <Tooltip.Passage>
                                  <Tag
                                    className={Styles.tags}
                                    onCloseAction={() =>
                                      unsetFilter(item, filter)
                                    }>
                                    {filterTypes[item] === 'Payment Block'
                                      ? filter === 'true'
                                        ? 'Block Exist'
                                        : 'None'
                                      : filter === 'true'
                                      ? 'yes'
                                      : 'no'}
                                  </Tag>
                                </Tooltip.Passage>
                                <Tooltip.Content>
                                  {filterTypes[item]}
                                </Tooltip.Content>
                              </Tooltip>
                            </React.Fragment>
                          );
                    })}
                  </Tag.Group>
                }
              />
            </Toolbar.Item>
            <Toolbar.Item right>
              <Button
                className={Styles.button}
                variant="primary"
                onClick={() => props.newVendor()}>
                <CirclePlusIcon size="small" style={{ marginRight: '10px' }} />
                New Vendor
              </Button>
            </Toolbar.Item>
          </Toolbar>
        }
        footer={
          vendors.length ? (
            <Toolbar>
              <Toolbar.Item>
                {`Showing ${
                  vendors.length * (page + 1) - vendors.length + 1
                } - ${vendors.length * (page + 1)} of ${count} results`}
              </Toolbar.Item>
              <Toolbar.Item right>
                <Pagination
                  className={Styles.pagination}
                  pages={pages}
                  onChange={(page) => changePage(page)}
                />
              </Toolbar.Item>
            </Toolbar>
          ) : null
        }>
        {vendors.length ? (
          <>
            <Table.Head>
              <Table.Head.Row>
                <Table.Head.Cell>Crit</Table.Head.Cell>
                {Object.keys(headers).map((item, index) => {
                  return (
                    <Table.Head.Cell
                      onClick={() => onSort(item)}
                      key={`column-${item}-${index}`}
                      style={{ userSelect: 'none' }}>
                      <div className={Styles.sort}>
                        {headers[item]}
                        <div style={{ height: '100%' }}>
                          {selectedFilters.sortOptions?.field === item &&
                          selectedFilters.sortOptions?.order === 'asc' ? (
                            <CaretUpIcon />
                          ) : selectedFilters.sortOptions?.field === item &&
                            selectedFilters.sortOptions?.order === 'desc' ? (
                            <CaretDownIcon />
                          ) : (
                            <>
                              <CaretUpIcon /> <CaretDownIcon />
                            </>
                          )}
                        </div>
                      </div>
                    </Table.Head.Cell>
                  );
                })}
                <Table.Head.Cell>PMT Block</Table.Head.Cell>
                <Table.Head.Cell>Needs IV</Table.Head.Cell>
                <Table.Head.Cell>Edit</Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              {vendors.map((vendor, index) => {
                return (
                  <Table.Body.Row
                    key={`vendor-${vendor.vendorNumber}-${index}`}>
                    <Table.Body.Cell className={Styles.centerIcon}>
                      {vendor.critical && <WarningIcon />}
                    </Table.Body.Cell>
                    <Table.Body.Cell className={Styles.centerIcon}>
                      {vendor.systemCode}
                    </Table.Body.Cell>
                    <Table.Body.Cell style={{ maxWidth: '180px' }}>
                      {vendor.vendorNumber}
                      <div style={{ fontSize: '12px' }}>
                        {vendor.vendorName}
                      </div>
                    </Table.Body.Cell>
                    <Table.Body.Cell>{vendor.taxID}</Table.Body.Cell>
                    <Table.Body.Cell className={Styles.centerData}>
                      {vendor.countryCode}
                    </Table.Body.Cell>
                    <Table.Body.Cell className={Styles.centerData}>
                      {vendor.region}
                    </Table.Body.Cell>
                    <Table.Body.Cell>{vendor.city}</Table.Body.Cell>
                    <Table.Body.Cell>{vendor.street}</Table.Body.Cell>
                    <Table.Body.Cell>{vendor.zipCode}</Table.Body.Cell>
                    <Table.Body.Cell className={Styles.centerData}>
                      {vendor.postingBlock ? 'Yes' : 'No'}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      {vendor.hasPaymentBlocks ? (
                        <div className={`${Styles.status} ${Styles.withBlock}`}>
                          {' '}
                          Blocks Exist
                        </div>
                      ) : (
                        <div className={`${Styles.status} ${Styles.noBlock}`}>
                          {' '}
                          None
                        </div>
                      )}
                    </Table.Body.Cell>
                    <Table.Body.Cell className={Styles.centerIcon}>
                      {vendor.needsIV && <VisibilityOnIcon />}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      <Button
                        onClick={() => props.editVendor(vendor)}
                        variant="primary"
                        size="small"
                        style={{
                          height: '30px',
                        }}>
                        <PencilIcon size="small" />
                      </Button>
                    </Table.Body.Cell>
                  </Table.Body.Row>
                );
              })}
            </Table.Body>
          </>
        ) : (
          <tbody className={Styles.notFound}>
            <tr>
              <td>No results found</td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default ManageVendorsTable;
