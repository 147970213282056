import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Pagination,
  Searchbar,
  Select,
  Table,
  Tag,
  Toolbar,
  Tooltip,
  SearchIcon,
  TextLink,
} from '@exxonmobil/react-unity';
import { SelectGroup } from 'Components/SelectGroup';
import { DatePickerGroup } from 'Components/DatePickerGroup';
import { Loading } from 'Components/Loading';
import moment from 'moment';
import Styles from './style.module.css';

const ChangeLogTable = (props) => {
  const changeSets = props.changeSets;
  const top = 10;
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const filtersMount = useRef(true);
  const [filter, setFilter] = useState('');
  const [filterStart, setFilterStart] = useState('');
  const [filterEnd, setFilterEnd] = useState('');
  const [filterType, setFilterType] = useState('documentNumber');
  const [selectedFilters, setSelectedFilters] = useState({});
  const [error, setError] = useState();

  let filterTypes = {};
  let headers = {};

  if (props.entity === 'invoice') {
    filterTypes = {
      documentNumber: 'Document',
      system: 'System',
      fiscalYear: 'Fiscal Year',
      changedDateRange: 'Change Date',
      changedBy: 'changed By',
    };
    headers = {
      changeId: 'Change ID',
      system: 'System',
      docNo: 'Document',
      fiscalYear: 'Fiscal Year',
      changedBy: 'Changed By',
      changedDate: 'Changed Date',
      comments: 'Comments',
    };
  } else if (props.entity === 'vendor') {
    filterTypes = {
      system: 'System',
      vendorName: 'Vendor Name',
      vendorNumber: 'Vendor Number',
      changedBy: 'Changed By',
    };
    headers = {
      changeId: 'Change ID',
      system: 'System',
      vendorName: 'Vendor',
      changedBy: 'Changed By',
      changedDate: 'Changed Date',
      comments: 'Comments',
    };
  }

  const { register, control, reset } = useForm();
  const history = useHistory();

  useEffect(() => {
    setPageArray(props.count);
  }, [props.count]);

  useEffect(() => {
    if (filterType.includes('Date')) {
      if (!validateDate(filterStart) && filterStart !== '') {
        setError({
          ...error,
          start:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
      } else {
        setError({ ...error, start: null });
      }
      if (!validateDate(filterEnd) && filterEnd !== '') {
        setError({
          ...error,
          end:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
      } else {
        setError({ ...error, end: null });
      }
    }
    // eslint-disable-next-line
  }, [filterType, filterStart, filterEnd]);

  useLayoutEffect(() => {
    if (filtersMount.current) {
      filtersMount.current = false;
      return;
    }
    props.loadChangeSets(props.entity, top, page, selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    if (props.entity === 'vendor') {
      setFilterType('system');
    }
  }, [props.entity]);

  const setFilterTypeValue = (value) => {
    setFilter('');
    setFilterStart('');
    setFilterEnd('');
    setFilterType(value);
  };

  const unsetFilter = (type, filter) => {
    const value = selectedFilters[type];

    if (type.includes('Start') || type.includes('End')) {
      let baseType = '';

      if (type.includes('Start')) {
        baseType = type.replace('Start', '');
      }
      if (type.includes('End')) {
        baseType = type.replace('End', '');
      }

      setSelectedFilters({
        ...selectedFilters,
        [`${baseType}Start`]: Array.isArray(value)
          ? value.filter((item) => item !== filter)
          : '',
        [`${baseType}End`]: Array.isArray(value)
          ? value.filter((item) => item !== filter)
          : '',
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [type]: Array.isArray(value)
          ? value.filter((item) => item !== filter)
          : '',
      });
    }
  };

  const setActiveFilters = (e) => {
    let filters = {};
    if (filterType.includes('Range')) {
      const filterName = filterType.split('Range')[0];
      filters = {
        ...selectedFilters,
        [`${filterName}Start`]: filterType.includes('Date')
          ? new Date(filterStart).toISOString()
          : filterType.includes('amount')
          ? Number(filterStart.replace(/[^0-9.-]+/g, ''))
          : [
              ...(selectedFilters[filterType]
                ? selectedFilters[filterType]
                : []),
              filterStart,
            ],
        [`${filterName}End`]: filterType.includes('Date')
          ? new Date(filterEnd).toISOString()
          : filterType.includes('amount')
          ? Number(filterEnd.replace(/[^0-9.-]+/g, ''))
          : [
              ...(selectedFilters[filterType]
                ? selectedFilters[filterType]
                : []),
              filterEnd,
            ],
      };
    } else {
      filters = {
        ...selectedFilters,
        [filterType]: filterType.includes('Date')
          ? new Date(filter).toISOString()
          : filterType.includes('amount')
          ? Number(filter.replace(/[^0-9.-]+/g, ''))
          : [
              ...(selectedFilters[filterType]
                ? selectedFilters[filterType]
                : []),
              filter,
            ],
      };
    }

    setSelectedFilters(filters);
    setFilter('');
    setFilterStart('');
    setFilterEnd('');
    e.preventDefault();
  };

  const setPageArray = (count) => {
    const total = Math.ceil(count / top);
    return setPages([...Array(total).keys()].toString().split(','));
  };

  const changePage = (nextPage) => {
    setPage(nextPage - 1);
    props.loadChangeSets(
      props.entity,
      top,
      (nextPage - 1) * top,
      selectedFilters
    );
  };

  const setInputType = () => {
    switch (filterType) {
      case 'system':
        return 'hidden';
      default:
        return filterType.includes('Range') ? 'hidden' : 'text';
    }
  };

  const inputValidation = (input) => {
    if (input.value.length > input.maxLength)
      input.value = input.value.slice(0, input.maxLength);
  };

  const goToChangeLog = (changeLog) => {
    const { id } = changeLog.changeSet;
    history.push({
      pathname: `/changelog/${id}`,
      state: { changeLog },
    });
  };

  const validateDate = (date) => {
    return moment(date, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
  };

  const handleSearchClick = (e) => {
    if (filterType.includes('DateRange') || filterType === 'amountRange') {
      if (filterStart === '') {
        setError({ start: 'Required' });
        e.preventDefault();
      } else if (
        !moment(filterStart, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid()
      ) {
        setError({
          start:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
        e.preventDefault();
      } else if (
        !moment(filterEnd, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid()
      ) {
        setError({
          end:
            'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
        });
        e.preventDefault();
      } else if (
        moment(filterStart, ['MM/DD/YYYY', 'M/D/YYYY'], true) >
        moment(filterEnd, ['MM/DD/YYYY', 'M/D/YYYY'], true)
      ) {
        setError({ end: 'End value cannot be smaller than start value' });
        e.preventDefault();
      } else {
        setActiveFilters(e);
        setError(null);
        reset({
          startDate: '',
          endDate: '',
        });
      }
    } else {
      setActiveFilters(e);
      setError();
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h1 className={Styles.title}>Changesets</h1>
      </div>
      <Table
        className={Styles.table}
        header={
          <Toolbar className={Styles.tableHeader}>
            <Toolbar.Item left="true">
              <Searchbar
                className={Styles.filter}
                onSubmit={(e) => handleSearchClick(e)}
                inputProps={{
                  maxLength: 100,
                  minLength: 3,
                  type: setInputType(),
                  className: `em-u-no-border ${
                    filterType.includes('Date') && Styles.date
                  }`,
                  onInput: (e) => inputValidation(e.target),
                  value: filter,
                  valid: false,
                  onChange: (e) => setFilter(e.target.value),
                  placeholder: 'Filter',
                }}
                filter={
                  <span style={{ display: 'inherit' }}>
                    <Select
                      className="em-u-margin-none"
                      onChange={(e) => setFilterTypeValue(e.target.value)}>
                      {Object.keys(filterTypes).map((item, index) => {
                        return (
                          <option value={item} key={`filter-${item}-${index}`}>
                            {filterTypes[item]}
                          </option>
                        );
                      })}
                    </Select>
                    {filterType === 'system' && (
                      <SelectGroup
                        divStyle={{ width: '300px' }}
                        useDesc={true}
                        labelStyle={{ marginBottom: '0px' }}
                        name="system"
                        control={control}
                        register={register}
                        options={props.systems}
                        option="systemCode"
                        isLoading={props.systemsLoading}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    )}
                    {filterType.includes('DateRange') && (
                      <>
                        <div className={Styles.rangeFilter}>
                          <DatePickerGroup
                            id="startDate"
                            control={control}
                            register={register}
                            name="startDate"
                            onChange={(e) => setFilterStart(e.target.value)}
                            placeholder="Start Date"
                            noLabel
                            //{errors.startDate?.required}
                            error={error?.start}
                          />
                          <DatePickerGroup
                            id="endDate"
                            control={control}
                            register={register}
                            name="endDate"
                            onChange={(e) => setFilterEnd(e.target.value)}
                            placeholder="End Date"
                            noLabel
                            error={error?.end}
                          />
                        </div>
                      </>
                    )}
                  </span>
                }
                tags={
                  <Tag.Group style={{ maxWidth: '360px' }}>
                    {Object.keys(selectedFilters).map((item, index) => {
                      const filter = selectedFilters[item];
                      return Array.isArray(filter)
                        ? filter.map((tag, tagIndex) => {
                            return (
                              tag && (
                                <React.Fragment
                                  key={`active-filter-${item}-${tag}-${index}-${tagIndex}`}>
                                  <Tooltip color="light">
                                    <Tooltip.Passage>
                                      <Tag
                                        className={Styles.tags}
                                        onCloseAction={() =>
                                          unsetFilter(item, tag)
                                        }>
                                        {tag}
                                      </Tag>
                                      <Tooltip.Content>
                                        {filterTypes[item]}
                                      </Tooltip.Content>
                                    </Tooltip.Passage>
                                  </Tooltip>
                                </React.Fragment>
                              )
                            );
                          })
                        : filter && typeof filter !== 'object' && (
                            <React.Fragment
                              key={`active-filter-${item}-${index}`}>
                              <Tooltip color="light">
                                <Tooltip.Passage>
                                  <Tag
                                    className={Styles.tags}
                                    onCloseAction={() =>
                                      unsetFilter(item, filter)
                                    }>
                                    {new Date(filter).toLocaleDateString(
                                      'en-US',
                                      {
                                        timeZone: 'UTC',
                                      }
                                    )}
                                  </Tag>
                                  <Tooltip.Content>
                                    {item.includes('Start')
                                      ? filterTypes[
                                          item.replace('Start', 'Range')
                                        ]
                                      : item.includes('End')
                                      ? filterTypes[
                                          item.replace('End', 'Range')
                                        ]
                                      : filterTypes[item]}
                                  </Tooltip.Content>
                                </Tooltip.Passage>
                              </Tooltip>
                            </React.Fragment>
                          );
                    })}
                  </Tag.Group>
                }
              />
            </Toolbar.Item>
            <Toolbar.Item right>
              {' '}
              {props.invoiceId && (
                <TextLink href={`/invoices/${props.invoiceId}`}>
                  View Invoice
                </TextLink>
              )}
            </Toolbar.Item>
          </Toolbar>
        }
        footer={
          changeSets.length ? (
            <Toolbar>
              <Toolbar.Item>
                {`Showing ${
                  changeSets.length * (page + 1) - changeSets.length + 1
                } - ${changeSets.length * (page + 1)} of ${
                  props.count
                } results`}
              </Toolbar.Item>
              <Toolbar.Item right>
                <Pagination
                  className={Styles.pagination}
                  pages={pages}
                  onChange={(page) => changePage(page)}
                />
              </Toolbar.Item>
            </Toolbar>
          ) : null
        }>
        {changeSets.length ? (
          <>
            <Table.Head>
              <Table.Head.Row>
                {Object.keys(headers).map((item, index) => {
                  return (
                    <Table.Head.Cell
                      key={`column-${item}-${index}`}
                      style={{ userSelect: 'none' }}>
                      <div>{headers[item]}</div>
                    </Table.Head.Cell>
                  );
                })}
                <Table.Head.Cell>Details</Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              {changeSets.map((item, index) => {
                return (
                  <Table.Body.Row
                    key={`changeset-${item.changeSet.id}-${index}`}>
                    <Table.Body.Cell>{item.changeSet.id}</Table.Body.Cell>
                    <Table.Body.Cell>
                      {item.entityObject.systemCode || '-'}
                    </Table.Body.Cell>
                    {props.entity === 'invoice' && (
                      <>
                        <Table.Body.Cell>
                          {item.entityObject.documentNumber || '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {item.entityObject.fiscalYear || '-'}
                        </Table.Body.Cell>
                      </>
                    )}
                    {props.entity === 'vendor' && (
                      <Table.Body.Cell style={{ maxWidth: '180px' }}>
                        {item.entityObject.vendorNumber}
                        <div style={{ fontSize: '12px' }}>
                          {item.entityObject.vendorName}
                        </div>
                      </Table.Body.Cell>
                    )}
                    <Table.Body.Cell>
                      {item.changeSet.changedBy || '-'}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      {new Date(item.changeSet.changedDate).toLocaleString()}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      {item.changeSet.changeReason || '-'}
                    </Table.Body.Cell>
                    <Table.Body.Cell>
                      {item.changeSet.action === 'Modified' && (
                        <Button
                          onClick={() => goToChangeLog(item)}
                          variant="primary"
                          size="small"
                          style={{
                            height: '30px',
                          }}>
                          <SearchIcon size="small" />
                        </Button>
                      )}
                    </Table.Body.Cell>
                  </Table.Body.Row>
                );
              })}
            </Table.Body>
          </>
        ) : (
          <tbody className={Styles.notFound}>
            <tr>
              <td>No results found</td>
            </tr>
          </tbody>
        )}
      </Table>
      <Loading isLoading={props.loading} />
    </div>
  );
};

export default ChangeLogTable;
