import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table,
  BulletedList,
  ChevronRightIcon,
  ChevronDownIcon,
} from '@exxonmobil/react-unity';
import { setPaymentInvoices } from 'Store/Reducers/preDisbursementSlice';

import Style from './style.module.css';

const InvoicesTable = (props) => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState({});
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    setVendors(initialVendors(props.vendors));
    setInvoices(initialInvoices(props.vendors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSelectedInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, vendors]);

  const setSelectedInvoices = () => {
    const invoicesList = Object.keys(invoices).reduce((acc, item) => {
      const selected = invoices[item].filter((item) => {
        return item.selected;
      });
      return [...acc, ...selected];
    }, []);

    dispatch(setPaymentInvoices({ index: props.index, value: invoicesList }));
  };

  const initialVendors = (vendors) => {
    return vendors.reduce((acc, item) => {
      return [...acc, { ...item, visible: true, selected: isCreditMemo(item) }];
    }, []);
  };

  const isCreditMemo = (vendor) => {
    let amount = vendor.invoices.reduce((acc, invoice) => {
      return acc + parseFloat(invoice.documentAmount);
    }, 0);
    if (props.onCreditMemoCheck) props.onCreditMemoCheck(amount <= 0);
    return amount <= 0;
  };

  const setVisibility = (index) => {
    const vendor = vendors[index];
    vendor.visible = !vendor.visible;
    setInvoices({ ...invoices, [index]: invoices[index] });
  };

  const initialInvoices = (vendors) => {
    return vendors.reduce((acc, vItem, index) => {
      const invoices = vItem.invoices.reduce((acc, item) => {
        return [...acc, { ...item, selected: isCreditMemo(vItem) }];
      }, []);
      return { ...acc, [index]: [...invoices] };
    }, {});
  };

  const isPositive = (totalAmountList) => {
    let positive = false;
    totalAmountList.map((item) => {
      if (item.amount > 0) {
        positive = true;
      }
      return item;
    });
    return positive;
  };

  const selectInvoices = (vendorIndex, invoiceIndex) => {
    const invoicesCopy = vendors.map((item, vendorIdx) => {
      return invoices[vendorIdx].reduce((acc, item, index) => {
        return [
          ...acc,
          {
            ...item,
            selected:
              vendorIndex === vendorIdx && invoiceIndex === index
                ? !item.selected
                : item.selected,
          },
        ];
      }, []);
    });
    checkSelected(invoicesCopy, vendorIndex);
    return props.onCheck(invoicesCopy);
  };

  const checkSelected = (invoicesCopy, vendorIndex) => {
    const allSelected = invoicesCopy[vendorIndex].every((item) => {
      return item.selected;
    });

    setInvoices(invoicesCopy);
    setVendors(
      vendors.reduce((acc, item, index) => {
        return [
          ...acc,
          {
            ...item,
            selected: vendorIndex === index ? allSelected : item.selected,
          },
        ];
      }, [])
    );
  };

  const selectVendor = (vendor, vendorIndex) => {
    vendor.selected = !vendor.selected;
    let invoicesList = vendors.map((item, index) => {
      return invoices[index].reduce((acc, item) => {
        return [
          ...acc,
          {
            ...item,
            selected: index === vendorIndex ? vendor.selected : item.selected,
          },
        ];
      }, []);
    });
    setInvoices(invoicesList);
    props.onCheck(invoicesList);
  };

  const totalSelected = (index) => {
    return invoices[index].reduce((acc, item) => {
      return item.selected ? ++acc : acc;
    }, 0);
  };

  const totalAmount = (index) => {
    // return invoices[index].reduce((acc, item) => {
    //   return acc + (item.selected && parseFloat(item.documentAmount));
    // }, 0);
    let uniqueCurrencies = invoices[index]
      .filter((invoice) => invoice.selected)
      .map((invoice) => invoice.documentCurrency)
      .filter((value, index, self) => self.indexOf(value) === index);

    if (uniqueCurrencies.length > 0) {
      return uniqueCurrencies.map((item) => {
        return {
          currency: item,
          amount: invoices[index].reduce((acc, invoice) => {
            return (
              acc +
              (invoice.selected &&
                invoice.documentCurrency === item &&
                parseFloat(invoice.documentAmount))
            );
          }, 0),
        };
      });
    }
    return [
      {
        currency: invoices[index][0].documentCurrency,
        amount: 0,
      },
    ];
  };

  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ? currencyCode : 'USD',
    });
  };

  return (
    <div style={{ width: '100%' }}>
      {vendors.map((vendor, index) => {
        return (
          <div
            key={`vendor-${vendor.invoiceVendorCode}-${index}`}
            ref={(el) =>
              (props.refs[props.companyCode + vendor.invoiceVendorCode] = el)
            }>
            <div onClick={() => setVisibility(index)} className={Style.vendor}>
              {vendor.visible ? (
                <ChevronDownIcon className="chevron" />
              ) : (
                <ChevronRightIcon />
              )}
              <div className={Style.vendorHeader}>
                <div className={Style.vendorTitle}>
                  <div>{`${vendor.invoiceVendorCode} - ${vendor.invoiceVendorName}`}</div>
                  {!props.readOnly && (
                    <div
                      className={
                        isPositive(totalAmount(index))
                          ? Style.selectedInvoicesPositive
                          : Style.selectedInvoices
                      }>
                      <div>{totalSelected(index)} selected invoices</div>
                      <div className={Style.totals}>
                        {totalAmount(index).map((item, index) => {
                          return (
                            <div key={`currency-${index}`}>
                              {item.currency}{' '}
                              {formatter('USD').format(item.amount)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className={Style.vendorInfo}>
                  <BulletedList>
                    <BulletedList.Item>
                      Payment Method:{' '}
                      {vendor.vendorPmtM ? vendor.vendorPmtM : '-'}
                    </BulletedList.Item>
                    {vendor.indivPmt === '1' && (
                      <BulletedList.Item>
                        Individual Payment: Yes
                      </BulletedList.Item>
                    )}
                    {(vendor.indivPmt === '0' || vendor.indivPmt == null) && (
                      <BulletedList.Item>
                        Individual Payment: No
                      </BulletedList.Item>
                    )}
                    <BulletedList.Item>
                      Bank Key: {vendor.bankKey ? vendor.bankKey : '-'}
                    </BulletedList.Item>
                    <BulletedList.Item>
                      Bank Type:{' '}
                      {vendor.partnerBankType ? vendor.partnerBankType : '-'}
                    </BulletedList.Item>
                    <BulletedList.Item>
                      Account Number:{' '}
                      {vendor.bankAccountNumber
                        ? vendor.bankAccountNumber
                        : '-'}
                    </BulletedList.Item>
                  </BulletedList>
                </div>
              </div>
            </div>
            {vendor.visible && (
              <Table className={Style.table}>
                <Table.Head>
                  <Table.Head.Row>
                    {!props.readOnly && (
                      <Table.Head.Cell>
                        <input
                          type="checkbox"
                          onChange={() => selectVendor(vendor, index)}
                          checked={vendor.selected}
                        />
                      </Table.Head.Cell>
                    )}
                    <Table.Head.Cell>INVOICE CC</Table.Head.Cell>
                    <Table.Head.Cell>DOC #</Table.Head.Cell>
                    <Table.Head.Cell>REFERENCE</Table.Head.Cell>
                    <Table.Head.Cell>DOC DATE</Table.Head.Cell>
                    <Table.Head.Cell>POSTING DATE</Table.Head.Cell>
                    <Table.Head.Cell>DUE DATE</Table.Head.Cell>
                    <Table.Head.Cell>DEBIT/CREDIT</Table.Head.Cell>
                    <Table.Head.Cell>AMOUNT ($)</Table.Head.Cell>
                    <Table.Head.Cell>CLUSTER</Table.Head.Cell>
                  </Table.Head.Row>
                </Table.Head>
                <Table.Body>
                  {invoices[index]?.map((invoice, invoiceIndex) => {
                    return (
                      <Table.Body.Row
                        key={`invoice-${invoice.invoiceCC}-${invoiceIndex}`}>
                        {!props.readOnly && (
                          <Table.Body.Cell>
                            <input
                              type="checkbox"
                              onChange={() => {
                                selectInvoices(index, invoiceIndex);
                              }}
                              checked={invoice.selected}
                            />
                          </Table.Body.Cell>
                        )}
                        <Table.Body.Cell>
                          {invoice.invoiceCC || '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {invoice.documentNumber || '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {invoice.reference || '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {(invoice.docDate &&
                            new Date(invoice.docDate).toLocaleDateString()) ||
                            '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {(invoice.postingDate &&
                            new Date(
                              invoice.postingDate
                            ).toLocaleDateString()) ||
                            '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {(invoice.dueDate &&
                            new Date(invoice.dueDate).toLocaleDateString()) ||
                            '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {invoice.debitCredit || '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {invoice.documentCurrency +
                            ' ' +
                            formatter('USD').format(invoice.documentAmount) ||
                            '-'}
                        </Table.Body.Cell>
                        <Table.Body.Cell>
                          {invoice.cluster || '-'}
                        </Table.Body.Cell>
                      </Table.Body.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default InvoicesTable;
