import React from 'react';
import { useDispatch } from 'react-redux';
import { unauthenticate } from './Store/Reducers/authenticationSlice';
import { Container, BanIcon } from '@exxonmobil/react-unity';

const AccessDenied = (props) => {
  useDispatch()(unauthenticate());

  return (
    <Container>
      <BanIcon
        style={{
          color: 'red',
          width: '260px',
          height: '260px',
          margin: '0 auto',
          marginTop: '15%',
        }}
      />
      <p
        style={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 'bold',
        }}>
        You are not authorized to to access this application!
      </p>
    </Container>
  );
};

export default AccessDenied;
