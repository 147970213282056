import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@exxonmobil/react-unity';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import { useHistory } from 'react-router-dom';

import { SelectGroup } from 'Components/SelectGroup';
import { DatePickerGroup } from 'Components/DatePickerGroup';
import Autocomplete from 'Components/Autocomplete/Autocomplete';

import { setLoading } from 'Store/Reducers/uiSlice';
import moment from 'moment';

import Styles from './style.module.css';

export const GenerateFileFilters = (props) => {
  const { register, handleSubmit, control, errors, reset } = useForm();
  const dispatch = useDispatch();

  const filters = props.filterApplied;
  const history = useHistory();

  const [fileTemplateValue, setFileTemplateValue] = useState();
  const [sapSystemCodeValue, setSapSystemCodeValue] = useState();
  const [clusterValue, setClusterValue] = useState();

  const onSubmit = async (data) => {
    dispatch(setLoading(true));
    const filters = { ...data };
    filters.dueDate = simpleDateFormat(new Date(filters.dueDate));
    filters.companies = filters.companies?.reduce((acc, item) => {
      return [...acc, item.code];
    }, []);
    filters.sapSystemCode === '' && (filters.sapSystemCode = null);
    const query = window.btoa(JSON.stringify(filters));
    const response = await props.filter(query);
    redirectReport(query, response, props.fileTemplates, props.clusters);
  };

  const simpleDateFormat = (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
    ].join('-');
  };

  const createCompanyObj = () => {
    const selectedCompaniesArr = [];
    filters.companies.map(async (company, index) => {
      selectedCompaniesArr[index] = { code: company, name: null };
      return selectedCompaniesArr;
    });
    return selectedCompaniesArr;
  };

  const redirectReport = (query, response, fileTemplates, clusters) => {
    history.push({
      pathname: '/payment-file-review',
      search: `?filter=${query}`,
      state: {
        results: response,
        fileTemplates: fileTemplates,
        clusters: clusters,
      },
    });
  };

  const today = () => {
    const now = new Date();
    return new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
  };

  const dueDateErrorMessage = {
    invalidDate:
      'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
    minDate: 'Due date should not be sooner than today',
    maxDate: 'Due date should not be later than 21 working days from now',
    required: 'Required',
  };

  const setVariables = () => {
    filters?.fileTemplateId && setFileTemplateValue(filters.fileTemplateId);
    filters?.sapSystemCode && setSapSystemCodeValue(filters.sapSystemCode);
    filters?.cluster && setClusterValue(filters.cluster);
    filters?.companies && (filters.companies = createCompanyObj());
    filters?.dueDate &&
      (filters.dueDate = new Date(filters?.dueDate).toLocaleDateString());
    reset(filters);
  };

  useEffect(() => {
    register({ name: 'fileTemplateId' }, { required: 'Required' });
    register(
      { name: 'dueDate' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          minDate: (value) => {
            return new Date(value) >= today();
          },
          maxDate: (value) => {
            const oneDay = 24 * 60 * 60 * 1000;
            const workingDays = 31;
            return (
              Math.round(Math.abs((new Date(value) - today()) / oneDay)) <=
              workingDays
            );
          },
        },
      }
    );
    register({ name: 'sapSystemCode' });
    register({ name: 'companies' });
    register({ name: 'cluster' });
  }, [register]);

  useEffect(() => {
    setVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={Styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={Styles.content}>
        <div className={Styles.title}>Select file to generate</div>
        <SelectGroup
          name="fileTemplateId"
          label="File Template"
          register={register}
          control={control}
          options={props.fileTemplates}
          option="description"
          error={errors.fileTemplateId}
          value={fileTemplateValue}
          isLoading={props.fileTemplatesLoading}
        />
        <DatePickerGroup
          control={control}
          register={register}
          name="dueDate"
          label="Due Date"
          error={dueDateErrorMessage[errors.dueDate?.type]}
        />
        <SelectGroup
          name="sapSystemCode"
          register={register}
          control={control}
          label="SAP Systems"
          optional
          options={props.sapSystems}
          option="systemCode"
          error={errors.sapSystemCode}
          isLoading={props.sapSystemsLoading}
          value={sapSystemCodeValue}
        />
        <Autocomplete
          isMulti
          placeholder="Type to search"
          name="companies"
          label="Company Codes"
          optional
          register={register}
          control={control}
          loadOptions={props.companies}
          minLength="3"
          loadWait="500"
        />
        <SelectGroup
          name="cluster"
          register={register}
          control={control}
          label="Cluster/Team"
          optional
          options={props.clusters}
          option="name"
          error={errors.cluster}
          isLoading={props.clustersLoading}
          value={clusterValue}
        />
        <Button
          className={Styles.button}
          type="submit"
          onClick={() => onSubmit}
          variant="primary">
          Generate Pre-Disbursement Report
        </Button>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default GenerateFileFilters;
