import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Table, Button, CheckIcon, XFilledIcon } from '@exxonmobil/react-unity';
import { Autocomplete } from 'Components/Autocomplete';
import { Switch } from '@exxonmobil/react-unity';
import { MultiSelectGroup } from 'Components/MultiSelectGroup';
import { SelectGroup } from 'Components/SelectGroup';

import InvoiceService from 'Services/invoicesService';

import PaymentFileService from 'Services/paymentFileService';
import { AdvancedVendorSearch } from 'Components/AdvancedVendorSearch';

const VendorCompanyEditRow = (props) => {
  const { register, control, errors, getValues, reset } = useForm({
    mode: 'onBlur',
  });

  const { vc, system, vendorNumber , hasIVRole, hasVendorAdminRole} = props;
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [individualPayment, setIndividualPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentBlock, setPaymentBlock] = useState('');
  const [postingBlock, setPostingBlock] = useState(false);
  const [altPayee, setAltPayee] = useState('');

  useEffect(() => {
    register({ name: 'altPayee' }, { required: false });
    register({ name: 'company' }, { required: true });
  }, [register]);

  const getPaymentMethods = async (system, country) => {
    const payments = await InvoiceService.getPaymentMethods(system, country);
    setPaymentMethods(payments.data);
  };

  useEffect(() => {
    if (system && country) {
      getPaymentMethods(system, country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system, country]);

  useEffect(() => {
    if (vc.vendorNumber && vc.systemCode) {
      setCompany(vc.companyCode);
      setCountry(vc.countryCode);
      setIndividualPayment(vc.individualPayment);
      setPaymentMethod(vc.paymentMethodCodes);
      setPaymentBlock(vc.paymentBlock);
      setPostingBlock(vc.postingBlock);
      setAltPayee(vc.altPayee);
      reset({
        altPayee: vc.alternativePayeeNumber
          ? {
              vendorNumber: vc.alternativePayeeNumber,
              vendorName: vc.alternativePayeeName,
            }
          : '',
        company: { code: vc.companyCode, name: vc.companyName },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vc, reset]);

  const saveVendorCompany = () => {
    let stagingVC = {
      companyCode: company,
      paymentMethodCodes: paymentMethod,
      individualPayment,
      paymentBlock,
      postingBlock,
      alternativePayeeNumber: altPayee?.vendorNumber,
      vendorNumber: vendorNumber,
      systemCode: system,
    };
    if (vc.vendorNumber && vc.systemCode) {
      stagingVC.vendorNumber = vc.vendorNumber;
      stagingVC.systemCode = vc.systemCode;
      props.putVendorCompany(stagingVC);
      return;
    }

    props.postVendorCompany(stagingVC);
  };

  const getCompanies = async (query) => {
    const list = await PaymentFileService.getCompanies(query);
    return list.data;
  };
  const [paymentBlocks] = useState([
    { id: 'V', name: 'V' },
    { id: 'G', name: 'G' },
    { id: 'A', name: 'A' },
    { id: '0', name: 'Open' },
  ]);
  return (
    <>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          width: '250px',
        }}>
        {!vc.companyCode ? (
          <Autocomplete
            placeholder="Type to search"
            name="company"
            register={register}
            control={control}
            loadOptions={getCompanies}
            minLength="3"
            loadWait="500"
            error={errors.company}
            value={company}
            setSelected={(company) => {
              setCompany(company?.code);
              setCountry(company?.countryCode);
            }}
          />
        ) : (
          vc.companyCode + ' - ' + vc.companyName
        )}
      </Table.Body.Cell>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          width: '60px',
        }}>
        <center>
          { hasVendorAdminRole?            
            <Switch
            active={individualPayment}
            onClick={() => setIndividualPayment(!individualPayment)}
            />
            : individualPayment ? "Yes" : "No"
          }
        </center>
      </Table.Body.Cell>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          width: '85px',
        }}> 
        { hasVendorAdminRole ? 
          <div>
          {country ? (
            <MultiSelectGroup
            placeholder="..."
            name="paymentMethod"
            register={register}
            control={control}
            options={paymentMethods}
            option="paymentMethodCode"
            value={paymentMethod}
            onChange={(element) => {
              setPaymentMethod(element.map((x) => x.paymentMethodCode));
            }}
            noDefault
            />
            ) : (
              '-'
              )}
        </div>
        : paymentMethod?.length > 0 ? paymentMethod.join(", ") :"-" }
      </Table.Body.Cell>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          width: '100px',
        }}>
        <SelectGroup
          disabled={!hasIVRole}
          placeholder="..."
          name="paymentBlock"
          register={register}
          control={control}
          options={paymentBlocks}
          option="name"
          value={paymentBlock}
          onChange={(element) => setPaymentBlock(element.target.value)}
          noDefault
        />
      </Table.Body.Cell>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          width: '60px',
        }}>
          {
            hasVendorAdminRole ?
            <Switch
            active={postingBlock}
            onClick={() => setPostingBlock(!postingBlock)}
            />
            :
            postingBlock ? "Yes" : "No"
          }
      </Table.Body.Cell>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          maxWidth: '300px',
          width: '300px',
        }}>
        <AdvancedVendorSearch
          disabled={!company || !hasVendorAdminRole}
          placeholder="Type to search"
          name="altPayee"
          register={register}
          control={control}
          loadOptions={(query) => props.getVendors(query, system, company)}
          minLength="3"
          loadWait="500"
          error={errors.altPayee}
          code="vendorNumber"
          desc="vendorName"
          reset={reset}
          getValues={getValues}
          system={system}
          setSelected={(option) => setAltPayee(option)}
        />
      </Table.Body.Cell>
      {!props.hideButtons && (
        <Table.Body.Cell
          style={{
            verticalAlign: 'middle',
            width: '85px',
          }}>
          <Button
            onClick={() => saveVendorCompany()}
            variant="primary"
            size="small"
            style={{
              height: '30px',
            }}>
            <CheckIcon size="small" />
          </Button>
          <Button
            onClick={() => props.cancel()}
            variant="secondary"
            size="small"
            style={{
              height: '30px',
            }}>
            <XFilledIcon size="small" />
          </Button>
        </Table.Body.Cell>
      )}
    </>
  );
};

export default VendorCompanyEditRow;
