import React, { useEffect, useState } from 'react';
import { Button } from '@exxonmobil/react-unity';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';

import Style from './style.module.css';
import { DatePickerGroup } from 'Components/DatePickerGroup';
import { Loading } from 'Components/Loading';
import { PaymentSummaryTable } from 'Partials/PaymentSummaryTable';
import { Autocomplete } from 'Components/Autocomplete';
import moment from 'moment';

export const PaymentSummaryFilter = (props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filters, setFilters] = useState({});

  const { register, handleSubmit, getValues, control, errors } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);

    const filters = {};
    const { dueDateStart, dueDateEnd, companyCode } = data;
    const { getSummary } = props;
    filters.dueDateStart = simpleDateFormat(new Date(dueDateStart));
    filters.dueDateEnd = simpleDateFormat(new Date(dueDateEnd));
    filters.companyCode = companyCode?.code;

    await getSummary(filters);
    setSubmitted(true);
    setFilters(filters);
    setLoading(false);
  };

  const today = () => {
    const now = new Date();
    return new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
  };

  const dueDateErrorMessage = {
    // minDate: 'Due date should not be sooner than today',
    invalidDate:
      'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
    maxDate: 'Due date should not be later than 21 working days from now',
    laterThanStart: 'End Date should not be sooner than Start Date',
    required: 'Required',
  };

  const maxDateValidation = (value) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const workingDays = 31;
    return Math.round((new Date(value) - today()) / oneDay) <= workingDays;
  };

  const simpleDateFormat = (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
    ].join('-');
  };

  useEffect(() => {
    register(
      { name: 'dueDateStart' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          maxDate: (value) => {
            return maxDateValidation(value);
          },
        },
      }
    );

    register(
      { name: 'dueDateEnd' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          laterThanStart: (value) => {
            return new Date(value) >= new Date(getValues('dueDateStart'));
          },
          maxDate: (value) => {
            return maxDateValidation(value);
          },
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={Style.content}>
        <div className={Style.title}>Forecast Payment Summary</div>
        <div className={Style.filters}>
          <DatePickerGroup
            className={Style.item}
            id="due-date-start"
            control={control}
            register={register}
            name="dueDateStart"
            label="Due Date Start"
            error={dueDateErrorMessage[errors.dueDateStart?.type]}
          />
          <DatePickerGroup
            className={Style.item}
            id="due-date-end"
            control={control}
            register={register}
            name="dueDateEnd"
            label="Due Date End"
            error={dueDateErrorMessage[errors.dueDateEnd?.type]}
          />
          <Autocomplete
            className={Style.item}
            placeholder="Type to search"
            name="companyCode"
            label="Company Code"
            optional
            register={register}
            isClearable={true}
            control={control}
            loadOptions={props.onSearchCompanies}
            minLength="3"
            loadWait="500"
          />
          <Button
            className={Style.button}
            type="submit"
            onClick={() => onSubmit}
            variant="primary">
            Display Summary
          </Button>
        </div>
      </form>
      <Loading isLoading={loading} message={'Generating Report...'} />
      {props.companies.length && submitted ? (
        <PaymentSummaryTable
          companies={props.companies}
          filters={filters}
          getSummary={props.getSummary}
          setLoading={setLoading}
        />
      ) : (
        submitted &&
        !loading && <div className={Style.notFound}>No results found</div>
      )}
      <DevTool control={control} />
    </>
  );
};

export default PaymentSummaryFilter;
