import React from 'react';
import { Modal, Button, XFilledIcon } from '@exxonmobil/react-unity';
import Style from './style.module.css';

export const GenericModal = (props) => {
  return (
    <div>
      <Modal show={props.show}>
        <Modal.Window
          className={props.large ? Style.large : props.huge && Style.huge}>
          <Modal.Window.Header>
            <div className={Style.header}>
              <div className={Style.title}>
                <Modal.Window.Header.Title>
                  {props.title}
                </Modal.Window.Header.Title>
              </div>
              {props.close && (
                <Button variant="tertiary" onClick={() => props.close()}>
                  <XFilledIcon size="small"></XFilledIcon>
                </Button>
              )}
            </div>
          </Modal.Window.Header>
          <Modal.Window.Body className={Style.content}>
            {props.content}
          </Modal.Window.Body>
          {props.footer && (
            <Modal.Window.Footer className={Style.footer}>
              <>
                <div className={Style.buttons}>
                  <Button.Group>
                    <Button variant="primary" onClick={props.primaryAction}>
                      {props.primaryButton}
                    </Button>
                    {props.secondaryButton && (
                      <Button onClick={props.secondaryAction}>
                        {props.secondaryButton}
                      </Button>
                    )}
                  </Button.Group>
                </div>
              </>
            </Modal.Window.Footer>
          )}
        </Modal.Window>
      </Modal>
    </div>
  );
};

export default GenericModal;
