import React from 'react';
import { Button, Table, ExclamationFilledIcon } from '@exxonmobil/react-unity';
import Styles from './InvoiceMassUpload.module.css';

const ResultWarning = (props) => {
  return (
    <>
      <div className={`${Styles.checkcontainer} ${Styles.resultwarn}`}>
        <ExclamationFilledIcon className={Styles.check} />
      </div>
      <div>
        <p className={Styles.checktitle}>
          The file has been processed with errors:
        </p>
        <p className={Styles.checktext}>
          {props.total} invoices have been imported, but following entries were
          invalid:
        </p>
        <Table condensed striped style={{ maxWidth: '50em' }}>
          <Table.Head>
            <Table.Head.Row>
              <Table.Head.Cell> Row # </Table.Head.Cell>
              <Table.Head.Cell> Problems </Table.Head.Cell>
            </Table.Head.Row>
          </Table.Head>
          <Table.Body>
            {props.errorList.map((row) => (
              <Table.Body.Row key={row.item1}>
                <Table.Body.Cell>{row.item1}</Table.Body.Cell>
                <Table.Body.Cell>
                  <ul>
                    {row.item2.map((error) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </Table.Body.Cell>
              </Table.Body.Row>
            ))}
          </Table.Body>
        </Table>
        <div>
          <Button
            variant="default"
            size="small"
            style={{ marginRight: '20px' }}
            onClick={props.home}>
            Go to Home
          </Button>
          <Button variant="default" size="small" onClick={props.reset}>
            Upload another file
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResultWarning;
