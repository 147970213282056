import { createSlice } from '@reduxjs/toolkit';

const paymentFileSlice = createSlice({
  name: 'auth',
  initialState: {
    fileTemplates: [],
    clusters: [],
    sapSystems: [],
    companies: [],
  },
  reducers: {
    setFileTemplates: (state, action) => {
      return { ...state, fileTemplates: action.payload };
    },
    setClusters: (state, action) => {
      return { ...state, clusters: action.payload };
    },
    setSapSystems: (state, action) => {
      return { ...state, sapSystems: action.payload };
    },
    setCompanies: (state, action) => {
      return { ...state, companies: action.payload };
    },
  },
});

export const {
  setFileTemplates,
  setClusters,
  setSapSystems,
  setCompanies,
} = paymentFileSlice.actions;

export default paymentFileSlice.reducer;
