import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { Loader } from '@exxonmobil/react-unity';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './Providers/authProvider';

import './index.css';
import '@exxonmobil/react-unity/dist/unity.css';

import App from './App';
import AccessDenied from './AccessDenied';
import RootReducer from 'Store/store';

const store = configureStore({
  reducer: RootReducer,
});

ReactDOM.render(
  <Provider store={store}>
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            if (accountInfo.account.idTokenClaims.roles?.includes('User'))
              return <App accountInfo={accountInfo} />;
            return <AccessDenied />;
          case AuthenticationState.Unauthenticated:
            return <p>You are not authenticated !</p>;
          case AuthenticationState.InProgress:
            return (
              <div
                style={{
                  position: 'relative',
                  width: '80px',
                  height: '80px',
                  margin: '0 auto',
                  top: 'calc(50% - 80px)',
                }}>
                <Loader />
              </div>
            );
          default:
            return;
        }
      }}
    </AzureAD>
  </Provider>,
  document.getElementById('root')
);
