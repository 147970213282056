import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoices: [],
  queryTimestamp: '',
};

const preDisbursementSlice = createSlice({
  name: 'predisbursement',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setPaymentInvoices: (state, action) => {
      state.invoices[action.payload.index] = action.payload.value;
      state.queryTimestamp = action.payload.value;
      return state;
    },
  },
});

export const { setPaymentInvoices, reset } = preDisbursementSlice.actions;

export default preDisbursementSlice.reducer;
