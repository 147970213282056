import React from 'react';
import { Loader } from '@exxonmobil/react-unity';

import Style from './style.module.css';

export const Loading = (props) => {
  return (
    <>
      {props.isLoading && (
        <div className={props.nobg ? Style.nobg : Style.background}>
          <div className={Style.loader}>
            <Loader />
          </div>
          <h1>{props.message}</h1>
        </div>
      )}
    </>
  );
};

export default Loading;
