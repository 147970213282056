import React, { useState } from 'react';
import { DatePicker } from '@exxonmobil/react-unity';
import moment from 'moment';
import { Controller } from 'react-hook-form';

import Style from './style.module.css';

const DatePickerGroup = (props) => {
  const [error, setError] = useState(null);

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/[^0-9/]+$/g, '').substring(0, 10);
    const isValidDate = validateDate(inputValue);

    if (!isValidDate) {
      setError(
        'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)'
      );
      return inputValue;
    }

    setError(null);
    return new Date(inputValue).toLocaleDateString();
  };

  const validateDate = (date) => {
    return moment(date, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
  };

  return (
    <div className={props.className}>
      {props.noLabel || (
        <div className={Style.label}>
          {props.label}
          <div className={Style.optional}>{props.optional && '(Optional)'}</div>
        </div>
      )}
      <Controller
        as={
          <DatePicker
            className={Style.datePicker}
            error={!!error ? !!error : !!props.error}
            note={error ? error : props.error}
            id={props.id}
          />
        }
        readOnly={props.readOnly}
        name={props.name}
        control={props.control}
        register={{ ...props.register }}
        placeholder={props?.placeholder ? props?.placeholder : 'Please select'}
        onChange={([event]) => {
          if (props.onChange) {
            props.onChange(event);
          }
          if (event.target.value?.toLocaleDateString) {
            setError(null);
            return event.target.value.toLocaleDateString();
          }
          // typing input
          if (event.target.value) {
            return handleInputChange(event.target.value);
          }
        }}
      />
    </div>
  );
};

export default DatePickerGroup;
