import React, { useEffect, useState } from 'react';
import Api from 'Services/vendorsService';
import { BankInformationEditRow } from 'Components/BankInformationEditRow';
import { Loading } from 'Components/Loading';

import {
  Button,
  Table,
  PencilIcon,
  TrashcanEmptyIcon,
} from '@exxonmobil/react-unity';
import Styles from './style.module.css';

const BankInformationTable = (props) => {
  const [bankInfos, setBankInfos] = useState([]);
  const [newBankDetail, setNewBankDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const { vendorNumber, systemCode, countries, hasVendorAdminRole } = props;

  useEffect(() => {
    if (vendorNumber && systemCode) {
      loadBankInfos(systemCode, vendorNumber);
    }
  }, [vendorNumber, systemCode]);

  const loadBankInfos = async (systemCode, vendorNumber) => {
    setLoading(true);
    const response = await Api.getBankDetails(systemCode, vendorNumber);
    setBankInfos(response.data);
    setLoading(false);
  };

  const postBankDetails = (bankDetail) => {
    let errors = validate(bankDetail);
    if (errors.length > 0) {
      props.showErrors(errors);
      return;
    }
    setLoading(true);
    Api.postBankDetail(bankDetail)
      .then(() => {
        loadBankInfos(systemCode, vendorNumber);
      })
      .finally(() => {
        setAddMode(false);
      });
  };
  const putBankDetails = (bankDetail) => {
    let errors = validate(bankDetail);
    if (errors.length > 0) {
      props.showErrors(errors);
      return;
    }
    setLoading(true);
    Api.deleteBankDetail(bankDetail)
      .then(() => {
        loadBankInfos(systemCode, vendorNumber);
      })
      .finally(() => {
        setEditIndex(-1);
      });
  };
  const deleteBankDetails = (bankDetail) => {
    setLoading(true);
    Api.deleteBankDetail(bankDetail)
      .then(() => {
        loadBankInfos(systemCode, vendorNumber);
      })
      .finally(() => {
        setEditIndex(-1);
      });
  };

  const validate = (bankDetail) => {
    let errors = [];
    if (bankDetail.countryCode === '') {
      errors.push('Country code is required!');
    }
    if (bankDetail.bankKeys === '') {
      errors.push('Bank Keys are required!');
    }
    if (bankDetail.bankAccountNumber === '') {
      errors.push('Bank Account Number is required!');
    }
    return errors;
  };

  return (
    <>
      {!loading && (
        <Table className={Styles.table}>
          <Table.Head>
            <Table.Head.Row>
              <Table.Head.Cell>Country Code</Table.Head.Cell>
              <Table.Head.Cell>Bank Keys</Table.Head.Cell>
              <Table.Head.Cell>Bank Acct #</Table.Head.Cell>
              <Table.Head.Cell>Partner Bank Type</Table.Head.Cell>
              <Table.Head.Cell>Swift</Table.Head.Cell>
              <Table.Head.Cell>IBAN</Table.Head.Cell>
              {
                hasVendorAdminRole &&
                <Table.Head.Cell>Edit</Table.Head.Cell>
              }
            </Table.Head.Row>
          </Table.Head>
          {bankInfos?.length > 0 ? (
            <Table.Body>
              {bankInfos?.map((bankInfo, index) => (
                <Table.Body.Row key={index}>
                  {editIndex === index ? (
                    <BankInformationEditRow
                      key={index}
                      bankDetail={bankInfo}
                      system={systemCode}
                      vendorNumber={vendorNumber}
                      getVendors={props.getVendors}
                      countries={countries}
                      action={putBankDetails}
                      cancel={() => setEditIndex(-1)}
                    />
                  ) : (
                    <>
                      <Table.Body.Cell>{bankInfo.countryCode}</Table.Body.Cell>
                      <Table.Body.Cell>{bankInfo.bankKeys}</Table.Body.Cell>
                      <Table.Body.Cell>
                        {bankInfo.bankAccountNumber}
                      </Table.Body.Cell>
                      <Table.Body.Cell>
                        {bankInfo.partnerBankType?.trim()
                          ? bankInfo.partnerBankType
                          : '-'}
                      </Table.Body.Cell>
                      <Table.Body.Cell>
                        {bankInfo.swiftCode ? bankInfo.swiftCode : '-'}
                      </Table.Body.Cell>
                      <Table.Body.Cell>
                        {bankInfo.ibanCode ? bankInfo.ibanCode : '-'}
                      </Table.Body.Cell>
                      {
                        hasVendorAdminRole &&
                        <Table.Body.Cell>
                        <Button
                          onClick={() => setEditIndex(index)}
                          variant="primary"
                          size="small"
                          style={{
                            height: '30px',
                            marginRight: '10px',
                          }}>
                          <PencilIcon size="small" />
                        </Button>
                        <Button
                          onClick={() => deleteBankDetails(bankInfo)}
                          variant="secondary"
                          size="small"
                          style={{
                            height: '30px',
                          }}>
                          <TrashcanEmptyIcon size="small" />
                        </Button>
                      </Table.Body.Cell>
                          }
                    </>
                  )}
                </Table.Body.Row>
              ))}
            </Table.Body>
          ) : (
            <>
              {!addMode && (
                <tbody className={Styles.notFound}>
                  <tr>
                    <td colSpan={7}>
                      <p
                        style={{
                          marginTop: '50px',
                        }}>
                        No results found
                      </p>
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          )}

          {addMode && (
            <Table.Body>
              <BankInformationEditRow
                bankDetail={newBankDetail}
                system={systemCode}
                vendorNumber={vendorNumber}
                getVendors={props.getVendors}
                countries={countries}
                action={postBankDetails}
                cancel={() => setAddMode(false)}
              />
            </Table.Body>
          )}
        </Table>
      )}
      <Loading isLoading={loading} nobg />
      {
        hasVendorAdminRole &&
        <Button
        onClick={() => {
          setNewBankDetail({});
          setAddMode(true);
        }}
        style={{
          marginRight: '10px',
        }}
        variant="primary">
        Add New
      </Button>
        }
    </>
  );
};

export default BankInformationTable;
