import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SelectGroup } from 'Components/SelectGroup';
import { useForm } from 'react-hook-form';
import Autocomplete from 'Components/Autocomplete/Autocomplete';
import { Toggle } from '@exxonmobil/react-unity';
import { DatePickerGroup } from 'Components/DatePickerGroup';
import { TextInputGroup } from 'Components/TextInputGroup';
import { MoneyInputGroup } from 'Components/MoneyInputGroup';
import { useState } from 'react';
import moment from 'moment';
import { AdvancedVendorSearch } from 'Components/AdvancedVendorSearch';
import { DevTool } from 'react-hook-form-devtools';
import {
  Button,
  CalendarIcon,
  Grid,
  GridItem,
  NewspaperIcon,
  User1Icon,
} from '@exxonmobil/react-unity';

import Styles from './style.module.css';

const NewInvoicePage = (props) => {
  const { register, control, errors, getValues, handleSubmit, reset } = useForm(
    {
      mode: 'onBlur',
    }
  );
  const { invoice } = props;
  const history = useHistory();
  const [system, setSystem] = useState('');
  const [postingKey, setPostingKey] = useState('31');
  const [company, setCompany] = useState('');
  const [vendorNumber, setVendorNumber] = useState('');
  const [country, setCountry] = useState('');
  const [cluster, setCluster] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [currency, setCurrency] = useState('');

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const dateErrorMessage = {
    invalidDate:
      'The provided date is invalid. Please, check if it follows the correct format (MM/DD/YYYY)',
    laterThanStart: 'Due date should not be sooner than the Document Date',
    required: 'Required',
  };

  useEffect(() => {
    if (invoice.id) {
      setSystem(invoice.system);
      setCompany(invoice.company);
      setAmount(invoice.amount);
      setCountry(invoice.countryCode);
      setPostingKey(invoice.postingKey);
      setCluster(invoice.clusterId);
      setPoNumber(invoice.poNumber);
      setPaymentMethod(invoice.paymentMethod);
      setInvoiceNumber(invoice.invoiceNumber);
      setVendorNumber(invoice.vendorNumber);
      setCurrency(invoice.currencyCode);
      // Set Default Form Values
      // https://github.com/react-hook-form/react-hook-form/issues/2492
      reset({
        system: invoice.system,
        company: invoice.company
          ? { code: invoice.company, name: invoice.companyName }
          : '',
        vendor: invoice.vendorNumber
          ? {
              vendorNumber: invoice.vendorNumber,
              vendorName: invoice.vendorName,
            }
          : '',
        documentDate: invoice.invoiceDate
          ? new Date(invoice.invoiceDate).toLocaleDateString()
          : null,
        dueDate: invoice.dueDate
          ? new Date(invoice.dueDate).toLocaleDateString()
          : null,
        postingDate: invoice.postingDate
          ? new Date(invoice.postingDate).toLocaleDateString()
          : null,
        postingKey: invoice.postingKey,
        invoiceNumber: invoice.invoiceNumber,
        paymentMethod: invoice.paymentMethod,
        clusterId: invoice.clusterId,
        poNumber: invoice.poNumber,
        amount: invoice.amount,
        currency: invoice.currencyCode,
      });
    } else {
      reset({
        postingDate: new Date().toLocaleDateString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, reset]);

  useEffect(() => {
    if (company !== '' && system !== '' && vendorNumber !== '') {
      props.getPaymentMethods(system, company, vendorNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system, company, vendorNumber]);

  const onSubmit = () => {
    const stagingInvoice = assembleInvoice(false);
    if (invoice.id) {
      stagingInvoice.id = invoice.id;
      props.updateInvoice(stagingInvoice);
      return;
    }
    props.saveInvoice(stagingInvoice);
  };

  const saveAsDraft = () => {
    const stagingInvoice = assembleInvoice(true);
    if (invoice.id) {
      stagingInvoice.id = invoice.id;
      props.updateInvoice(stagingInvoice);
      return;
    }
    props.saveInvoice(stagingInvoice);
  };

  const assembleInvoice = (draft) => {
    const form = { ...getValues() };
    form.invoiceDate = getISODate(form.documentDate);
    form.amount = Number.parseFloat(
      form.amount?.toString().replace(/[^0-9.-]+/g, '')
    );
    form.dueDate = getISODate(form.dueDate);
    form.postingDate = getISODate(form.postingDate);
    form.company = form.company?.code;
    form.vendorName = form.vendor?.vendorName;
    form.vendorNumber = form.vendor?.vendorNumber;
    delete form.vendor;
    return {
      ...form,
      // amount: Number.parseFloat(amount.replace(/[^0-9.-]+/g, '')),
      postingKey: postingKey,
      cluster: '',
      status: draft ? 'DRAFT' : 'OPEN',
      paymentBlock: '',
      fiscalYear: getYear(invoice.entryDate),
      countryCode: country,
      entryDate: getISODate(invoice.entryDate),
      processor: invoice.processor,
      currencyCode: currency,
    };
  };

  const getISODate = (d) => {
    d = new Date(d);
    if (d instanceof Date && !isNaN(d)) {
      return new Date(d + 'Z').toISOString();
    }
  };
  const getYear = (d) => {
    d = new Date(d);
    if (d instanceof Date && !isNaN(d)) {
      return new Date(d).getFullYear().toString();
    }
  };
  useEffect(() => {
    register({ name: 'system' }, { required: 'Required' });
    register({ name: 'company' }, { required: 'Required' });
    register({ name: 'vendor' }, { required: 'Required' });
    register(
      { name: 'documentDate' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
        },
      }
    );
    register(
      { name: 'postingDate' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
        },
      }
    );
    register({ name: 'invoiceNumber' }, { required: 'Required' });
    register({ name: 'currency' }, { required: 'Required' });
    register(
      { name: 'amount' },
      {
        required: 'Required',
        validate: (value) =>
          value !== '$0.00' ? true : 'Amount should not be $0.00',
      }
    );
    register(
      { name: 'dueDate' },
      {
        required: 'Required',
        validate: {
          invalidDate: (value) => {
            return moment(value, ['MM/DD/YYYY', 'M/D/YYYY'], true).isValid();
          },
          laterThanStart: (value) => {
            return new Date(value) >= new Date(getValues('documentDate'));
          },
        },
      }
    );
    register({ name: 'paymentMethod' });
    register({ name: 'clusterId' });
    register(
      { name: 'poNumber' },
      {
        required: false,
        validate: (value) =>
          !value || value.match(/(null)|(^[0-9]+$)/) || value === ''
            ? true
            : 'Only numbers are accepted',
      }
    );
  }, [getValues, register]);

  return (
    <div>
      <div className={Styles.header}>
        <div className={Styles.document}>
          <div className={Styles.documentNumber}>
            {invoice.documentNumber
              ? `Document #${invoice.documentNumber}`
              : `New Document`}
          </div>
        </div>

        <div className={Styles.info}>
          <div className={Styles.details}>
            <div>
              <User1Icon size="small" className={Styles.icon} />
              Processed By: {invoice.processor || '-'}
            </div>
            <div>
              <CalendarIcon size="small" className={Styles.icon} />
              Entry Date:{' '}
              {invoice.entryDate !== null
                ? new Date(invoice.entryDate).toLocaleDateString()
                : '-'}
            </div>
            <div>
              <NewspaperIcon size="small" className={Styles.icon} />
              Status:
              {invoice.status === 'Draft' && (
                <div className={`${Styles.status} ${Styles.Draft}`}>
                  {invoice.status}
                </div>
              )}
            </div>
          </div>
          <div className={Styles.actions}>
            {company && system && (
              <>
                <Button variant="primary" type="submit" form="invoice-form">
                  Save
                </Button>
                <Button variant="secondary" onClick={() => saveAsDraft()}>
                  Save As Draft
                </Button>
              </>
            )}
            <Button
              variant="secondary"
              onClick={() => history.push('/invoices/manage/')}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.title}>Invoice Details</div>
        <form id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid variant={system && company ? '3-up' : '2-up'}>
            <GridItem>
              <SelectGroup
                useDesc={true}
                label={'SAP System'}
                name="system"
                control={control}
                register={register}
                options={props.systems}
                option="systemCode"
                isLoading={props.systemsLoading}
                onChange={(element) => {
                  reset({
                    ...getValues(),
                    system: element.target.value,
                    company: '',
                    country: '',
                    vendor: '',
                  });
                  setSystem(element.target.value);
                  setCompany('');
                  setVendorNumber('');
                  setCountry('');
                }}
                error={errors.system}
                value={system}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                placeholder={!system ? 'Select system first' : 'Type to search'}
                disabled={!system}
                name="company"
                label="Company Code"
                register={register}
                control={control}
                loadOptions={(value) => {
                  return props.getCompanies(system, value);
                }}
                minLength="3"
                loadWait="500"
                error={errors.company}
                setSelected={(company) => {
                  reset({
                    ...getValues(),
                    vendor: '',
                    company: company,
                  });
                  setCountry(company?.countryCode);
                  setCompany(company?.code);
                  setVendorNumber('');
                }}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <div>
                <AdvancedVendorSearch
                  placeholder={
                    !(company && system)
                      ? 'Type company and system'
                      : 'Type to search'
                  }
                  name="vendor"
                  label="Vendor Number"
                  register={register}
                  control={control}
                  loadOptions={(query) =>
                    props.getVendors(query, system, company)
                  }
                  minLength="3"
                  loadWait="500"
                  disabled={!(company && system)}
                  error={errors.vendor}
                  code="vendorNumber"
                  desc="vendorName"
                  reset={reset}
                  getValues={getValues}
                />
              </div>
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <Toggle label="Posting Key">
                <Toggle.Item
                  id="postingKey-inv"
                  key="postingKey-inv"
                  name="postingKey"
                  value={'31'}
                  checked={postingKey === '31'}
                  onChange={(e) => setPostingKey('31')}>
                  Invoice
                </Toggle.Item>
                <Toggle.Item
                  id="postingKey-memo"
                  key="postingKey-memo"
                  name="postingKey"
                  value={'21'}
                  checked={postingKey === '21'}
                  onChange={(e) => setPostingKey('21')}>
                  Credit Memo
                </Toggle.Item>
              </Toggle>
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <DatePickerGroup
                control={control}
                register={register}
                name="documentDate"
                label="Document Date"
                id="documentDate"
                error={dateErrorMessage[errors.documentDate?.type]}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <DatePickerGroup
                control={control}
                register={register}
                name="postingDate"
                label="Posting Date"
                id="postingDate"
                error={dateErrorMessage[errors.postingDate?.type]}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <TextInputGroup
                className={Styles.input}
                name="invoiceNumber"
                register={register}
                control={control}
                label="Invoice Number"
                maxLength="100"
                value={invoiceNumber}
                error={errors.invoiceNumber}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <MoneyInputGroup
                className={Styles.input}
                name="amount"
                register={register}
                control={control}
                label="Amount"
                value={amount}
                error={errors.amount}
                placeholder={'$0.00'}
                note={'Discounting WHT'}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <SelectGroup
                useDesc={true}
                label={'Currency'}
                name="currency"
                control={control}
                register={register}
                options={props.currencies}
                option="currencyCode"
                isLoading={props.currenciesLoading}
                onChange={(element) => {
                  setCurrency(element.target.value);
                }}
                error={errors.currency}
                value={currency}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <DatePickerGroup
                control={control}
                register={register}
                name="dueDate"
                label="Due Date"
                id="dueDate"
                error={dateErrorMessage[errors.dueDate?.type]}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <SelectGroup
                name="clusterId"
                register={register}
                control={control}
                label="Cluster/Team"
                optional
                options={props.clusters}
                option="name"
                error={errors.cluster}
                isLoading={props.clustersLoading}
                value={cluster}
              />
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <div className={Styles.select}>
                <SelectGroup
                  name="paymentMethod"
                  optional={true}
                  label="Payment Method"
                  register={register}
                  disabled={!(country && system)}
                  control={control}
                  options={props.paymentMethods}
                  option="paymentMethodCode"
                  isLoading={props.paymentMethodsLoading}
                  value={paymentMethod}
                  disabledPlaceholder="Select company and system"
                />
              </div>
            </GridItem>
            <GridItem style={{ display: system && company ? 'block' : 'none' }}>
              <TextInputGroup
                className={Styles.input}
                name="poNumber"
                register={register}
                control={control}
                label="PO Number"
                optional
                maxLength="10"
                value={poNumber}
                error={errors.poNumber}
              />
            </GridItem>
          </Grid>
        </form>
        <DevTool control={control} />
      </div>
    </div>
  );
};

export default NewInvoicePage;
