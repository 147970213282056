import React, { useState, useEffect } from 'react';
import {
  Table,
  ChevronRightIcon,
  ChevronDownIcon,
  Accordian,
} from '@exxonmobil/react-unity';

import Style from './style.module.css';

const PaymentSummaryTable = (props) => {
  // const [companies, setCompanies] = useState([]);
  const [summary, setSummary] = useState([]);
  const className = '';

  useEffect(() => {
    setSummary(initialSummary(props.companies));
  }, [props.companies]);

  const initialSummary = (summary) => {
    return summary.reduce((acc, item) => {
      return [
        ...acc,
        {
          ...item,
          treasuryCompaniesVM: item.treasuryCompaniesVM.reduce(
            (companyAcc, companyItem) => {
              return [...companyAcc, { ...companyItem, visible: false }];
            },
            []
          ),
          visible: false,
        },
      ];
    }, []);
  };

  const setVisibility = (companyIndex) => {
    setSummary(
      summary.reduce((acc, item, index) => {
        return [
          ...acc,
          {
            ...item,
            visible: index === companyIndex ? !item.visible : item.visible,
          },
        ];
      }, [])
    );
  };

  const setCompaniesVisibility = (companyIndex, summaryIndex) => {
    const newSummary = summary.reduce((acc, item, index) => {
      return [
        ...acc,
        index === summaryIndex
          ? {
            ...item,
            treasuryCompaniesVM: item.treasuryCompaniesVM.reduce(
              (vendorAcc, vendorItem, vendorIndex) => {
                return [
                  ...vendorAcc,
                  {
                    ...vendorItem,
                    visible:
                      vendorIndex === companyIndex
                        ? !vendorItem.visible
                        : vendorItem.visible,
                  },
                ];
              },
              []
              ),
            }
            : { ...item },
          ];
        }, []);
        setSummary(newSummary);
      };


    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const href = null;
    return (
      <div className={Style.container}>
        {summary.map((summary, index) => {
          return (
            <Accordian key={`summary-${index}`} className={Style.accordian}>
              <li
                className={`em-c-accordion__item em-js-accordion-item ${summary.visible === false ? 'em-is-closed' : ''
                  } ${className}`}>
                <div className="">
                  <a
                    href={href}
                    onClick={() => setVisibility(index)}
                    className="em-c-accordion__title em-js-accordion-trigger">
                    <div className={Style.header}>
                      <ChevronDownIcon
                        onClick={() => setVisibility(index)}
                        className="em-c-accordion__icon"
                        size="small"
                      />
                      <span
                        style={{ width: '100%' }}
                        className="em-c-accordion__title-text">
                        <>
                          <div className={Style.alignInline}>
                            <div className={Style.title}>
                              <h3>
                                Due Date:{' '}
                                {new Date(
                                  summary.netDueDate
                                ).toLocaleDateString()}
                              </h3>
                              <h3>|Total Amount: ${summary.total}</h3>
                            </div>
                          </div>
                          <div></div>
                        </>
                      </span>
                    </div>
                  </a>
                </div>
                <div className="em-c-accordion__body">
                  {summary.visible && (
                    <Table className={Style.table}>
                      <Table.Head>
                        <Table.Head.Row>
                          <Table.Head.Cell></Table.Head.Cell>
                          <Table.Head.Cell>Paying Company Code</Table.Head.Cell>
                          <Table.Head.Cell>Bank Country Key</Table.Head.Cell>
                          <Table.Head.Cell>Bank Key</Table.Head.Cell>
                          <Table.Head.Cell>Bank Account Number</Table.Head.Cell>
                          <Table.Head.Cell>Payment Method</Table.Head.Cell>
                          <Table.Head.Cell>Invoice Count</Table.Head.Cell>
                          <Table.Head.Cell>Total Amount ($)</Table.Head.Cell>
                        </Table.Head.Row>
                      </Table.Head>
                      <Table.Body>
                        {summary.treasuryCompaniesVM.map(
                          (company, companyIndex) => {
                            return (
                              <React.Fragment
                                key={`company-${company.companyCode}-${companyIndex}`}>
                                <Table.Body.GroupedRow
                                  className={Style.company}
                                  onClick={() =>
                                    setCompaniesVisibility(companyIndex, index)
                                  }
                                  cells={
                                    <>
                                      <Table.Body.Cell>
                                        {company.visible ? (
                                          <ChevronDownIcon
                                            className={Style.icon}
                                          />
                                        ) : (
                                            <ChevronRightIcon
                                              className={Style.icon}
                                            />
                                          )}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {company.companyCode}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {company.bankCountryCode}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {company.bankKey}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {company.bankAccountNumber}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {company.paymentMethod}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {company.totalInvoices}
                                      </Table.Body.Cell>
                                      <Table.Body.Cell>
                                        {`${company.currencyCode
                                          } ${formatter.format(
                                            company.totalValue
                                          )}`}
                                      </Table.Body.Cell>
                                    </>
                                  }
                                />
                                {company.visible &&
                                  company.vendors.map((vendor, index) => {
                                    return (
                                      <Table.Body.Row
                                        className={Style.vendor}
                                        key={`vendors-${vendor.totalValue}-${vendor.vendorCode}-${index}`}>
                                        <Table.Body.Cell></Table.Body.Cell>
                                        <Table.Body.Cell
                                          colSpan={5}
                                          className={Style.vendorName}>
                                          {`${vendor.vendorCode} - ${vendor.vendorName}`}
                                        </Table.Body.Cell>
                                        <Table.Body.Cell>
                                          {vendor.totalInvoices}
                                        </Table.Body.Cell>
                                        <Table.Body.Cell>{`${vendor.currencyCode
                                          } ${formatter.format(
                                            vendor.totalValue
                                          )}`}</Table.Body.Cell>
                                      </Table.Body.Row>
                                    );
                                  })}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Table.Body>
                    </Table>
                  )}
                </div>
              </li>
            </Accordian>
          );
        })}
      ;
      </div>
    );
}
export default PaymentSummaryTable;
