import React, { useEffect, useState } from 'react';
import { VendorsManagement } from 'Partials/VendorManagement';
import Api from 'Services/vendorsService';

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadVendors();
  }, []);

  const loadVendors = async (top, skip, filter) => {
    setLoading(true);
    const query = filter && window.btoa(JSON.stringify(filter));
    const vendors = await Api.getVendors(top, skip, query);
    setVendors(vendors.data.results);
    setCount(vendors.data.count);
    setLoading(false);
  };

  return (
    <VendorsManagement
      vendors={vendors}
      count={count}
      loading={loading}
      loadVendors={loadVendors}
    />
  );
};

export default VendorList;
