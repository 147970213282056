import React from 'react';

import { PaymentLogsFilter } from 'Partials/PaymentLogsFilter';
import Api from 'Services/paymentFileService';
export const PaymentLogList = () => {
  const getLogs = async (query) => {
    const logs = await Api.getPaymentLogs(query);
    return logs.data;
  };

  return <PaymentLogsFilter getLogs={getLogs} />;
};

export default PaymentLogList;
