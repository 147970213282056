import React from 'react';
import { Button, FileUpload } from '@exxonmobil/react-unity';
import Styles from './InvoiceMassUpload.module.css';

const Upload = (props) => {
  const downloadTemplate = () => {
    window.location.href = 'InvoiceUploadTemplate.xlsx';
  };

  return (
    <div>
      <div className={Styles.title}>Upload Invoices</div>
      <div className={Styles.action_bar}>
        <Button className="em-u-margin-half" onClick={downloadTemplate}>
          Download Template
        </Button>
        <Button
          className="em-u-margin-half"
          variant="primary"
          onClick={props.submitFile}
          disabled={props.fileError.length > 0 || props.filePosted === null}>
          Submit
        </Button>
      </div>
      <div className={Styles.upload_area}>
        <FileUpload
          multiple={false}
          onChange={props.showFile}
          error={props.fileError.length > 0}
          note={props.fileError}
        />
      </div>
    </div>
  );
};

export default Upload;
