import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  DownloadIcon,
  SolidCard,
  ShareIcon,
  ChartBarIcon,
  CirclePlusIcon,
  FileXlsxIcon,
  ClockIcon,
  UndoIcon,
  ShoppingCartIcon,
  FileSpreadsheetIcon,
  LinkList,
} from '@exxonmobil/react-unity';
import Style from './style.module.css';

export default function Home() {
  const userRoles = useSelector(
    (state) => state.auth.accountInfo.account.idTokenClaims.roles
  );
  return (
    <div className={Style.container}>
      <SolidCard className={Style.card}>
        <SolidCard.Body className={Style.cardBody}>
          <SolidCard.Body.Icon>
            <ShareIcon style={{ color: '#ffffff' }} />
          </SolidCard.Body.Icon>
          <SolidCard.Body.Title>Payments</SolidCard.Body.Title>
        </SolidCard.Body>
        <SolidCard.Footer className={Style.cardFooter}>
          <LinkList icons className={Style.grid}>
            <Link to="/generate-file" className={Style.links}>
              <LinkList.Item
                icon={<DownloadIcon style={{ color: '#ffffff' }} />}>
                Generate File
              </LinkList.Item>
            </Link>
            {userRoles.includes('Treasury') && (
              <Link to="/forecast-payment-summary" className={Style.linksLeft}>
                <LinkList.Item
                  icon={<ChartBarIcon style={{ color: '#ffffff' }} />}>
                  {' '}
                  Forecast Payment Summary
                </LinkList.Item>
              </Link>
            )}
            <Link to="/payment-log-list" className={Style.links}>
              <LinkList.Item icon={<UndoIcon style={{ color: '#ffffff' }} />}>
                Payment Logs
              </LinkList.Item>
            </Link>
          </LinkList>
        </SolidCard.Footer>
      </SolidCard>

      <SolidCard className={Style.card}>
        <SolidCard.Body className={Style.cardBody}>
          <SolidCard.Body.Icon>
            <FileSpreadsheetIcon style={{ color: '#ffffff' }} />
          </SolidCard.Body.Icon>
          <SolidCard.Body.Title>Invoices</SolidCard.Body.Title>
        </SolidCard.Body>
        <SolidCard.Footer className={Style.cardFooter}>
          <LinkList icons className={Style.grid}>
            <Link to="/invoices/manage" className={Style.links}>
              <LinkList.Item
                icon={<FileSpreadsheetIcon style={{ color: '#ffffff' }} />}>
                Manage Invoices
              </LinkList.Item>
            </Link>
            <Link to="/invoices-upload" className={Style.linksLeft}>
              <LinkList.Item
                icon={<FileXlsxIcon style={{ color: '#ffffff' }} />}>
                Invoice Mass Upload
              </LinkList.Item>
            </Link>
            <Link to="/invoices/new" className={Style.links}>
              <LinkList.Item
                icon={<CirclePlusIcon style={{ color: '#ffffff' }} />}>
                New Invoice
              </LinkList.Item>
            </Link>
            <Link
              to="/changelog-list?entity=invoice"
              className={Style.linksLeft}>
              <LinkList.Item icon={<ClockIcon style={{ color: '#ffffff' }} />}>
                Changelog
              </LinkList.Item>
            </Link>
          </LinkList>
        </SolidCard.Footer>
      </SolidCard>

      <SolidCard className={Style.card}>
        <SolidCard.Body className={Style.cardBody}>
          <SolidCard.Body.Icon>
            <ShoppingCartIcon style={{ color: '#ffffff' }} />
          </SolidCard.Body.Icon>
          <SolidCard.Body.Title>Vendors</SolidCard.Body.Title>
        </SolidCard.Body>
        <SolidCard.Footer className={Style.cardFooter}>
          <LinkList icons className={Style.grid}>
            <Link to="/vendors/manage" className={Style.links}>
              <LinkList.Item
                icon={<ShoppingCartIcon style={{ color: '#ffffff' }} />}>
                Manage Vendors
              </LinkList.Item>
            </Link>
            <Link
              to="/changelog-list?entity=vendor"
              className={Style.linksLeft}>
              <LinkList.Item
                icon={<FileXlsxIcon style={{ color: '#ffffff' }} />}>
                Changelog
              </LinkList.Item>
            </Link>
            {(userRoles.includes('VendorAdmin') || userRoles.includes('VendorIV')) && (
            <Link to="/vendors/new" className={Style.links}>
              <LinkList.Item
                icon={<CirclePlusIcon style={{ color: '#ffffff' }} />}>
                New Vendor
              </LinkList.Item>
            </Link>
            )}
          </LinkList>
        </SolidCard.Footer>
      </SolidCard>
    </div>
  );
}
