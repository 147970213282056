import React, { useEffect, useState } from 'react';
import { PaymentFilePage } from 'Partials/PaymentFilePage';
import Api from 'Services/paymentFileService';
import { setLoading } from 'Store/Reducers/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'Store/Reducers/preDisbursementSlice';

const PaymentFileReview = (props) => {
  const ui = useSelector((state) => state.ui);
  const [invoices, setInvoices] = useState();
  const [cluster, setClusters] = useState('');
  const [sapSystem, setSapSystem] = useState('');
  const [companies, setCompanies] = useState('');
  const [formatDate, setFormatDate] = useState('');
  const [fileTemplate, setFileTemplate] = useState('');

  const queryTimestamp = new Date();

  const dispatch = useDispatch();

  const getInvoices = async (query) => {
    dispatch(setLoading(true));

    const report = await Api.getInvoices(query);
    const { cluster, fileTemplateId } = report.data.filter;

    Api.getClusters().then((resp) => {
      setClusters(resp.data.find((item) => item.id === cluster)?.name);
    });

    Api.getFileTemplates().then((resp) => {
      setFileTemplate(
        resp.data.find((item) => item.id === fileTemplateId)?.description
      );
    });

    return report.data;
  };

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    dispatch(reset());
    if (params.get('filter') !== null) {
      if (props.location.state === null) {
        getInvoices(params.get('filter')).then((resp) => {
          setInvoices(resp);
          setVariables(resp);
        });
      } else {
        dispatch(setLoading(false));
        const {
          cluster,
          fileTemplateId,
        } = props.location.state?.results.filter;

        setVariables(props.location.state?.results);

        setClusters(
          props.location.state?.clusters.find((item) => item.id === cluster)
            ?.name
        );

        setFileTemplate(
          props.location.state?.fileTemplates.find(
            (item) => item.id === fileTemplateId
          )?.description
        );

        setInvoices(props.location.state?.results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setVariables = (invoices) => {
    setSapSystem(invoices.filter.sapSystemCode);
    const date = new Date(invoices.filter.dueDate);
    setFormatDate(
      date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    );
    setCompanies(invoices.filter.companies);
  };

  return (
    <PaymentFilePage
      fileTemplate={fileTemplate}
      companies={companies}
      dueDate={formatDate}
      clusterTeam={cluster}
      sapSystem={sapSystem}
      invoices={invoices}
      queryTimestamp={queryTimestamp}
      loading={ui.isLoading}
      setLoading={setLoading}
      query={params.get('filter')}
    />
  );
};

export default PaymentFileReview;
