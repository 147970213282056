import React, { useState } from 'react';

import { PaymentSummaryFilter } from 'Partials/PaymentSummaryFilter';
import Api from 'Services/paymentFileService';
export const PaymentSummary = () => {
  const [companies, setCompanies] = useState([]);

  const getSummary = async (query) => {
    const summary = await Api.getTreasuryInvoices(query);
    setCompanies(summary.data);
  };

  const onSearchCompanies = async (query) => {
    const list = await Api.getCompanies(query);
    return list.data;
  };

  return (
    <PaymentSummaryFilter
      getSummary={getSummary}
      companies={companies}
      onSearchCompanies={onSearchCompanies}
    />
  );
};

export default PaymentSummary;
