import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Table, Button, CheckIcon, XFilledIcon } from '@exxonmobil/react-unity';
import { SelectGroup } from 'Components/SelectGroup';
import { TextInputGroup } from 'Components/TextInputGroup';

import Styles from './style.module.css';

const BankInformationEditRow = (props) => {
  const { register, control, reset, errors } = useForm({
    mode: 'onBlur',
  });

  const { bankDetail, countries, system, vendorNumber } = props;
  const [country, setCountry] = useState('');
  const [bankKeys, setBankKeys] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [partnerBankType, setPartnerBankType] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [ibanCode, setIbanCode] = useState('');

  const saveBankDetails = () => {
    let bd = {
      systemCode: system,
      vendorNumber: vendorNumber,
      countryCode: country,
      bankKeys: bankKeys,
      bankAccountNumber: bankAccountNumber,
      partnerBankType: partnerBankType,
      swiftCode: swiftCode,
      ibanCode: ibanCode,
      accountOrder: 1,
    };

    props.action(bd);
  };

  useEffect(() => {
    register({ name: 'country' });
    register({ name: 'bankKeys' });
    register({ name: 'bankAccountNumber' });
    register({ name: 'partnerBankType' });
    register({ name: 'swiftCode' });
    register({ name: 'ibanCode' });
  }, [register]);

  useEffect(() => {
    if (bankDetail.vendorNumber && bankDetail.countryCode) {
      setCountry(bankDetail.countryCode);
      setBankKeys(bankDetail.bankKeys);
      setBankAccountNumber(bankDetail.bankAccountNumber);
      setPartnerBankType(bankDetail.partnerBankType);
      setSwiftCode(bankDetail.swiftCode);
      setIbanCode(bankDetail.ibanCode);
      reset({
        country: bankDetail.countryCode,
        bankKeys: bankDetail.bankKeys,
        bankAccountNumber: bankDetail.bankAccountNumber,
        partnerBankType: bankDetail.partnerBankType,
        swiftCode: bankDetail.swiftCode,
        ibanCode: bankDetail.ibanCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankDetail, reset]);

  return (
    <>
      <Table.Body.Cell style={{ verticalAlign: 'middle' }}>
        {!bankDetail.countryCode ? (
          <SelectGroup
            useDesc={true}
            name="country"
            value={country}
            control={control}
            register={register}
            options={countries}
            option="countryCode"
            onChange={(element) => setCountry(element.target.value)}
            error={errors.country}
          />
        ) : (
          bankDetail.countryCode
        )}
      </Table.Body.Cell>
      <Table.Body.Cell style={{ verticalAlign: 'middle' }}>
        {!bankDetail.bankKeys ? (
          <TextInputGroup
            className={Styles.textInput}
            name="bankKeys"
            register={register}
            value={bankKeys}
            control={control}
            maxLength="15"
            noLabel
            onChange={(element) => setBankKeys(element.target.value)}
            error={errors.bankKeys}
          />
        ) : (
          bankDetail.bankKeys
        )}
      </Table.Body.Cell>
      <Table.Body.Cell style={{ verticalAlign: 'middle' }}>
        {!bankDetail.bankAccountNumber ? (
          <TextInputGroup
            className={Styles.textInput}
            name="bankAccountNumber"
            register={register}
            value={bankAccountNumber}
            control={control}
            maxLength="18"
            noLabel
            error={errors.bankAccountNumber}
            onChange={(element) => setBankAccountNumber(element.target.value)}
          />
        ) : (
          bankDetail.bankAccountNumber
        )}
      </Table.Body.Cell>
      <Table.Body.Cell style={{ verticalAlign: 'middle' }}>
        <TextInputGroup
          className={Styles.textInput}
          name="partnerBankType"
          register={register}
          value={partnerBankType}
          control={control}
          maxLength="4"
          noLabel
          error={errors.partnerBankType}
          onChange={(element) => setPartnerBankType(element.target.value)}
        />
      </Table.Body.Cell>
      <Table.Body.Cell style={{ verticalAlign: 'middle' }}>
        <TextInputGroup
          className={Styles.textInput}
          name="swiftCode"
          register={register}
          control={control}
          value={swiftCode}
          maxLength="11"
          error={errors.swiftCode}
          noLabel
          onChange={(element) => setSwiftCode(element.target.value)}
        />
      </Table.Body.Cell>
      <Table.Body.Cell style={{ verticalAlign: 'middle' }}>
        <TextInputGroup
          className={Styles.textInput}
          name="ibanCode"
          value={ibanCode}
          register={register}
          control={control}
          maxLength="8"
          error={errors.ibanCode}
          noLabel
          onChange={(element) => setIbanCode(element.target.value)}
        />
      </Table.Body.Cell>
      <Table.Body.Cell
        style={{
          verticalAlign: 'middle',
          width: '110px',
        }}>
        <Button
          onClick={() => saveBankDetails()}
          variant="primary"
          size="small"
          style={{
            height: '30px',
          }}>
          <CheckIcon size="small" />
        </Button>
        <Button
          onClick={() => props.cancel()}
          variant="secondary"
          size="small"
          style={{
            height: '30px',
          }}>
          <XFilledIcon size="small" />
        </Button>
      </Table.Body.Cell>
    </>
  );
};

export default BankInformationEditRow;
