import React, { useEffect, useState } from 'react';

import { ChangeLogTable } from 'Partials/ChangeLogTable';

import Api from 'Services/invoicesService';
import ApiSystem from 'Services/paymentFileService';

const ChangeLogList = (props) => {
  const [changeSets, setChangeSets] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [systemsLoading, setSystemsLoading] = useState(false);
  const [systems, setSystems] = useState([]);
  const { entityGuid } = props.match.params;
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (entityGuid){
      loadChangeSets(params.get('entity'),entityGuid);

    } else {
      loadChangeSets(params.get('entity'));

    }
    getSystems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.get('entity')]);


  const loadChangeSets = async (entity, entityGuid, top, skip, filter) => {
    setLoading(true);

    if (entityGuid && filter){
      filter.entityGuid = entityGuid;
    }
    if (entityGuid && !filter) {
      filter = {entityGuid};
    }

    const query = filter && window.btoa(JSON.stringify(filter));
    const changeSets = await Api.getChangeSets(entity, top, skip, query);
    changeSets.data.results
      ? setChangeSets(changeSets.data.results)
      : setChangeSets([]);
    setCount(changeSets.data.count);
    setLoading(false);
  };

  const getSystems = () => {
    setSystemsLoading(true);
    ApiSystem.getSapSystems().then((resp) => {
      resp && setSystems(resp.data);
      setSystemsLoading(false);
    });
  };

  return (
    <ChangeLogTable
      changeSets={changeSets}
      count={count}
      loadChangeSets={loadChangeSets}
      entity={params.get('entity')}
      loading={loading}
      systems={systems}
      systemsLoading={systemsLoading}
      entityGuid={entityGuid}
      ></ChangeLogTable>
  );
};

export default ChangeLogList;
