import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextInput } from '@exxonmobil/react-unity';

import Style from './style.module.css';

const TextInputGroup = (props) => {
  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);
  const [value, setValue] = useState();
  return (
    <Controller
      as={
        <div>
          {props.noLabel || (
            <div className={Style.label}>
              {props.label}
              <div className={Style.optional}>
                {props.optional && ' (Optional)'}
              </div>
            </div>
          )}
          <TextInput
            className={props?.className}
            value={value}
            placeholder={props.placeholder || 'Type here'}
            inputProps={{ maxLength: props.maxLength }}
            type={props.type || 'text'}
            disabled={props.disabled}
            error={!!props.error}
            note={props.error && (props.error?.message || props.error)}
            id={props.id}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
          />
        </div>
      }
      name={props.name}
      control={props.control}
      register={{ ...props.register }}
      // defaultValue="Type here"
      onChange={([element]) => {
        if (props.onChange) {
          props.onChange(element);
          return;
        }
        setValue(element.target.value);
        return element.target.value;
      }}
    />
  );
};

export default TextInputGroup;
