import { InvoiceDetailsPage } from 'Partials/InvoiceDetailsPage';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InvoiceService from 'Services/invoicesService';
import PaymentFileService from 'Services/paymentFileService';

const InvoiceDetails = (props) => {
  const [invoice, setInvoice] = useState({});
  const [invoiceComments, setInvoiceComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clustersLoading, setClustersLoading] = useState(false);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);
  const [concurrencyError, setConcurrencyError] = useState(false);
  const [edited, setEdited] = useState(null);
  const [systems, setSystems] = useState([]);
  const [systemsLoading, setSystemsLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date());
  const mount = useRef(true);
  const { id } = props.match.params;
  const [currencies, setCurrencies] = useState([]);
  const [currenciesLoading, setCurrenciesLoading] = useState(false);
  let history = useHistory();

  const auth = useSelector((state) => state.auth.accountInfo.account);

  useEffect(() => {
    setTimestamp(new Date());
    if (id) {
      getInvoice(id);
    } else {
      setInvoice({
        status: 'Draft',
        entryDate: new Date(),
        processor: auth.userName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSystems();
    getClusters();
    getCurrencies();
    if (id) {
      getInvoiceComments(id);
      getAttachments(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mount.current) {
      mount.current = false;
      return;
    }
    if (id) {
      getPaymentMethods(invoice.system, invoice.company, invoice.vendorNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  const getClusters = async () => {
    setClustersLoading(true);
    const clusters = await InvoiceService.getClusters(true);
    setClusters(clusters.data);
    setClustersLoading(false);
  };

  const getPaymentMethods = async (system, companyCode, vendorNumber) => {
    setPaymentMethodsLoading(true);
    const payments = await InvoiceService.getVendorPaymentMethods(
      system,
      companyCode,
      vendorNumber
    );
    setPaymentMethods(payments.data);
    setPaymentMethodsLoading(false);
  };

  const getInvoice = async (id) => {
    setConcurrencyError(false);
    setLoading(true);
    const invoice = await InvoiceService.getInvoiceDetails(id);
    setInvoice(invoice.data);
    setLoading(false);
  };

  const getInvoiceComments = async (id) => {
    setLoading(true);
    const invoiceComments = await InvoiceService.getInvoiceComments(id);
    setInvoiceComments(invoiceComments.data);
    setLoading(false);
  };

  const getAttachments = async (id) => {
    setLoading(true);
    const attachments = await InvoiceService.getAttachments(id);
    setAttachments(attachments.data);
    setLoading(false);
  };

  const getSystems = () => {
    if (!clusters.length) {
      setSystemsLoading(true);
      PaymentFileService.getSapSystems().then((resp) => {
        resp && setSystems(resp.data);
        setSystemsLoading(false);
      });
    }
  };

  const downloadAttachment = async (id, fileName) => {
    setLoading(true);
    const response = await InvoiceService.downloadAttachment(id);
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setLoading(false);
  };

  const deleteAttachment = async (id) => {
    setLoading(true);
    await InvoiceService.deleteAttachment(id);
    setLoading(false);
  };

  const deleteComment = async (id) => {
    setLoading(true);
    await InvoiceService.deleteComment(id);
    setLoading(false);
  };

  const updateInvoice = async (invoice) => {
    setLoading(true);
    const { id } = props.match.params;
    invoice.amount = invoice.amount?.toFixed(2);
    return InvoiceService.putInvoiceDetails(id, invoice, timestamp)
      .then((response) => {
        if (response.status === 409) {
          setConcurrencyError(true);
        } else {
          if (response.data.status === 'Cancelled') {
            history.push(`/invoices/${id}`);
            window.location.reload();
            return;
          }
          setEdited(true);
          getInvoice(id);
        }
      })
      .finally(() => {
        setLoading(false);
        setTimestamp(new Date());
      });
  };

  const addComment = async (comment) => {
    setLoading(true);

    const commentLoad = {
      createdDate: new Date().toISOString().split('.')[0],
      createdBy: auth.name || auth.userName,

      commentText: comment,
    };

    const { id } = props.match.params;
    return InvoiceService.postInvoiceComment(id, commentLoad).finally(() => {
      setLoading(false);
    });
  };

  const addAttachments = async (files) => {
    setLoading(true);
    const { id } = props.match.params;
    const response = await InvoiceService.postInvoiceAttahcments(id, files);
    setLoading(false);
    return response;
  };

  const getCompanies = async (systemCode, query) => {
    const list = await PaymentFileService.getCompaniesBySystem(
      systemCode,
      query
    );
    return list.data;
  };

  const getVendors = async (query, system, company) => {
    const list = await InvoiceService.getVendors(query, system, company);
    return list.data;
  };

  const saveInvoice = async (stagingInvoice) => {
    setLoading(true);
    stagingInvoice.changeReason = 'Invoice Created';
    return InvoiceService.postInvoiceDetails(stagingInvoice)
      .then((response) => {
        invoice.id = response.data.id;
        history.push(`/invoices/${response.data.id}`);
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrencies = async () => {
    setCurrenciesLoading(true);
    const currencies = await InvoiceService.getCurrencies();
    setCurrencies(currencies.data);
    setCurrenciesLoading(false);
  };

  return (
    <InvoiceDetailsPage
      invoice={invoice}
      invoiceComments={invoiceComments}
      deleteInvoiceComments={deleteComment}
      attachments={attachments}
      deleteAttachment={deleteAttachment}
      clusters={clusters}
      clustersLoading={clustersLoading}
      paymentMethods={paymentMethods}
      paymentMethodsLoading={paymentMethodsLoading}
      updateInvoice={updateInvoice}
      concurrencyError={concurrencyError}
      addComment={addComment}
      addAttachments={addAttachments}
      getAttachments={getAttachments}
      downloadAttachment={downloadAttachment}
      edited={edited}
      setEdited={setEdited}
      loading={loading}
      getInvoice={getInvoice}
      systems={systems}
      systemsLoading={systemsLoading}
      saveInvoice={saveInvoice}
      getCompanies={getCompanies}
      getVendors={getVendors}
      getPaymentMethods={getPaymentMethods}
      deleteInvoice={updateInvoice}
      currencies={currencies}
      currenciesLoading={currenciesLoading}
    />
  );
};

export default InvoiceDetails;
