import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextInput } from '@exxonmobil/react-unity';

import Style from './style.module.css';

const MoneyInputGroup = (props) => {
  const formatValue = (newValue) => {
    if (
      newValue === '' ||
      newValue === '$' ||
      newValue == null ||
      newValue === undefined
    ) {
      newValue = '0';
    }
    if (newValue !== value) {
      const valueNumber = formatValueNumber(newValue);

      const valueFormatted = formatter().format(valueNumber);
      if (valueFormatted !== value) {
        setValue(valueFormatted);
      }
      return valueFormatted;
    }
  };

  const formatValueNumber = (value) => {
    if (Math.floor(value) === parseFloat(value))
      return Number.parseFloat(value.replace(/[^0-9-]+/g, ''));
    const decimals = value.toString().split('.')[1]?.length;
    return decimals === 1
      ? Number.parseFloat(value.replace(/[^0-9-]+/g, '') / 10)
      : Number.parseFloat(value.replace(/[^0-9-]+/g, '') / 100);
  };

  const formatter = function (currencyCode) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,

      currency: currencyCode ? currencyCode : 'USD',
    });
  };

  useEffect(() => {
    if (props.value) {
      setValue(formatValue(props.value.toString()));
    } else {
      setValue(props.value || '');
    }
    // eslint-disable-next-line
  }, [props.value]);
  const [value, setValue] = useState();
  return (
    <Controller
      as={
        <div>
          {props.noLabel || (
            <div className={Style.label}>
              {props.label}
              <div className={Style.optional}>
                {props.optional && ' (Optional)'}
              </div>
            </div>
          )}
          <TextInput
            className={props?.className}
            value={value}
            placeholder={props.placeholder || 'Type here'}
            inputProps={{ maxLength: props.maxLength }}
            type={props.type || 'text'}
            disabled={props.disabled}
            error={!!props.error}
            note={
              props.error
                ? props.error && (props.error?.message || props.error)
                : props.note
            }
            id={props.id}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
          />
        </div>
      }
      name={props.name}
      control={props.control}
      register={{ ...props.register }}
      // defaultValue="Type here"
      onChange={([element]) => {
        if (props.onChange) {
          props.onChange(element);
          return;
        }
        let money = formatValue(element.target.value);
        setValue(money);
        return money;
      }}
    />
  );
};

export default MoneyInputGroup;
