import { createSlice } from '@reduxjs/toolkit';
import { AuthenticationState } from 'react-aad-msal';

const authenticationSlice = createSlice({
  name: 'auth',
  initialState: {
    accountInfo: {},
    state: AuthenticationState.Unauthenticated,
  },
  reducers: {
    authenticate: (state, action) => {
      state.accountInfo = action.payload;
      state.state = AuthenticationState.Authenticated;
    },
    unauthenticate: (state, action) => {
      state.accountInfo = null;
      state.state = AuthenticationState.Unauthenticated;
    },
    updateIdToken: (state, action) => {
      state.accountInfo.jwtIdToken = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.accountInfo.jwtAccessToken = action.payload;
    },
  },
});

export const {
  authenticate,
  unauthenticate,
  updateIdToken,
  updateAccessToken,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
