import React, { useEffect, useState } from 'react';
import { Loader, CheckboxDropdown } from '@exxonmobil/react-unity';
import { Controller } from 'react-hook-form';

import Style from './style.module.css';

const MultiSelectGroup = (props) => {
  useEffect(() => {
    setOptions(
      props.options.map((item) => {
        item.checked = props.value.indexOf(item[props.option]) >= 0;
        return item;
      })
    );
  }, [props.value, props.options, props.option]);
  const [options, setOptions] = useState([]);
  return (
    <Controller
      as={
        <div style={props.divStyle}>
          {props.label && (
            <div className={Style.label} style={props.labelStyle}>
              {props.label}
              <div className={Style.optional}>
                {props.optional && ' (Optional)'}
              </div>
            </div>
          )}
          <CheckboxDropdown
            label={props.value?.length > 0 ? props.value.join(',') : '-'}
            className={`${Style.container} ${
              props.isLoading ? Style.loading : ''
            }`}
            disabled={props.disabled || props.isLoading}
            id={`select-${props.label}`}
            note={props.error && props.error.message}>
            {options &&
              options.map((item, index, ref) => {
                return (
                  <CheckboxDropdown.Item
                    label={item[props.option]}
                    key={`option-${item.id}-${index}`}
                    inputProps={{
                      checked: item.checked,
                      onChange: (element) => {
                        item.checked = element.target.checked;
                        props.onChange &&
                          props.onChange(ref.filter((x) => x.checked));
                      },
                    }}
                  />
                );
              })}
          </CheckboxDropdown>
          <div
            style={{
              width: '0px',
              height: '0px',
              float: 'right',
              position: 'relative',
              top: '-40px',
              right: '30px',
            }}>
            <div
              style={{
                width: '20px',
                position: 'absolute',
              }}>
              {props.isLoading && <Loader />}
            </div>
          </div>
        </div>
      }
      name={props.name}
      control={props.control}
      register={{ ...props.register }}
      placeholder={props.placeholder ? props.placeholder : 'Please select'}
    />
  );
};

export default MultiSelectGroup;
