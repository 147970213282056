import React, { useEffect, useState } from 'react';
import {
  Button,
  FileGenericIcon,
  FileUpload,
  TrashcanEmptyIcon,
} from '@exxonmobil/react-unity';

import Styles from './style.module.css';
import { GenericModal } from 'Components/GenericModal';

const AttachmentList = (props) => {
  const [formData, setFormData] = useState(new FormData());
  const [attachments, setAttachments] = useState([]);
  const [errorStatus, setErrorStatus] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setAttachments(props.attachments);
  }, [props.attachments]);

  const uploadAttachaments = (files) => {
    const data = new FormData();
    files.map((item) => data.append('attachments', item));
    setFormData(data);
  };

  const submitAttachments = async () => {
    props
      .addAttachments(formData)
      .then((response) => {
        if (response.status === 400) {
          setShowModal(true);
          setErrorStatus(response);
        } else {
          // setAttachments([...attachments, ...response.data]);
          setFormData(new FormData());
          if (props.invoiceId) props.getAttachments(props.invoiceId);
          if (props.systemCode != null && props.vendorNumber != null)
            props.getAttachments(props.systemCode, props.vendorNumber);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeItem = (attachment) => {
    props.deleteAttachment(attachment.id).then((response) => {
      if (props.invoiceId) props.getAttachments(props.invoiceId);
      if (props.systemCode != null && props.vendorNumber != null)
        props.getAttachments(props.systemCode, props.vendorNumber);
    });
  };

  return (
    <div>
      {attachments &&
        attachments?.map((item, index) => {
          return (
            <div
              className={Styles.list}
              key={`attachment-${index}-${item.blobUrl}`}>
              <div className={Styles.container}>
                <div className={Styles.details}>
                  <div className={Styles.icon}>
                    <FileGenericIcon
                      size="large"
                      onClick={() =>
                        props.downloadAttachment(item.id, item.fileName)
                      }
                    />
                  </div>
                  <div className={Styles.description}>
                    <div
                      className={Styles.file}
                      onClick={() =>
                        props.downloadAttachment(item.id, item.fileName)
                      }>
                      {item.fileName}
                    </div>
                    <div>
                      {new Date(item.createdDate+"Z").toLocaleDateString('en-US', {
                        // timeZone: 'UTC',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </div>
                  </div>
                </div>
                <div className={Styles.actions}>
                  <Button onClick={() => removeItem(item)}>
                    <TrashcanEmptyIcon />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      <div className={Styles.fileUpload}>
        <FileUpload
          className={Styles.upload}
          onChange={(e) => {
            uploadAttachaments(e);
          }}
        />
        <Button
          className={Styles.submitButton}
          variant="primary"
          onClick={() => submitAttachments()}>
          Submit
        </Button>
      </div>
      <GenericModal
        show={showModal}
        title="Validation Errors"
        content={<div>{errorStatus.data}</div>}
        close={() => setShowModal(false)}
      />
    </div>
  );
};

export default AttachmentList;
